import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import Svg from '../helpers/svg';
import PageHeader from '../helpers/pageHeader';
import fpRest from '../helpers/fpRest';
import CsvImporter from '../helpers/csvImporter';
import List from '../helpers/list';
import {defaultTableFilter, DorpDownFilter} from '../helpers/tableHelpers';
import ReactTable from 'react-table';
import Loader from 'react-loader-advanced';
 import { toast } from 'react-toastify';


import 'react-table/react-table.css';

class Assets extends Component {
    constructor(props) {
        super(props);
        const initSortOrder = sessionStorage.getItem(`assets:sortOrder`);

        this.state = {
            showLoader: true,
            initSortOrder: initSortOrder ? JSON.parse(initSortOrder) : {id: 'name', desc: false},
            assets: [],
            assetsActive: [],
            types: [],
            isImporterOpen: false,
            hideInactive: true,
            isImportDropdownOpen: false,
            isDownloadDropdownOpen: false           
        };
    };

    getData = async () => {
        if (this.state.showLoader === false) this.setState({showLoader: true});
        try {
            const assetsRes = await fpRest.get('assets');
            const assets = assetsRes.data;
            const assetsActive = assets.filter(({active}) => active);
            const typesRes = await fpRest.get(`assets/types`);
            const types = typesRes.data; 
            console.log(assets);

            this.setState({assets, assetsActive, types, showLoader: false});
        }
        catch(err) {
            console.log(err);
        };         
    };

    startImport = () => {
        this.setState({isImporterOpen: true});
    };

    closeImporter = () => {
        this.setState({isImporterOpen: false});
    };

    toggleImportDropdown = () => {
        this.setState({isImportDropdownOpen:!this.state.isImportDropdownOpen});
    };
    
    toggleDownloadDropdown = () => {
        this.setState({isDownloadDropdownOpen:!this.state.isDownloadDropdownOpen});
    };            

    toggleInactive = () => {
        this.setState({hideInactive: !this.state.hideInactive});
    };

    uploadAssets = assets => {
        const getData = this.getData.bind(this);
        const policyPromises = assets.map(asset => fpRest.post('assets', {...asset, active: 1}));
        Promise.all(policyPromises)
        .then(res => {
            getData();
            toast.success('Asset Uploaded');
        })
        .catch(err => { 
            if (err.response && err.response.data) {
                err.response.data.errors.forEach(err => {
                    toast.error(`import failed: ${err}`);
                });
                console.log(err.response.data);
            }
            else {
                console.log(err);
                toast.error(`Oops! Something didn't work right. Assets weren't imported`);
            }
        });
    };

    printAll = () => {
        console.log('Print All');
    };

    downloadAllAssets = async () => {
        this.setState({showLoader: true, isLoadingReport: true});
        
        try {
            const res = await fpRest.download(`reportsTest?name=assetsAll&format=csv`, `Assets.csv`);  
            this.setState({showLoader: false, isLoadingReport: false});    
            if (res.message == 'No data found') toast.success('There are no Assets');   
        }
        catch (err) {
            console.log(err);
        }
    };    

    componentDidMount() {
        this.getData();        
    }

    render() {
        const {toggleImportDropdown, startImport, closeImporter, uploadAssets, downloadAllAssets, toggleDownloadDropdown, toggleInactive} = this;
        const {showLoader, initSortOrder, assets, assetsActive, types, hideInactive, isImporterOpen, isImportDropdownOpen, isDownloadDropdownOpen} = this.state;

        return (
            <Loader show={showLoader} message={<Svg use="loader"/>} disableDefaultStyles={true}>
                <PageHeader title="Assets" actionItems={[
                    {label: <Svg use="add" />, tip: 'New Asset', link: '/assets/new'},
                    {label: <Svg use="upload" />, tip: 'Upload Assets', options: [{label: 'Select CSV File', action: startImport}, {label: 'Download Import Template', link: 'https://flightplan-public.s3.amazonaws.com/import-templates/asset-impot-template.csv', target: "_self"}], isOpen: isImportDropdownOpen, action: toggleImportDropdown},
                    {label: <Svg use='download'/>, tip:'Download',
                    options: [
                        {label: 'Download All Assets (CSV)', action: downloadAllAssets}
                    ],
                    isOpen: isDownloadDropdownOpen, action: toggleDownloadDropdown},                                
                ]} />
                <section>
                    <List
                        className="meta"
                        inlineLabels={true}
                        items ={[
                            {label: 'Active Asset', value: assetsActive.length},
                            {label: 'Hide Inactive', value: hideInactive, editing: true, inputType: 'toggle', onChange: toggleInactive}
                        ]}
                    />
                </section>
                <section>
                    <div className="card">                      
                        <ReactTable
                            data={hideInactive ? assetsActive : assets}
                            defaultSorted={[initSortOrder]}
                            columns={[            
                                {Header: 'id', accessor: 'id', show: false},
                                {
                                    Header: 'Name',
                                    accessor: 'name',
                                    Cell: row => row.value ? <span>{row.value}</span> : 'Not Set'
                                },
                                {
                                    Header: 'Description',
                                    accessor: 'description',
                                    Cell: row => row.value ? <span>{row.value}</span> : 'Not Set'
                                },                                
                                {
                                    Header: 'Tag/ID',
                                    accessor: 'tag',
                                    Cell: row => row.value ? <span>{row.value}</span> : 'Not Set'
                                },
                                {
                                    Header: 'Serial Number',
                                    accessor: 'serialNumber',
                                    Cell: row => row.value ? <span>{row.value}</span> : 'Not Set'
                                },                                                                
                                {
                                    Header: 'Location',
                                    accessor: 'location',
                                    Cell: row => row.value ? <span>{row.value}</span> : 'Not Set'
                                },
                                {
                                    Header: 'Criticality',
                                    accessor: 'criticality',
                                },
                                {
                                    Header: 'Has ePHI',
                                    accessor: 'ephi',
                                    Cell: row => row.value ? <span>Yes</span> : <span>No</span>
                                },
                                {
                                    Header: 'Is Encrypted',
                                    accessor: 'encrypted',
                                    Cell: row => row.value ? <span>Yes</span> : <span>No</span>
                                },                                                                 
                                {
                                    show: hideInactive ? false : true,
                                    Header: 'Active',
                                    accessor: 'active',
                                    Cell: row => row.value ? 'Active' : 'Inactive',
                                    Filter: ({onChange}) => <DorpDownFilter options={[{label: 'Active', value: true}, {label: 'inactive', value: false}]} action={onChange.bind(this)}/>            
                                }
                            ]}
                            noDataText="No Assets Found"
                            onSortedChange={sort => sessionStorage.setItem(`assets:sortOrder`, JSON.stringify(sort[0]))}
                            filterable={true}
                            defaultFilterMethod={defaultTableFilter}
                            getTdProps={(state, rowInfo, column, instance) => ({
                                onClick: e => rowInfo && this.props.history.push(`/assets/${rowInfo.row.id}`),
                                style: { cursor: 'pointer' }
                            })}
                        />
                    </div>
                </section>
                
                <CsvImporter
                    open={isImporterOpen}
                    onRequestClose={closeImporter}
                    onRequestUpload={res => uploadAssets(res)}
                    title="Map Asset Fields"
                    mappingOptions={[
                        {label: 'Name', value: 'name', required: true},
                        {label: 'Tag', value: 'tag', required: true},
                        {label: 'Serial Number', value: 'serialNumber'},
                        {
                            label: 'Type',
                            value: 'typeId',
                            required: true,
                            allowedCellValues: types.map(({id, name}) => ({label: name, value: id}))                           
                        },
                        {label: 'Description', value: 'description'},
                        {label: 'Location', value: 'location'},
                        {label: 'Criticality', value: 'criticality'},
                        {label: 'Destroyed Date', value: 'destroyedDate', allowedCellValues: 'date'},
                        {label: 'Active Date', value: 'activeDate', allowedCellValues: 'date'},
                        {label: 'Deactivate Date', value: 'deactivateDate', allowedCellValues: 'date'},
                        //{label: 'Active', value: 'active'},
                        {
                            label: 'Has ePHI',
                            value: 'ephi',
                            allowedCellValues: [
                                {value: true, label: 'Yes'},
                                {value: false, label: 'No'},
                                {value: true, label: 'True'},
                                {value: false, label: 'False'},
                                {value: true, label: '1'},
                                {value: false, label: '0'}                                                          
                            ]                            
                        },
                        {
                            label: 'Encrypted',
                            value: 'encrypted',
                            allowedCellValues: [
                                {value: true, label: 'Yes'},
                                {value: false, label: 'No'},
                                {value: true, label: 'True'},
                                {value: false, label: 'False'},
                                {value: true, label: '1'},
                                {value: false, label: '0'}                                                          
                            ]                                
                        },
                    ]}
                />
           </Loader>
        );
    };
}

export default withRouter(Assets);
import React, {Component} from 'react';
import {withRouter, Prompt} from 'react-router-dom';
import Modal from 'react-modal';
import Svg from '../helpers/svg';
import List from '../helpers/list';
import PageHeader from '../helpers/pageHeader';
import fpRest from '../helpers/fpRest';
import withCurrentUser from '../helpers/withCurrentUser';
import {truncate, timezones} from '../helpers/utils';
import settingsHeaderNav from '../helpers/settingsHeaderNav';
import 'react-table/react-table.css';
import Loader from 'react-loader-advanced';
import {toast} from 'react-toastify';
import usStatesObj from 'datasets-us-states-abbr-names';
import {handleGetError, handlePostPatchError} from '../helpers/errors';

const usStatesOptions = Object.keys(usStatesObj).map(abbr => ({label: usStatesObj[abbr], value: abbr}));

class Company extends Component {
    constructor(props) {
        super(props); 

        this.state = {
            showLoader: true,
            isSaved: true,
            modalIsOpen: false,
            modalContent: {},
            createdDate: '',
            name: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            zip: '',
            phone: '',
            fax: '',
            website: '',
            securityOfficerId: '',
            securityOfficerName: '',
            privacyOfficerId: '',
            privacyOfficerName: '',
            timezoneId: '',
            timezoneName: '',
            users: []
        };            
    };

    getData = async () => {
        const {editing, _new, currentUser} = this.props;
        let company = [];
        let users = [];

        try {
            company = await fpRest.get(`companies/${currentUser.tenantId}`);
            company = company.data;

            this.setState({...company, showLoader: false});

            if (editing) {
                users = await fpRest.get(`users`);
                users = users.data.map(({id, firstName, lastName}) => ({value: id, label: `${firstName} ${lastName}`}));
                
                this.setState({users});
            }                      
        }
        catch (err) {
            console.log(err);
        }
    };
    
    onFieldChange = ({accessor, value}) => {
        this.setState({
            [accessor]: value,
            isSaved: false
        });
    };   
    
    validate = ({
        name,
        timezoneId
    }) => {
        let invalidFields = [];
    
        if (!name) invalidFields.push('"Company Name" is required');
        if (!timezoneId) invalidFields.push('"Time Zone" is required');

        return invalidFields;
    };

    save = async secondAction => {
        const {currentUser, history} = this.props;
        const {
            name,
            address1,
            address2,
            city,
            state,
            zip,
            phone,
            fax,
            website,
            securityOfficerId,
            privacyOfficerId,
            timezoneId
        } = this.state;
        const persistedState = {
            name,
            address1,
            address2,
            city,
            state,
            zip,
            phone,
            fax,
            website,
            securityOfficerId,
            privacyOfficerId,
            timezoneId
        };
        const invalidFields = this.validate(persistedState);

        this.setState({isSaved: true});

        if (!invalidFields.length) {
            try {
                await fpRest.patch(`companies/${currentUser.tenantId}`, persistedState);
                if (secondAction === 'close') {
                    history.push(`/settings/company`);
                }
                
                toast.success('Company Saved');
                console.log('Saved');
            }
            catch (err) {
                handlePostPatchError(err);
            }
        }
        else {
            this.setState({isSaved: false});

            invalidFields.forEach(message => {
                toast.error(message);
            });
        } 
    };

    close = () => {
        if (this.props.id) {
            this.props.history.push(`/settings/companies/${this.props.id}`);
        }
        else {
            this.props.history.push(`/settings/companies/`);
        }
    };

    componentDidMount() {
        this.getData();
    };

    render() {
        const {id, editing, _new, history} = this.props;
        const {
            showLoader,
            isSaved,
            modalIsOpen,
            modalContent,
            createdDate,
            name,
            address1,
            address2,
            city,
            state,
            zip,
            phone,
            fax,
            website,
            securityOfficerId,
            securityOfficerName,
            privacyOfficerId,
            privacyOfficerName,
            timezoneId,
            timezoneName,
            users
        } = this.state;
        const {onFieldChange, save, close} = this;

        let saveButtonClass = '';

        if (!isSaved) saveButtonClass = 'warning';

        return (
            <Loader show={showLoader} message={<Svg use="loader"/>} disableDefaultStyles={true}>
                {editing ?
                    <PageHeader title=''
                        navItems={settingsHeaderNav('company')}
                        actionItems={[
                            //{label: <Svg use='briefcase'/>, tip: 'All Companies', link: '/companies/'},
                            {label: <Svg use='close'/>, tip: 'Close', action: close},
                            {label: <Svg use='save'/>, tip:'Save', action: save, className: saveButtonClass},
                            {label: <Svg use='save-close'/>, tip: 'Save & Close', action: () => save('close')}
                        ]}
                    />
                    :
                    <PageHeader title=''
                        navItems={settingsHeaderNav('company')}    
                        actionItems={[
                            /*{label: <Svg use='arrow-left'/>, tip: 'All Companies', link: '/settings/companies/'},*/
                            {label: <Svg use='edit'/>, tip: 'Edit Company', link: 'company/edit/'},
                            /*{label: <Svg use="add" />, tip: 'New Company', link: '/settings/companies/new'}*/
                        ]}
                    />
                }
                {!_new &&
                    <section>
                        <List
                            className="meta"
                            inlineLabels={true}
                            items ={[
                                {label: 'Created Date', value: createdDate, inputType: 'date'}
                            ]}
                        />
                    </section>
                }           
                <section>
                    <List
                        className=" card grid-3"
                        editing={editing}
                        onChange={onFieldChange}
                        items={[
                            {label: 'Company Name', value: name, accessor:'name', required: true},
                            {label: 'Address 1', value: address1, accessor:'address1'},
                            {label: 'Address 2', value: address2, accessor:'address2'},
                            {label: 'City', value: city, accessor:'city'},
                            {
                                label: 'State',
                                value: editing ? state : usStatesObj[state],
                                inputType: 'select',
                                options: usStatesOptions                                    
                            },
                            {label: 'Zip', value: zip, accessor:'zip', inputType: 'number'},
                            {label: 'Phone', value: phone, accessor:'phone', inputType: 'phone'},
                            {label: 'Fax', value: fax, inputType: 'phone'},
                            {label: 'Website', value: website, accessor:'website'},
                            {label: 'Security Officer', value: editing ? securityOfficerId : securityOfficerName, accessor: 'securityOfficerId', inputType: 'select', options: users},
                            {label: 'Privacy Officer', value: editing ? privacyOfficerId : privacyOfficerName, accessor: 'privacyOfficerId', inputType: 'select', options: users},
                            {label: 'Time Zone', value: editing ? timezoneId : timezoneName, accessor: 'timezoneId', inputType: 'select', options: timezones, required: true}
                        ]}
                    />                                             
                </section>

                <Modal
                    isOpen={modalIsOpen}
                    className="modal-content"
                    closeTimeoutMS={300}
                    overlayClassName="modal-"
                    bodyOpenClassName="modal-active"
                    ariaHideApp={false}
                    contentLabel="Modal"
                >
                    {modalContent.header &&                    
                        <h3>{modalContent.header}</h3>
                    }
                    {modalContent.html ? <span dangerouslySetInnerHTML={{__html: modalContent.body}}></span> : <span>{modalContent.body}</span>}
                </Modal>                                                
                <Prompt when={editing && !isSaved} message={location => `You have changes that arn't saved. Click "Okay" to discard these changes, or "Cancel" to stay on this page`}/>                               
            </Loader>
        );
    }
}

export default withRouter(withCurrentUser(Company));
import React, {Component} from 'react';
import {withRouter, Prompt} from 'react-router-dom';
import Svg from '../helpers/svg';
import List from '../helpers/list';
import PageHeader from '../helpers/pageHeader';
import fpRest from '../helpers/fpRest';
import EvidenceTable from '../evidence/evidenceTable'
import withCurrentUser from '../helpers/withCurrentUser';
import {truncate} from '../helpers/utils';
import 'react-table/react-table.css';
import Loader from 'react-loader-advanced';
 import { toast } from 'react-toastify';
import {handleGetError, handlePostPatchError} from '../helpers/errors';


class SecurityIncident extends Component {
    constructor(props) {
        super(props) 

        this.state = {
            hideInactive: false,
            showLoader: true,
            isSaved: true,
            users: [],
            linkedEvidence: [],
            incidentCategories: [],
            incidentTypes: [],
            businessAssociates: [],
            incidentStatuses: [{label: 'Open', value: 1}, {label: 'Closed', value: 2}],           
            enteredDate: null,
            occurrenceDate: null,
            knownDate: null,
            location: '',
            discoveredBy: '',
            reportedBy: '',
            witnesseddBy: '',
            description: '',
            investigationStartDate: null,
            investigationEnddDate: null,
            investigatedBy: '',
            investigationNotes: '',
            mitigationNotes: '',
            breach: false,
            breachEffected: '',
            name: '',
            status: '',
            statusId: 1,
            type: '',
            typeId: null,
            category: '',
            categoryId: null,
            businessAssociate: '',
            businessAssociateId: null
        };            
    };

    getData = async () => {
        const {id, editing, _new} = this.props;

        try {
            let securityIncident = {};
            let incidentCategories = [];
            let incidentTypes = [];
            let businessAssociates = [];
            
            if (!_new) {
                securityIncident = await fpRest.get(`security-incidents/${id}`);
                securityIncident = securityIncident.data;
            }

            if (editing) {
                const res = await Promise.all([fpRest.get('security-incidents/types'), fpRest.get('security-incidents/categories'), fpRest.get('business-associates')]);
                incidentCategories = res[1].data.map(({id, name}) => ({value: id, label: name}));
                incidentTypes = res[0].data.map(({id, name}) => ({value: id, label: name}));
                businessAssociates = [{value: '', label: 'None'}, ...res[2].data.map(({id, name}) => ({value: id, label: name}))];
            }

            this.setState({...securityIncident, incidentCategories, incidentTypes, businessAssociates, showLoader: false});
        }
        catch (err) {
            handleGetError(err, this.props);
        }
    };

    downloadEvidence = async id => {
        this.setState({showLoader: true});

        try {
            const {data} = await fpRest.get(`evidence/${id}`);

            let element = document.createElement('a');

            element.setAttribute('href', data.tempDownloadUrl);
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
            this.setState({showLoader: false});          
        }
        catch (err) {
            console.log(err);
        } 
    };    

    onEvidenceUploadComplete = async (newEvidence) => {
        this.setState({linkedEvidence: [...this.state.linkedEvidence, newEvidence]});
    }; 
    
    deleteEvidence = async id => {
        try {
            await fpRest.delete(`evidence/${id}`);

            const linkedEvidence = this.state.linkedEvidence.filter(evidence => evidence.id === id ? false : true);

            this.setState({linkedEvidence});
            toast.success('Evidence Deleted');
        }
        catch(err) {
            console.log(err);
        }
    };      

    onFieldChange = ({accessor, value}) => {
        this.setState({
            [accessor]: value,
            isSaved: false
        });
    };
    
    validate = ({
        name,
        statusId,
        categoryId,
        typeId,
        occurrenceDate,
        knownDate
    }) => {
        let invalidFields = [];
    
        if (!name) invalidFields.push('"Name" is required');
        if (!statusId) invalidFields.push('"Status" is required');
        if (!categoryId) invalidFields.push('"category" is required');
        if (!typeId) invalidFields.push('"Type" is required');
        if (!occurrenceDate) invalidFields.push('"Occurrence Date & Time" is required');
        if (!knownDate) invalidFields.push('"Found Date & Time" is required');
    
        return invalidFields;
    };

    save = async secondAction => {
        const {id, history} = this.props;
        const {
            enteredDate,
            occurrenceDate,
            knownDate,
            location,
            discoveredBy,
            reportedBy,
            witnesseddBy,
            description,
            investigationStartDate,
            investigationEnddDate,
            investigatedBy,
            investigationNotes,
            mitigationNotes,
            breach,
            breachEffected,
            name,
            statusId,
            typeId,
            categoryId,
            businessAssociateId
        } = this.state;
        const persistedState = {
            enteredDate,
            occurrenceDate,
            knownDate,
            location,
            discoveredBy,
            reportedBy,
            witnesseddBy,
            description,
            investigationStartDate,
            investigationEnddDate,
            investigatedBy,
            investigationNotes,
            mitigationNotes,
            breach,
            breachEffected,
            name,
            statusId,
            typeId,
            categoryId,
            businessAssociateId
        };
        const invalidFields = this.validate(persistedState);

        this.setState({isSaved: true});

        if (!invalidFields.length) {
            try {
                if (id) {
                    await fpRest.patch(`security-incidents/${id}`, persistedState);
                    if (secondAction === 'close') {
                         history.push(`/security-incidents/${id}`);
                    }
                    
                    toast.success('Security Incident Saved');
                }
                else {
                    const {data} = await fpRest.post('security-incidents', persistedState);
                    toast.success('Security Incident Saved');
                    
                    if (secondAction === 'close') {
                        history.push(`/security-incidents/`);
                    }
                    else {
                        history.push(`/security-incidents/${data.id}`);
                    }
                }
            }
            catch (err) {
                this.setState({isSaved: false});
                handlePostPatchError(err);
            }
        }
        else {
            this.setState({isSaved: false});

            invalidFields.forEach(message => {
                toast.error(message);
            });
        } 
    };

    close = () => {
        if (this.props.id) {
            this.props.history.push(`/security-incidents/${this.props.id}`);
        }
        else {
            this.props.history.push(`/security-incidents/`);
        }
    };

    componentDidMount() {
        this.getData();
    };

    render() {
        const {id, editing, _new, history} = this.props;
        const {
            showLoader,
            isSaved,
            incidentCategories,
            incidentTypes,
            linkedEvidence,
            businessAssociates,
            incidentStatuses,           
            enteredDate,
            occurrenceDate,
            knownDate,
            location,
            discoveredBy,
            reportedBy,
            witnesseddBy,
            description,
            investigationStartDate,
            investigationEnddDate,
            investigatedBy,
            investigationNotes,
            mitigationNotes,
            breach,
            breachEffected,
            name,
            status,
            statusId,
            type,
            typeId,
            category,
            categoryId,
            businessAssociate,
            businessAssociateId
        } = this.state;
        const {downloadEvidence, onEvidenceUploadComplete, deleteEvidence, onFieldChange, edit, save, close} = this;

        let saveButtonClass = '';

        if (!isSaved) saveButtonClass = 'warning';

        return (
            <Loader show={showLoader} message={<Svg use="loader"/>} disableDefaultStyles={true}>
                {editing ?
                    <PageHeader title={
                            <span>
                                <span className="brand-red">{_new && <span>New </span>}Security Incident</span>
                                {description && <span>: {truncate(name, 32)}</span>}
                            </span>
                        }
                        actionItems={[
                            {label: <Svg use='close'/>, tip: 'Close', action: close},
                            {label: <Svg use='save'/>, tip:'Save', action: save, className: saveButtonClass},
                            {label: <Svg use='save-close'/>, tip: 'Save & Close', action: () => save('close')}
                        ]}
                    />
                    :
                    <PageHeader title={<span><span className="brand-red">Security Incident:</span> {truncate(name, 32)}</span>}
                        actionItems={[
                            {label: <Svg use='arrow-left'/>, tip: 'All Security Incidents', link: '/security-incidents/'},
                            {label: <Svg use='edit'/>,  tip: 'Edit Log', link: `/security-incidents/${id}/edit/`},
                            {label: <Svg use="add" />, tip: 'New Log', link: '/security-incidents/new/'}
                        ]}
                    />
                }
                <section>
                    {!_new &&
                        <List
                            className="meta"
                            inlineLabels={true}
                            items ={[
                                {label: 'Entered Date', value: enteredDate, inputType: 'date'}
                            ]}
                        />
                    } 
                </section>           
                <section>
                    <div className="card">
                        <List
                            className="grid-3"
                            editing={editing}
                            onChange={onFieldChange}
                            items={[
                                {label: 'Name', value: name, accessor:'name', required: true},
                                {label: 'Status', value: editing ? statusId : status, accessor:'statusId', inputType: 'select', options: incidentStatuses, required: true},
                                {label: 'Category', value: editing ? categoryId : category, accessor:'categoryId', inputType: 'select', options: incidentCategories, required: true},
                                {label: 'Type', value: editing ? typeId : type, accessor:'typeId', inputType: 'select', options: incidentTypes, required: true},
                                {label: 'Occurrence Date & Time', value: occurrenceDate, accessor: 'occurrenceDate', inputType: 'dateTime', required: true},
                                {label: 'Found Date & Time', value: knownDate, accessor:'knownDate', inputType: 'dateTime', required: true},                                
                                {label: 'Involved BA', value: editing ? businessAssociateId : businessAssociate, accessor:'businessAssociateId', inputType: 'select', options: businessAssociates},
                                {label: 'Location of Incident', value: location, accessor:'location'},
                                {label: 'Was ePHI Breached?', value: editing ? breach : breach ? 'Yes' : 'No', accessor:'breach', inputType: 'toggle'},
                                {label: 'Number of Effected Patients', value: breachEffected, accessor:'breachEffected', inputType: 'number', hide: !breach},                                
                                {label: 'Incident Description (What Happened?)', value: description, accessor: 'description', inputType: 'textarea'}
                            ]}
                        />                      
                    </div>                                              
                </section>
                <section>
                    <div className="card">
                        <List
                            className="grid-3"
                            editing={editing}
                            onChange={onFieldChange}
                            items={[
                                {label: 'Discovered By', value: discoveredBy, accessor:'discoveredBy'},
                                {label: 'Reported By', value: reportedBy, accessor:'reportedBy'},
                                {label: 'Witnessed By', value: witnesseddBy, accessor:'witnesseddBy'},
                                {label: 'Investigation Start Date', value: investigationStartDate, accessor:'investigationStartDate', inputType: 'date'},
                                {label: 'Investigation End Date', value: investigationEnddDate, accessor:'investigationEnddDate', inputType: 'date'},
                                {label: 'Investigated By', value: investigatedBy, accessor:'investigatedBy'},
                                {label: 'Investigation Notes', value: investigationNotes, accessor: 'investigationNotes', inputType: 'textarea'},
                                {label: 'Mitigation Notes', value: mitigationNotes, accessor: 'mitigationNotes', inputType: 'textarea'}                                  
                            ]}
                        />                      
                    </div>                                              
                </section>
                {!_new &&
                    <section>
                        <EvidenceTable
                            className="card"
                            data={linkedEvidence}
                            onUploadComplete={onEvidenceUploadComplete}
                            onRequestDownload={downloadEvidence}
                            onRequestDelete={deleteEvidence}
                            isEditing={true}
                            parentId={id}
                            typeId={2}
                        />                    
                    </section>
                }                                 
                <Prompt when={editing && !isSaved} message={location => `You have changes that arn't saved. Click "Okay" to discard these changes, or "Cancel" to stay on this page`}/>                               
                
            </Loader>
        );
    }
}

export default withRouter(withCurrentUser(SecurityIncident));
import React from 'react';
import {NavLink} from 'react-router-dom';

const Dropdown = ({onClick, isOpen, label, options, buttonClass}) => {
    
    const addBodyEvent = origin => {
        document.body.onclick = event => {
            if (!event.target.isEqualNode(origin) && !origin.contains(event.target)) setTimeout(onClick, 5);
            document.body.onclick = null;
        };
    };    
   
    const _onClick = event => {
        if (!isOpen) addBodyEvent(event.target);
        onClick();
    };

    const itemAction = (action)=> {
        action();
    };

    return (
        <div className="dropdown">
            <button onClick={_onClick} className={`${buttonClass ? buttonClass : 'button'} ${isOpen ? 'active' : ''}`}>{label && <span>{label}</span>}</button>
            {isOpen &&
                <div className={`dropdown-menu active`}>
                    <ul>
                        {options.map(({label, action, link, target, className, show}, i) => show === undefined || show ? <li key={i}>
                            {link ?
                                target ? <a className={`${className}`} href={link} target={target}>{label}</a> : <NavLink className={`${className}`} to={link} exact activeClassName="active">{label}</NavLink>
                            :
                                <button className={`${className} no-style`} onClick={event => itemAction(action, event)}>{label}</button>
                            }
                        </li> : '')}
                    </ul>
                </div>
            }
        </div>
    );
}

export default Dropdown;
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import moment from 'moment';
import Svg from '../helpers/svg';
import PageHeader from '../helpers/pageHeader';
import fpRest from '../helpers/fpRest';
import CsvImporter from '../helpers/csvImporter';
import settingsHeaderNav from '../helpers/settingsHeaderNav';
import List from '../helpers/list';
import {defaultTableFilter, DorpDownFilter} from '../helpers/tableHelpers';
import ReactTable from 'react-table';
import Loader from 'react-loader-advanced';
 import { toast } from 'react-toastify';


import 'react-table/react-table.css';

class Users extends Component {
    constructor(props) {
        super(props)
        const initSortOrder = sessionStorage.getItem(`users:sortOrder`);

        this.state = {
            showLoader: true,
            initSortOrder: initSortOrder ? JSON.parse(initSortOrder) : {id: 'lastName', desc: false},
            users: [],
            usersActive: [],
            isImporterOpen: false,
            hideInactive: true,
            isImportDropdownOpen: false,
            isDownloadDropdownOpen: false                 
        };
    };

    getData = () => {
        if (this.state.showLoader === false) this.setState({showLoader: true});

        fpRest.get('users?activeOnly=false')
        .then(res => {
            const users = res.data;
            const usersActive = res.data.filter(({active}) => active);
            this.setState({users, usersActive, showLoader: false});
        })
        .catch(err => console.log(err));          
    };

    toggleInactive = () => {
        this.setState({hideInactive: !this.state.hideInactive});
    };
    
    toggleDownloadDropdown = () => {
        this.setState({isDownloadDropdownOpen:!this.state.isDownloadDropdownOpen});
    };
    
    downloadAllUsers = async () => {
        this.setState({showLoader: true, isLoadingReport: true});
        
        try {
            const res = await fpRest.download(`reportsTest?name=usersAll&format=csv`, `Users.csv`);  
            this.setState({showLoader: false, isLoadingReport: false});    
            if (res.message == 'No data found') toast.success('There are no Users');   
        }
        catch (err) {
            console.log(err);
        }
    };    

    printAll = () => {
        console.log('Print All');
    };

    componentDidMount() {
        this.getData();        
    }

    render() {
        const {toggleInactive, downloadAllUsers, toggleDownloadDropdown,} = this;
        const {showLoader, initSortOrder, users, usersActive, hideInactive, isImporterOpen, isImportDropdownOpen, isDownloadDropdownOpen} = this.state;
   
        return (
            <Loader show={showLoader} message={<Svg use="loader"/>} disableDefaultStyles={true}>
                <PageHeader
                navItems={settingsHeaderNav('users')}
                actionItems={[
                    {label: <Svg use="add" />, tip: 'New Users', link: '/settings/users/new'},
                    //{label: <Svg use="upload" />, tip: 'Upload Users', options: [{label: 'Select CSV File', action: startImport}, {label: 'Download Import Template', link: 'https://s3.amazonaws.com/flightplan-public/import-templates/users-impot-template.csv', target: "_self"}], isOpen: isImportDropdownOpen, action: toggleImportDropdown}
                    {label: <Svg use='download'/>, tip:'Download',
                    options: [
                        {label: 'Download All Users (CSV)', action: downloadAllUsers}
                    ],
                    isOpen: isDownloadDropdownOpen, action: toggleDownloadDropdown},
                ]} />
                <section>
                    <List
                        className="meta"
                        inlineLabels={true}
                        items ={[
                            {label: 'Active Users', value: usersActive.length},
                            {label: 'Hide Inactive', value: hideInactive, editing: true, inputType: 'toggle', onChange: toggleInactive},
                        ]}
                    />
                </section>                             
                <section>
                    <div className="card">                      
                        <ReactTable
                            data={hideInactive ? usersActive : users}
                            defaultSorted={[initSortOrder]}
                            columns={[            
                                {Header: 'id', accessor: 'id', show: false},

                                {
                                    Header: 'First Name',
                                    accessor: 'firstName',
                                },
                                {
                                    Header: 'Last Name',
                                    accessor: 'lastName',
                                },
                                {
                                    Header: 'Email',
                                    accessor: 'email',
                                },                                                                 
                                {
                                    Header: 'Created Date',
                                    accessor: 'createdDate',
                                    Cell: row => row.value ? <span>{moment(row.value).format('MM/DD/Y')}</span> : 'Not Set'

                                },
                                {
                                    Header: 'Hired Date',
                                    accessor: 'hiredDate',
                                    Cell: row => row.value ? <span>{moment(row.value).format('MM/DD/Y')}</span> : 'Not Set'

                                },                                
                                {
                                    show: hideInactive ? false : true,
                                    Header: 'Status',
                                    accessor: 'active',
                                    Cell: row => row.value ? 'Active' : 'Inactive',
                                    Filter: ({onChange}) => <DorpDownFilter options={[{label: 'Active', value: '1'}, {label: 'Inactive', value: '0'}]} action={onChange.bind(this)}/>            
                                }                                                                                                                                                                                                  
                            ]}
                            noDataText="No Users Found"
                            onSortedChange={sort => sessionStorage.setItem(`users:sortOrder`, JSON.stringify(sort[0]))}
                            filterable={true}
                            defaultFilterMethod={defaultTableFilter}
                            getTdProps={(state, rowInfo, column, instance) => ({
                                onClick: e => rowInfo && this.props.history.push(`/settings/users/${rowInfo.row.id}`),
                                style: { cursor: 'pointer' }
                            })}
                        />
                    </div>
                </section>
                
           </Loader>
        );
    };
}

export default withRouter(Users);
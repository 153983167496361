import React, {Component} from 'react';
import {withRouter, Prompt} from 'react-router-dom';
import Svg from '../helpers/svg';
import List from '../helpers/list';
import PageHeader from '../helpers/pageHeader';
import fpRest from '../helpers/fpRest';
import withCurrentUser from '../helpers/withCurrentUser';
import EvidenceTable from '../evidence/evidenceTable'
import {truncate} from '../helpers/utils';
import 'react-table/react-table.css';
import Loader from 'react-loader-advanced';
 import { toast } from 'react-toastify';
import {handleGetError, handlePostPatchError} from '../helpers/errors';


class Sanction extends Component {
    constructor(props) {
        super(props) 

        this.state = {
            hideInactive: false,
            showLoader: true,
            isSaved: true,
            users: [],
            linkedEvidence: [],            
            id: '',
            summary: '',
            sanctionedUserName: '',
            sanctionedUserId: '',
            violationDate: '',
            description: '',
            actionTaken: '',
            dateEntered: '',
            userTerminated: false
        };            
    };

    getData = async () => {
        const {id, editing, _new} = this.props;

        try {
            let sanction = {};
            let users = [];

            if (!_new) {
                sanction = await fpRest.get(`sanctions/${id}`);           
                sanction = sanction.data;
            }

            if (editing) {
                users = await fpRest.get(`users`);
                users = users.data.map(({id, firstName, lastName}) => ({value: id, label: `${firstName} ${lastName}`}));
            }

            console.log(id);
            this.setState({...sanction, users, showLoader: false});
        }
        catch (err) {
            handleGetError(err, this.props);
        }
    };

    downloadEvidence = async id => {
        this.setState({showLoader: true});

        try {
            const {data} = await fpRest.get(`evidence/${id}`);

            let element = document.createElement('a');

            element.setAttribute('href', data.tempDownloadUrl);
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
            this.setState({showLoader: false});          
        }
        catch (err) {
            console.log(err);
        } 
    };    

    onEvidenceUploadComplete = async (newEvidence) => {
        this.setState({linkedEvidence: [...this.state.linkedEvidence, newEvidence]});
    }; 
    
    deleteEvidence = async id => {
        try {
            await fpRest.delete(`evidence/${id}`);

            const linkedEvidence = this.state.linkedEvidence.filter(evidence => evidence.id === id ? false : true);

            this.setState({linkedEvidence});
            toast.success('Evidence Deleted');
        }
        catch(err) {
            console.log(err);
        }
    };      

    onFieldChange = ({accessor, value}) => {
        this.setState({
            [accessor]: value,
            isSaved: false
        });
    };

    edit = () => {
        this.props.history.push(`/sanctions/${this.props.id}/edit`)
    };    
    
    validate = ({
        summary,
        sanctionedUserId,
        violationDate,
        description,
        actionTaken,
    }) => {
        let invalidFields = [];

        if (!summary) invalidFields.push('"Summary" is required');
        if (!sanctionedUserId) invalidFields.push('"Sanctioned Employee" is required');
        if (!violationDate) invalidFields.push('"Date of Violation" is required');
        if (!description) invalidFields.push('"Description" is required');
        if (!actionTaken) invalidFields.push('"Action Taken(s)" is required');

        return invalidFields;
    };

    save = async secondAction => {
        const {id, history} = this.props;
        const {
            summary,
            sanctionedUserId,
            violationDate,
            description,
            actionTaken,
            userTerminated
        } = this.state;
        const persistedState = {
            summary,
            sanctionedUserId,
            violationDate,
            description,
            actionTaken,
            userTerminated
        };
        const invalidFields = this.validate(persistedState);

        this.setState({isSaved: true});

        if (!invalidFields.length) {
            try {
                if (id) {
                    await fpRest.patch(`sanctions/${id}`, persistedState);
                    if (secondAction === 'close') {
                         history.push(`/sanctions/${id}`);
                    }
                    
                    toast.success('Sanction Saved');
                }
                else {
                    const {data} = await fpRest.post('sanctions', persistedState);
                    if (secondAction === 'close') {
                        history.push(`/sanctions/`);
                    }
                    else {
                        history.push(`/sanctions/${data.id}`);
                    }
                }
            }
            catch (err) {
                this.setState({isSaved: false});
                handlePostPatchError(err);
            }
        }
        else {
            this.setState({isSaved: false});

            invalidFields.forEach(message => {
                toast.error(message);
            });
        } 
    };

    close = () => {
        if (this.props.id) {
            this.props.history.push(`/sanctions/${this.props.id}`);
        }
        else {
            this.props.history.push(`/sanctions/`);
        }
    };

    componentDidMount() {
        this.getData();
    };

    render() {
        const {id, editing, _new, history} = this.props;
        const {
            showLoader,
            isSaved,
            hideInactive,
            users,
            linkedEvidence,
            summary,
            sanctionedUserName,
            sanctionedUserId,
            violationDate,
            description,
            actionTaken,
            dateEntered,
            userTerminated
        } = this.state;
        const {downloadEvidence, onEvidenceUploadComplete, deleteEvidence, onFieldChange, edit, save, close} = this;

        let saveButtonClass = '';

        if (!isSaved) saveButtonClass = 'warning';
        console.log(linkedEvidence);
        return (
            <Loader show={showLoader} message={<Svg use="loader"/>} disableDefaultStyles={true}>
                {editing ?
                    <PageHeader title={
                            <span>
                                <span className="brand-red">{_new && <span>New </span>}Sanction</span>
                                {summary && <span>: {truncate(summary, 15)}</span>}
                            </span>
                        }
                        actionItems={[
                            //{label: <Svg use='briefcase'/>, tip: 'All Sanctions', link: '/sanctions/'},
                            {label: <Svg use='close'/>, tip: 'Close', action: close},
                            {label: <Svg use='save'/>, tip:'Save', action: save, className: saveButtonClass},
                            {label: <Svg use='save-close'/>, tip: 'Save & Close', action: () => save('close')}
                        ]}
                    />
                    :
                    <PageHeader title={<span><span className="brand-red">Sanction:</span> {truncate(summary, 15)}</span>}
                        actionItems={[
                            {label: <Svg use='arrow-left'/>, tip: 'All Sanctions', link: '/sanctions/'},
                            {label: <Svg use='edit'/>,  tip: 'Edit Sanction', action: edit},
                            {label: <Svg use="add" />, tip: 'New Sanction', link: '/sanctions/new'}
                        ]}
                    />
                }
                {!_new &&
                    <section>
                        <List
                            className="meta"
                            inlineLabels={true}
                            items ={[
                                {label: 'Entered Date', value: dateEntered, inputType: 'date'}
                            ]}
                        /> 
                    </section>
                }           
                <section>
                    <List
                        className=" card grid-3"
                        editing={editing}
                        onChange={onFieldChange}
                        items={[
                            {label: 'Summary', value: summary, accessor:'summary', required: true},
                            {label: 'Date of Violation', value: violationDate, accessor:'violationDate', inputType: 'date', required: true},                              
                            {label: 'Sanctioned Employee', value: editing ? sanctionedUserId : sanctionedUserName, accessor: 'sanctionedUserId', inputType: 'select', options: users, required: true},
                            {label: 'Description', value: description, inputType: 'textarea', required: true},
                            {label: 'Action(s) Taken', value: actionTaken, accessor:'actionTaken', inputType: 'textarea', required: true},
                            {label: 'Employee Terminated', value: editing ? userTerminated : userTerminated ? 'Yes' : 'No', accessor:'userTerminated', inputType: 'toggle', required: true},
                        ]}
                    />                                              
                </section>
                {!_new &&
                    <section>
                        <EvidenceTable
                            className="card"
                            data={linkedEvidence}
                            onUploadComplete={onEvidenceUploadComplete}
                            onRequestDownload={downloadEvidence}
                            onRequestDelete={deleteEvidence}
                            isEditing={true}
                            parentId={id}
                            typeId={5}
                        />                    
                    </section>
                }                      
                <Prompt when={editing && !isSaved} message={location => `You have changes that arn't saved. Click "Okay" to discard these changes, or "Cancel" to stay on this page`}/>                               
                
            </Loader>
        );
    }
}

export default withRouter(withCurrentUser(Sanction));
import React from 'react';
import {UiContext} from '../index';

export default function withUi(Component) {

    return function UiComponent(props) {
        return (
        <UiContext.Consumer>
            {ui => <Component {...props} ui={ui}/>}
        </UiContext.Consumer>
        );
    };
}
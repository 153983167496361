import React from 'react';
import withUi from '../helpers/withUi';
import Dropdown from '../helpers/dropdown';
import {NavLink} from 'react-router-dom';

const PageHeader = ({title, navItems, actionItems, meta, tag, ui}) => {
    const actionButton = ({link, action, options, tip, label, className, isOpen, show}) => {
        className = className + ' action-item' 

        if (link) {
            return <NavLink className={`${className}${show === false ? ' hide' : ''}`} to={link} exact activeClassName="active" title={tip}>{label}</NavLink> 
        }
        else if (action && options) {
            return (
                show !== false ?
                    <Dropdown label={label} buttonClass='no-style' options={options} isOpen={isOpen} show={show} onClick={action} />
                :
                    ''
            );            
        }
        else if (action) {
            return <button className={`${className}${show === false ? ' hide' : ''}`} onClick={action} title={tip}>{label}</button>
        }
    };
    
    return (
        <div className={'page-top ' + ui.pageSize}>
            <header className="page-header">
                <h1>
                    {tag &&
                        <span className="tag">{tag}</span>
                    }                
                    {title}
                </h1>
                {meta && 
                    <ul className="meta">
                        {meta.map((item, index) =>
                            <li key={index}>
                                {item}
                            </li>
                        )}
                    </ul>
                }
            </header>
            <div className="nav-container">
                {navItems &&
                    <nav className="in-page-nav">
                        <ul>
                            {navItems.map((item, index) =>
                                <li key={index}>
                                    {item.link ?
                                        <NavLink className={`button ${item.className}${item.active ? ' active' : ''}${item.show === false ? ' hide' : ''}`} to={item.link} exact activeClassName="active" title={item.tip}>{item.label}</NavLink> 
                                        : //Action
                                        <button className={`button ${item.className}${item.show === false ? ' hide' : ''}`} onClick={item.action} title={item.tip}>{item.label}</button>
                                    }
                                </li>
                            )}		
                        </ul>               
                    </nav>
                } 
                <nav className="in-page-actions">
                    {actionItems && actionItems.length ?
                        <ul className="in-page-actions-menu">
                            {actionItems.map((item, index) =>
                                <li key={index}>
                                    {actionButton(item)}
                                </li>
                            )}	
                        </ul>
                    :
                    <div></div>
                    }               
                </nav>             
            </div>              
        </div>      
    );
}

export default withUi(PageHeader);
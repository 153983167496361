import React, {Component} from 'react';
import {withRouter, Prompt} from 'react-router-dom';
import Svg from '../helpers/svg';
import List from '../helpers/list';
import PageHeader from '../helpers/pageHeader';
import fpRest from '../helpers/fpRest';
import withCurrentUser from '../helpers/withCurrentUser';
import EvidenceTable from '../evidence/evidenceTable'
import {truncate} from '../helpers/utils';
import 'react-table/react-table.css';
import Loader from 'react-loader-advanced';
import CKEditor from 'ckeditor4-react';
import {toast} from 'react-toastify';
import {handleGetError, handlePostPatchError} from '../helpers/errors';


CKEditor.editorUrl = `${process.env.PUBLIC_URL}/_static/js/ckeditor/ckeditor.js`;

const getEditorConfig =  editing => ({
    width: '100%',
    height: 320,
    resize_enabled: false,
    autoGrow_onStartup: true,
    autoGrow_minHeight: 320,
    autoGrow_maxHeight: 320,
    toolbarCanCollapse: true,
    toolbarStartupExpanded: editing,
    pasteFromWordPromptCleanup: true,
    linkShowAdvancedTab: false,
    allowedContent: true,
    toolbarGroups: [
		{ name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
		{ name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
		{ name: 'forms', groups: [ 'forms' ] },
		{ name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
		{ name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
		{ name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
		{ name: 'links', groups: [ 'links' ] },
		{ name: 'insert', groups: [ 'insert' ] },
		{ name: 'styles', groups: [ 'styles' ] },
		{ name: 'colors', groups: [ 'colors' ] },
		{ name: 'tools', groups: [ 'tools' ] },
		{ name: 'others', groups: [ 'others' ] },
		{ name: 'about', groups: [ 'about' ] }
    ],
    removeButtons: 'Source,Save,NewPage,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Find,Replace,SelectAll,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Subscript,Superscript,CopyFormatting,Blockquote,CreateDiv,BidiLtr,BidiRtl,Language,Link,Image,Flash,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,FontSize,Font,Format,Styles,TextColor,BGColor,ShowBlocks,Maximize,About,Anchor,Unlink'
});

class RiskAssessment extends Component {
    constructor(props) {
        super(props) 

        this.state = {
            stateDelay: false,
            hideInactive: false,
            showLoader: true,
            isSaved: true,
            name: '',
            purpose: '',
            purposeEditor: null,
            scope: '',
            scopeEditor: null,
            assumptions: '',
            assumptionsEditor: null,
            constraints: '',
            constraintsEditor: null,
            effectivenessTimeFrame: '',
            sunsetDate: '',
            createdDate: '',
            initiatedDate: '',
            findings: '',
            findingsEditor: null,
            linkedEvidence: []
        };            
    };

    getData = async () => {
        const {id, _new} = this.props;

        try {
            let riskAssessment = {};

            if (!_new) {
                riskAssessment = await fpRest.get(`risk-assessments/${id}`);
                riskAssessment = riskAssessment.data;
            }

            this.setState({...riskAssessment, showLoader: false});
        }
        catch (err) {
            handleGetError(err, this.props);
        }
    };

    downloadEvidence = async id => {
        this.setState({showLoader: true});

        try {
            const {data} = await fpRest.get(`evidence/${id}`);

            let element = document.createElement('a');

            element.setAttribute('href', data.tempDownloadUrl);
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
            this.setState({showLoader: false});          
        }
        catch (err) {
            console.log(err);
        } 
    };    

    onEvidenceUploadComplete = async (newEvidence) => {
        this.setState({linkedEvidence: [...this.state.linkedEvidence, newEvidence]});
    }; 
    
    deleteEvidence = async id => {
        try {
            await fpRest.delete(`evidence/${id}`);

            const linkedEvidence = this.state.linkedEvidence.filter(evidence => evidence.id === id ? false : true);

            this.setState({linkedEvidence});
            toast.success('Evidence Deleted');
        }
        catch(err) {
            console.log(err);
        }
    };      

    onFieldChange = ({accessor, value}) => {
        this.setState({
            [accessor]: value,
            isSaved: false
        });
    };

    onEditorChange = (editor, name) => {
        if (editor.focusManager.hasFocus) {
            const stateDelay = setTimeout(() => this.setState({stateDelay: false}), 500);

            let state = {
                isSaved: false,
                stateDelay                
            }

            if (name === 'purpose') {
                state.purposeEditor = editor;
            }
            else if (name === 'scope') {
                state.scopeEditor = editor;
            }
            else if (name === 'assumptions') {
                state.assumptionsEditor = editor;
            }
            else if (name === 'constraints') {
                state.constraintsEditor = editor;
            }
            else if (name === 'findings') {
                state.findingsEditor = editor;
            }

            if (this.state.stateDelay) clearTimeout(this.state.stateDelay);
            this.setState(state);
        }
    };    

    edit = () => {
        this.props.history.push(`/risk-assessments/${this.props.id}/edit`)
    };    
    
    validate = ({
        name,
        initiatedDate,
        purpose
    }) => {
        let invalidFields = [];

        if (!name) invalidFields.push('"Name" is required');
        if (!initiatedDate) invalidFields.push('"Initiated Date" is required');
        if (!purpose) invalidFields.push('"Purpose" is required');

        return invalidFields;
    };

    save = async secondAction => {
        const {id, history} = this.props;
        const {
            name,
            purpose,
            purposeEditor,
            scope,
            scopeEditor,
            assumptions,
            assumptionsEditor,
            constraints,
            constraintsEditor,
            effectivenessTimeFrame,
            sunsetDate,
            initiatedDate,
            findings,
            findingsEditor,
        } = this.state;
        const persistedState = {
            name,
            purpose: purposeEditor ? purposeEditor.getData() : purpose,
            scope: scopeEditor ? scopeEditor.getData() : scope,
            assumptions: assumptionsEditor ? assumptionsEditor.getData() : assumptions,
            constraints: constraintsEditor ? constraintsEditor.getData() : constraints,
            effectivenessTimeFrame,
            sunsetDate,
            initiatedDate,
            findings: findingsEditor ? findingsEditor.getData() : findings
        };
        const invalidFields = this.validate(persistedState);

        this.setState({isSaved: true});

        if (!invalidFields.length) {
            try {
                if (id) {
                    await fpRest.patch(`risk-assessments/${id}`, persistedState);
                    if (secondAction === 'close') {
                         history.push(`/risk-assessments/${id}`);
                    }
                    
                    toast.success('Risk Assessment Saved');
                }
                else {
                    const {data} = await fpRest.post('risk-assessments', persistedState);
                    if (secondAction === 'close') {
                        history.push(`/risk-assessments/`);
                    }
                    else {
                        history.push(`/risk-assessments/${data.id}`);
                    }
                }
            }
            catch (err) {
                this.setState({isSaved: false});
                handlePostPatchError(err);
            }
        }
        else {
            this.setState({isSaved: false});

            invalidFields.forEach(message => {
                toast.error(message);
            });
        } 
    };

    close = () => {
        if (this.props.id) {
            this.props.history.push(`/risk-assessments/${this.props.id}`);
        }
        else {
            this.props.history.push(`/risk-assessments/`);
        }
    };

    componentDidMount() {
        this.getData();
    };

    shouldComponentUpdate = (nextProps, nextState) => {
        return !nextState.stateDelay;
    }

    render() {
        const {id, editing, _new, history} = this.props;
        const {
            showLoader,
            isSaved,
            name,
            purpose,
            scope,
            assumptions,
            constraints,
            effectivenessTimeFrame,
            sunsetDate,
            createdDate,
            initiatedDate,
            findings,
            linkedEvidence
        } = this.state;
        const {downloadEvidence, onEvidenceUploadComplete, deleteEvidence, onFieldChange, onEditorChange, edit, save, close} = this;

        let saveButtonClass = '';

        if (!isSaved) saveButtonClass = 'warning';

        return (
            <Loader show={showLoader} message={<Svg use="loader"/>} disableDefaultStyles={true}>
                {editing ?
                    <PageHeader title={
                            <span>
                                <span className="brand-red">{_new && <span>New </span>}Risk Assessment</span>
                                {name && <span>: {truncate(name, 15)}</span>}
                            </span>
                        }
                        actionItems={[
                            {label: <Svg use='close'/>, tip: 'Close', action: close},
                            {label: <Svg use='save'/>, tip:'Save', action: save, className: saveButtonClass},
                            {label: <Svg use='save-close'/>, tip: 'Save & Close', action: () => save('close')}
                        ]}
                    />
                    :
                    <PageHeader title={<span><span className="brand-red">Risk Assessment:</span> {truncate(name, 15)}</span>}
                        actionItems={[
                            {label: <Svg use='arrow-left'/>, tip: 'All Risk Assessments', link: '/risk-assessments/'},
                            {label: <Svg use='edit'/>,  tip: 'Edit Risk Assessment', action: edit},
                            {label: <Svg use="add" />, tip: 'New Risk Assessment', link: '/risk-assessments/new'}
                        ]}
                    />
                }
                {!_new &&
                    <section>
                        <List
                            className="meta"
                            inlineLabels={true}
                            items ={[
                                {label: 'Created Date', value: createdDate, inputType: 'date'}
                            ]}
                        /> 
                    </section>
                }
                <section>
                    <List
                        className=" card grid-3"
                        editing={editing}
                        onChange={onFieldChange}
                        items={[
                            {label: 'Name', value: name, accessor:'name', required: true},
                            {label: 'Initiated Date', value: initiatedDate, accessor:'initiatedDate', inputType: 'date', required: true},
                            {label: 'Sunset Date', value: sunsetDate, accessor:'sunsetDate', inputType: 'date'},
                            {label: 'Effectiveness Time Frame', value: effectivenessTimeFrame, accessor:'effectivenessTimeFrame', inputType: 'textArea'}                                
                        ]}
                    />                                              
                </section>
                {(purpose || _new) &&
                    <section className="cards grid-2">
                        <div>
                            <h2 className="header-alt-1 clear-fix"><span style={{float: 'left'}}>Purpose<span className="required"> *</span></span></h2>                     
                            <CKEditor
                                data={purpose}
                                readOnly={!editing}
                                onChange={({editor}) => onEditorChange(editor, 'purpose')}
                                onBeforeLoad={CKEDITOR => (CKEDITOR.disableAutoInline = true)}
                                config={getEditorConfig(editing)}
                            />
                        </div> 
                        <div>
                        <h2 className="header-alt-1 clear-fix"><span style={{float: 'left'}}>Scope</span></h2>                     
                            <CKEditor
                                data={scope}
                                readOnly={!editing}
                                onChange={({editor}) => onEditorChange(editor, 'scope')}
                                onBeforeLoad={CKEDITOR => (CKEDITOR.disableAutoInline = true)}
                                config={getEditorConfig(editing)}
                            />
                        </div>
                        <div>
                            <h2 className="header-alt-1 clear-fix"><span style={{float: 'left'}}>Assumptions</span></h2>                     
                            <CKEditor
                                data={assumptions}
                                readOnly={!editing}
                                onChange={({editor}) => onEditorChange(editor, 'assumptions')}
                                onBeforeLoad={CKEDITOR => (CKEDITOR.disableAutoInline = true)}
                                config={getEditorConfig(editing)}
                            />
                        </div> 
                        <div>
                            <h2 className="header-alt-1 clear-fix"><span style={{float: 'left'}}>Constraints</span></h2>                     
                            <CKEditor
                                data={constraints}
                                readOnly={!editing}
                                onChange={({editor}) => onEditorChange(editor, 'constraints')}
                                onBeforeLoad={CKEDITOR => (CKEDITOR.disableAutoInline = true)}
                                config={getEditorConfig(editing)}
                            />
                        </div> 
                        <div>
                            <h2 className="header-alt-1 clear-fix"><span style={{float: 'left'}}>Findings</span></h2>                     
                            <CKEditor
                                data={findings}
                                readOnly={!editing}
                                onChange={({editor}) => onEditorChange(editor, 'findings')}
                                onBeforeLoad={CKEDITOR => (CKEDITOR.disableAutoInline = true)}
                                config={getEditorConfig(editing)}
                            />
                        </div>                                                                                                       
                        {_new ?
                            <div><p className="center">Evidence can be added after saving.</p></div>
                        :
                            <EvidenceTable
                                data={linkedEvidence}
                                onUploadComplete={onEvidenceUploadComplete}
                                onRequestDownload={downloadEvidence}
                                onRequestDelete={deleteEvidence}
                                isEditing={true}
                                parentId={id}
                                typeId={10}
                            />
                        }                    
                    </section>
                }
                <Prompt when={editing && !isSaved} message={location => `You have changes that arn't saved. Click "Okay" to discard these changes, or "Cancel" to stay on this page`}/>                               
                
            </Loader>
        );
    }
}

export default withRouter(withCurrentUser(RiskAssessment));
import {Auth} from 'aws-amplify';
import axios from 'axios';
import fileDownload from 'js-file-download';
import config from '../config'

const api = (meth, path, body, onUploadProgress) => new Promise((resolve, reject) => {
    Auth.currentSession()
    .then(creds => {
        const options = {
            method: meth,
            url: config.apiUrl + path,
            headers: {'Authorization': creds.idToken.jwtToken}
        }

        if (onUploadProgress !== undefined && typeof onUploadProgress === 'function') {
            options.onUploadProgress = onUploadProgress;
        }

        if (meth !== 'get' || meth !== 'delete') options.data = body;

        return resolve(axios(options))
    })
    .catch(err => reject(err)); 
});

const download = (path, fileName) => new Promise(async (resolve, reject) => {
    try {
        const creds = await Auth.currentSession()
        const {data} = await axios.get(config.apiUrl + path, {
            headers: {'Authorization': creds.idToken.jwtToken},
        });

        if (!data.message) { 
            let element = document.createElement('a');
            console.log(fileName);
            element.setAttribute('href', data);
            element.setAttribute('download', fileName);
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
            //fileDownload(data, fileName);
        }
        return resolve(data);
    }
    catch (err) {
        reject(err);
    }

});

const fpRest = {
    download,
    get: (path) => api('get', path),
    delete: (path) => api('delete', path),    
    post: (path, body, onUploadProgress) => api('post', path, body, onUploadProgress),
    patch: (path, body, onUploadProgress) => api('patch', path, body, onUploadProgress)
};

export default fpRest;
import React from 'react';
import {SignIn, ForgotPassword, Authenticator} from "aws-amplify-react";
import FpSingnIn from './signIn';
import FpForgotPassword from './forgotPassword';
import config from '../config'

export default function withAuth(Component) {
    function AppComponent(props) {
        if (props.authState == "signedIn") {
            return <Component {...props}/>
        }
        else {
            return null;
        }    
    };

    return function ReturnedComponent(props) {
        return (
            <Authenticator hide={[SignIn, ForgotPassword]} amplifyConfig={config.cognito}>
                <FpSingnIn/>
                <FpForgotPassword/> 
                <AppComponent {...props}/>
            </Authenticator>
        );
    };
}
import {toast} from 'react-toastify';

export const handleGetError = (err, props) => {
    if (err.response && err.response.status === 404) {
        props.history.push(`/`);
    }
};

export const handlePostPatchError = (err, props) => {
    console.log(err);
    toast.error('Oops! Something went wrong.');
};
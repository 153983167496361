import React, {Component} from 'react';
import {withRouter, Prompt} from 'react-router-dom';
import Svg from '../helpers/svg';
import List from '../helpers/list';
import PageHeader from '../helpers/pageHeader';
import fpRest from '../helpers/fpRest';

import 'react-table/react-table.css';
import Loader from 'react-loader-advanced';
 import { toast } from 'react-toastify';


class BusinessAssociateContact extends Component {
    constructor(props) {
        super(props) 

        this.state = {
            showLoader: true,
            isSaved: true,
            associateName: '',
            associateId: '',
            firstName: '',
            lastName: '',
            email: '',
            phoneOffice: '',
            phoneOfficeExt: '',
            phoneCell: '',
            jobTitle: '',
            active: null
        };            
    };

    getData = async () => {
        const {id, _new} = this.props;

        if (this.state.showLoader === false) this.setState({showLoader: true});

        try {
            if (!_new) {
                const {data} = await fpRest.get(`business-associates/contacts/${id}`);
                const contact =  data;

                console.log(contact);
                this.setState({...contact, showLoader: false});
            }
            else {
                console.log(this.props);
                const {associateId} = this.props;
                const session = sessionStorage.getItem(`associate-${associateId}`);
                const associate = session ? JSON.parse(session) : await fpRest.get(`business-associates/${associateId}`);
                const associateName = associate.data ? associate.data.name : associate.name ? associate.name : 'Not Found';

                this.setState({showLoader: false, active: true, associateName});
            }
        }
        catch (err) {
            console.log(err);
        }
    };

    downloadEvidence = async id => {
        this.setState({showLoader: true});

        try {
            const {data} = await fpRest.get(`evidence/${id}`);

            let element = document.createElement('a');

            element.setAttribute('href', data.tempDownloadUrl);
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
            this.setState({showLoader: false});          
        }
        catch (err) {
            console.log(err);
        } 
    };

    onFieldChange = ({accessor, value}) => {
        this.setState({
            [accessor]: value,
            isSaved: false
        });
    };    
    
    validate = ({firstName, lastName}) => {
        let invalidFields = [];
    
        if (!firstName) invalidFields.push('"First Name" is required');
        if (!lastName) invalidFields.push('"Last Name" is required');
    
        return invalidFields;
    };
    
    save = async secondAction => {
        const {id, history} = this.props;
        const {
            firstName,
            lastName,
            email,
            phoneOffice,
            phoneOfficeExt,
            phoneCell,
            jobTitle,
            active
        } = this.state;
        const persistedState = {
            firstName,
            lastName,
            email,
            phoneOffice,
            phoneOfficeExt,
            phoneCell,
            jobTitle,
            active            
        };
        const invalidFields = this.validate(persistedState);
        console.log(persistedState);
        this.setState({isSaved: true});

        if (!invalidFields.length) {
            try {
                if (id) {
                    const {associateId} = this.state;
                    
                    await fpRest.patch(`business-associates/contacts/${id}`, {...persistedState, associateId});
                    if (secondAction === 'close') {
                         history.push(`/business-associates/${associateId}/`);
                    }
                    toast.success('Associate Contact Saved');
                }
                else {
                    const {associateId} = this.props;
                    const {data} = await fpRest.post('business-associates/contacts', {...persistedState, associateId});

                    if (secondAction === 'close') {
                        history.push(`/business-associates/${associateId}/`);
                    }
                    else {
                        history.push(`/business-associates/contacts/${data.id}`);
                    }
                }
            }
            catch (err) {
                this.setState({isSaved: false});
                handlePostPatchError(err);
            }
        }
        else {
            invalidFields.forEach(message => {
                toast.error(message);
            });
        } 
    };

    componentDidMount() {
        this.getData();
    };

    render() {
        const {id, editing, _new} = this.props;
        const associateId = this.props.associateId ? this.props.associateId : this.state.associateId; 
        const {
            showLoader,
            isSaved,
            associateName,
            firstName,
            lastName,
            email,
            phoneOffice,
            phoneOfficeExt,
            phoneCell,
            jobTitle,
            active               
        } = this.state;
        const onFieldChange = this.onFieldChange.bind(this);
        const save = this.save.bind(this);

        let saveButtonClass = '';

        if (!isSaved) saveButtonClass = 'warning';
        console.log(isSaved, editing);

        return (
            <Loader show={showLoader} message={<Svg use="loader"/>} disableDefaultStyles={true}>
                {editing ?
                    <PageHeader title={
                            <span>
                                <span className="brand-red">{_new && <span>New </span>}Associate Contact</span>
                                {firstName && <span>: {firstName + ' ' + lastName}</span>}
                            </span>
                        }
                        actionItems={[
                            {label: <Svg use='close'/>, tip: `${associateName} Contacts`, link: `/business-associates/${associateId}`},
                            {label: <Svg use='save'/>, tip:'Save', action: save, className: saveButtonClass},
                            {label: <Svg use='save-close'/>, tip: 'Save & Close', action: () => save('close')}
                        ]}
                    />
                    :
                    <PageHeader title={<span><span className="brand-red">Associate Contact:</span> {firstName + ' ' + lastName}</span>}
                        actionItems={[
                            {label: <Svg use='arrow-left'/>, tip: `${associateName} Contacts`, link: `/business-associates/${associateId}`},
                            {label: <Svg use='edit'/>,  tip: 'Edit Contact', link: `/business-associates/contacts/${id}/edit`},
                            {label: <Svg use="add" />, tip: 'New Contact', link: `/business-associates/${associateId}/contacts/new`}
                        ]}
                    />
                }
                <section>
                    <List
                        className="meta"
                        inlineLabels={true}
                        items ={[
                            {label: 'Business Associate', value: associateName, link: `/business-associates/${associateId}/`}
                        ]}
                    />
                </section>                                    
                <section>
                    <div className="grid-2 card">
                        <List
                            className="grid-1"
                            editing={editing}
                            onChange={onFieldChange}
                            items={[
                                {label: 'First Name', value: firstName, required: true},
                                {label: 'Last Name', value: lastName, required: true},                              
                                {label: 'Title', accessor: 'jobTitle', value: jobTitle},
                                {label: 'Email', value: email, inputType: 'email'}
                            ]}
                        />
                        <List
                            className="grid-1"
                            editing={editing}
                            onChange={onFieldChange}
                            items={[
                                {label: 'Office Phone', accessor: 'phoneOffice', value: phoneOffice, inputType: 'tel-national'},
                                {label: 'Office Ext', accessor: 'phoneOfficeExt', value: phoneOfficeExt, inputType: 'number'}, 
                                {label: 'Cell Phone', accessor: 'phoneCell', value: phoneCell, inputType: 'tel-national'},
                                {
                                    label: 'Active',
                                    value: editing ? active : active ? 'yes' : 'No',
                                    inputType: 'toggle',                                 
                                }                        
                            ]}                            
                        />                           
                    </div>    
                </section>
                <Prompt when={editing && !isSaved} message={location => `You have changes that arn't saved. Click "Okay" to discard these changes, or "Cancel" to stay on this page`}/>                               
                
            </Loader>
        );
    }
}

export default withRouter(BusinessAssociateContact);
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import moment from 'moment';
import Svg from '../helpers/svg';
import PageHeader from '../helpers/pageHeader';
import fpRest from '../helpers/fpRest';
import CsvImporter from '../helpers/csvImporter';
import List from '../helpers/list';
import {defaultTableFilter, DorpDownFilter} from '../helpers/tableHelpers';
import ReactTable from 'react-table';
import Loader from 'react-loader-advanced';
 import { toast } from 'react-toastify';


import 'react-table/react-table.css';

class RiskAssessments extends Component {
    constructor(props) {
        super(props)
        const initSortOrder = sessionStorage.getItem(`riskAssessments:sortOrder`);

        this.state = {
            showLoader: true,
            initSortOrder: initSortOrder ? JSON.parse(initSortOrder) : {id: 'createdDate', desc: true},
            riskAssessments: [],
            isImporterOpen: false,
            hideInactive: true,
            isImportDropdownOpen: false      
        };
    };

    getData = () => {
        if (this.state.showLoader === false) this.setState({showLoader: true});

        fpRest.get('risk-assessments')
        .then(res => {
            const riskAssessments = res.data;
            console.log(riskAssessments);
            this.setState({riskAssessments, showLoader: false});
        })
        .catch(err => console.log(err));          
    };

    startImport = () => {
        this.setState({isImporterOpen: true});
    };

    closeImporter = () => {
        this.setState({isImporterOpen: false});
    };

    toggleImportDropdown = () => {
        this.setState({isImportDropdownOpen:!this.state.isImportDropdownOpen});
    };    

    toggleInactive = () => {
        this.setState({hideInactive: !this.state.hideInactive});
    };

    uploadRiskAssessments = riskAssessments => {
        const getData = this.getData.bind(this);
        const policyPromises = riskAssessments.map(log => fpRest.post('risk-assessments', {...log, active: 1}));
        Promise.all(policyPromises)
        .then(res => {
            getData();
            toast.success('Risk Assessments Uploaded');
        })
        .catch(err => { 
            if (err.response && err.response.data) {
                err.response.data.errors.forEach(err => {
                    toast.error(`import failed: ${err}`);
                });
                console.log(err.response.data);
            }
            else {
                console.log(err);
                toast.error(`Oops! Something didn't work right. Risk Assessments weren't imported`);
            }
        });
    };

    printAll = () => {
        console.log('Print All');
    };

    componentDidMount() {
        this.getData();        
    }

    render() {
        const {toggleImportDropdown, startImport, closeImporter, uploadRiskAssessments, toggleInactive} = this;
        const {showLoader, initSortOrder, riskAssessments, isImporterOpen, isImportDropdownOpen} = this.state;
   
        return (
            <Loader show={showLoader} message={<Svg use="loader"/>} disableDefaultStyles={true}>
                <PageHeader title="Risk Assessments" actionItems={[
                    {label: <Svg use="add" />, tip: 'New Risk Analysis', link: '/risk-assessments/new'},
                    //{label: <Svg use="upload" />, tip: 'Upload Risk Analysis', options: [{label: 'Select CSV File', action: startImport}, {label: 'Download Import Template', link: 'https://s3.amazonaws.com/flightplan-public/import-templates/riskAssessments-impot-template.csv', target: "_self"}], isOpen: isImportDropdownOpen, action: toggleImportDropdown}
                ]} />
                <section>
                    <List
                        className="meta"
                        inlineLabels={true}
                        items ={[
                            {label: 'Total Assessments', value: riskAssessments.length},
                        ]}
                    />
                </section>                
                <section>
                    <div className="card">                      
                        <ReactTable
                            data={riskAssessments}
                            defaultSorted={[initSortOrder]}
                            columns={[            
                                {Header: 'id', accessor: 'id', show: false},
                                {
                                    Header: 'Created Date',
                                    accessor: 'createdDate',
                                    width: 240,
                                },                                
                                {
                                    Header: 'Name',
                                    accessor: 'name',
                                },
                                {
                                    Header: 'Sunset Date',
                                    accessor: 'sunsetDate',
                                    width: 240,
                                },                                                                                                                                    
                            ]}
                            noDataText="No Logs Found"
                            onSortedChange={sort => sessionStorage.setItem(`riskAssessments:sortOrder`, JSON.stringify(sort[0]))}
                            filterable={true}
                            defaultFilterMethod={defaultTableFilter}
                            getTdProps={(state, rowInfo, column, instance) => ({
                                onClick: e => rowInfo && this.props.history.push(`/risk-assessments/${rowInfo.row.id}`),
                                style: { cursor: 'pointer' }
                            })}
                        />
                    </div>
                </section>
                
           </Loader>
        );
    };
}

export default withRouter(RiskAssessments);
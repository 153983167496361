import React, {Component} from 'react';
import ReactTable from 'react-table';
import Modal from 'react-modal';
import FileUploader from '../helpers/fileUploader';
import Svg from '../helpers/svg';
import moment from 'moment';

class EvidenceTable extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            isUploaderOpen: false,
            isModalOpen: false,
            idForDeletion: null
        };

    };

    deleteItem = () => {
        this.props.onRequestDelete(this.state.idForDeletion);
        this.closeModal();
    };

    openEvidenceUploader = () => {
        this.setState({isUploaderOpen: true});
    };

    closeEvidenceUploader = () => {
        this.setState({isUploaderOpen: false});
    };
    
    openModal = id => {
        this.setState({isModalOpen: true, idForDeletion: id});
    };  

    closeModal = () => {
        this.setState({isModalOpen: false});
    };    

    render = () => {
        const {data, title, onUploadComplete, onRequestDownload, isEditing, parentId, typeId, className} = this.props;
        const {isUploaderOpen, isModalOpen} = this.state;
        const openEvidenceUploader = this.openEvidenceUploader.bind(this);
        const closeEvidenceUploader = this.closeEvidenceUploader.bind(this);
        const closeModal = this.closeModal.bind(this);
        const openModal = this.openModal.bind(this);
        const deleteItem = this.deleteItem.bind(this);
        
        return (
            <div className={className}>
                <h2 className="header-alt-1 clear-fix"><span style={{float: 'left'}}>{title ? title : 'Evidence'}</span>{isEditing && <button onClick={openEvidenceUploader} style={{float: 'right'}} className="no-style corner"><Svg use="upload"/></button>}</h2>
                <ReactTable
                    data={data}
                    columns={[
                        {
                            Header: '',
                            accessor:'id',
                            show: isEditing ? true : false,
                            width: 64,
                            className: 'action',                                    
                            Cell: row => <Svg use="delete"/>
                        },
                        {Header: 'File Name', accessor:'name', filterable: true},
                        {Header: 'Description', accessor:'description', filterable: true},
                        {
                            Header: 'Date Uploaded',
                            accessor:'dateUploaded',
                            filterable: true,
                            Cell: row => <span>{moment(row.value).utcOffset('-05:00').format('MM/DD/Y')}</span>
                        }
                    ]}
                    defaultSorted={[{
                        id: 'dateUploaded',
                        desc: true
                    }]}                            
                    noDataText="No Files"
                    className="small-table"
                    defaultPageSize={200}
                    minRows={20}
                    showPageJump={false}
                    showPageSizeOptions={false}
                    showPaginationBottom={false}                         
                    getTdProps={(state, rowInfo, column, instance) => ({
                        onClick: e => rowInfo && (column.Header === '' ? openModal(rowInfo.row.id) : onRequestDownload(rowInfo.row.id)),
                        style: {cursor: 'pointer'},
                    })}                            
                />
                <FileUploader
                    open={isUploaderOpen}
                    onRequestClose={closeEvidenceUploader}
                    onUploadComplete={onUploadComplete}
                    title="Give this file a name."
                    parentId={parentId}
                    typeId={typeId}
                />
                <Modal
                    isOpen={isModalOpen}
                    //onAfterOpen={this.afterOpenModal}
                    onRequestClose={closeModal}
                    closeTimeoutMS={300}
                    className="modal-content"
                    overlayClassName="modal-"
                    bodyOpenClassName="modal-active"
                    ariaHideApp={false}
                    contentLabel="Modal"
                >
                
                    <h3>Are you sure you want to delete this evidence?</h3>
                    <div><button onClick={closeModal} className="button">No</button><button onClick={deleteItem} className="button">Yes</button></div>
                </Modal>                                                 
            </div>
        );
    };
};

export default EvidenceTable;
import React, {Component} from 'react';
import withCurrentUser from '../helpers/withCurrentUser';
import Svg from '../helpers/svg';
import PageHeader from '../helpers/pageHeader';
import fpRest from '../helpers/fpRest';
import CsvImporter from '../helpers/csvImporter';
import List from '../helpers/list';
import {defaultTableFilter, DorpDownFilter} from '../helpers/tableHelpers';
import ReactTable from 'react-table';
import moment from 'moment';
import Loader from 'react-loader-advanced';
 import { toast } from 'react-toastify';
import Modal from 'react-modal';


import 'react-table/react-table.css';

class Policies extends Component {
    constructor(props) {
        super(props)
        const manageModeSession = sessionStorage.getItem(`policies:managedMode`);
        const initSortOrder = sessionStorage.getItem(`policies:sortOrder`);

        this.state = {
            showLoader: true,
            isLoadingReport: false,
            initSortOrder: initSortOrder ? JSON.parse(initSortOrder) : {id: 'dateCreated', desc: true},
            policiesList: [],
            stageOptions: [],
            users: [],
            departments: [],
            isImporterOpen: false,
            isImportDropdownOpen: false,
            isDownloadDropdownOpen: false,
            isSignOffUserSelectOpen: false,
            isSignOffDepartmentSelectOpen: false,
            isSignOffPolicySelectOpen: false,
            isGettingExs: null,
            manageMode: manageModeSession && manageModeSession === 'true' ? true : false,
            authorization: {}      
        };
    };

    getData = () => {
        const {currentUser} = this.props;
        const authorizedEndpoints = currentUser.authorizedEndpoints;
        const isAdmin = currentUser.isAdmin;
        const authorization = {
            currentUserGet: authorizedEndpoints.filter(({func, queryParameters}) => func === 'getPolicies' && queryParameters && queryParameters[0].parameter === 'assignedToCurrentUser').length > 0 ? true : false,
            get: (authorizedEndpoints.filter(({func}) => func === 'getPolicies').length > 0 && this.currentUserGet === false) || isAdmin ? true : false,
            patch: (authorizedEndpoints.filter(({func}) => func === 'patchPolicies').length > 0) || isAdmin ? true : false,
            post: (authorizedEndpoints.filter(({func}) => func === 'postPolicies').length > 0) || isAdmin ? true : false
        };
        const apiCalls = [fpRest.get(`policies/stages`)];
        console.log(authorization);
        if (authorization.get) {
            apiCalls.push(fpRest.get('policies'));
            apiCalls.push(fpRest.get('users'));
            apiCalls.push(fpRest.get('departments'));
        }
        else if (authorization.currentUserGet) {
            apiCalls.push(fpRest.get(`policies?assignedToCurrentUser=true`));
            this.setState({manageMode: false});
        }
        if (this.state.showLoader === false) this.setState({showLoader: true});
        //if (currentUser.jobRoleIds.length) apiCalls.push(fpRest.get(`job-descriptions/${currentUser.jobRoleId}`));

        Promise.all(apiCalls)
        .then(res => {
            console.log(res);
            const stageOptions = res[0].data;
            const users = authorization.get ? res[2].data : [];
            const departments = authorization.get ? res[3].data : [];
            //const draftOption = res[0].data.filter(({title}) => title === 'Draft' ? true : false)[0];
            const policiesList = res[1].data.map(policy => {
                policy.signOffReq = policy.linkedJobDescriptionIds.reduce((acc, jobDescId, i) => {
                    return currentUser.jobRoleIds.indexOf(jobDescId) > -1 || acc === true ? true : false
                }, false);                 

                policy.userSignOffs.forEach(signOff => {
                    if (!policy.signOffDate) {
                        policy.signOffDate = signOff.userId === currentUser.id ? moment(signOff.dateSignoff).utcOffset('-05:00').format('MM/DD/Y') : '';
                    }
                });
                
                //???
                policy.dateCreated = moment.utc(policy.dateCreated).format('MM/DD/Y');

                return policy;
            });
            this.setState({policiesList, stageOptions, users, departments, authorization, showLoader: false});
        })
        .catch(err => console.log(err));           
    };

    toggleImportDropdown = () => {
        this.setState({isImportDropdownOpen:!this.state.isImportDropdownOpen});
    };

    toggleDownloadDropdown = () => {
        this.setState({isDownloadDropdownOpen:!this.state.isDownloadDropdownOpen});
    };

    toggleManageMode = () => {       
        this.setState({manageMode: !this.state.manageMode}, () => {
            sessionStorage.setItem(`policies:managedMode`, this.state.manageMode);
        });
    };

    toggleSignOffUserSelect = (isGettingExs) => {
        this.setState({isSignOffUserSelectOpen:!this.state.isSignOffUserSelectOpen, isGettingExs});
    };

    toggleSignOffDepartmentSelect = (isGettingExs) => {
        this.setState({isSignOffDepartmentSelectOpen:!this.state.isSignOffDepartmentSelectOpen, isGettingExs});
    };

    toggleSignOffPolicySelect = (isGettingExs) => {
        this.setState({isSignOffPolicySelectOpen:!this.state.isSignOffPolicySelectOpen, isGettingExs});
    };

    startImport = () => {
        this.setState({isImporterOpen: true});
    };

    closeImporter = () => {
        this.setState({isImporterOpen: false});
    };

    uploadPolicies = policies => {
        const getData = this.getData.bind(this);
        const policyPromises = policies.map(policy => fpRest.post('policies', policy));

        this.setState({showLoader: true});
        Promise.all(policyPromises)
        .then(res => {
            getData();
            toast.success('Import Successful');
        })
        .catch(err => {
            toast.error('Import Failed');
            console.error(err)
        });
    };

    downloadAllPolicies = async () => {
        this.setState({showLoader: true, isLoadingReport: true});
        
        try {
            await fpRest.download(`reportsTest?name=policiesAll`, `All Policies.pdf`);  
            this.setState({showLoader: false, isLoadingReport: false});       
        }
        catch (err) {
            console.log(err);
        }
    };

    downloadMyPolicies = async () => {
        const {id, firstName, lastName} = this.props.currentUser;

        this.setState({showLoader: true, isLoadingReport: true});

        try {
            const res = await fpRest.download(`reportsTest?name=policiesUser&id=${id}`, `Policies For ${firstName} ${lastName}.pdf`);  
            this.setState({showLoader: false, isLoadingReport: false});
            if (res.message == 'No data found') toast.success('There are no policies assigned to you');       
        }
        catch (err) {
            console.log(err);
        }
    };

    downloadAllSignOffExceptions = async () => {
        this.setState({showLoader: true, isLoadingReport: true});
        
        try {
            const res = await fpRest.download(`reportsTest?name=policiesAllSignOffExceptions`, `All Policy Sign Off Exceptions.pdf`);  
            this.setState({showLoader: false, isLoadingReport: false});       
            if (res.message == 'No data found') toast.success('There are no sign off exceptions');         
        }
        catch (err) {
            console.log(err);
        }
    };

    downloadSignOffExceptionsByDepartment = async (id, department) => {
        this.setState({showLoader: true, isLoadingReport: true, isSignOffDepartmentSelectOpen: false});
        
        try {
            const res = await fpRest.download(`reportsTest?name=policiesDepartmentSignOffExceptions&id=${id}`, `Policy Sign Off Exceptions - ${department}.pdf`);  
            this.setState({showLoader: false, isLoadingReport: false, isSignOffDepartmentSelectOpen: true});   
            if (res.message == 'No data found') toast.success('There are no sign off exceptions for that department');   
        }
        catch (err) {
            console.log(err);
        }
    };

    downloadSignOffExceptionsByUser = async (id, userName) => {
        this.setState({showLoader: true, isLoadingReport: true, isSignOffUserSelectOpen: false});
        
        try {
            const res = await fpRest.download(`reportsTest?name=policiesUserSignOffExceptions&id=${id}`, `Policy Sign Off Exceptions - ${userName}.pdf`);  
            this.setState({showLoader: false, isLoadingReport: false, isSignOffUserSelectOpen: true});   
            if (res.message == 'No data found') toast.success('There are no sign off exceptions for that user');   
        }
        catch (err) {
            console.log(err);
        }
    };

    downloadSignOffExceptionsByPolicy = async (id, policy) => {
        this.setState({showLoader: true, isLoadingReport: true, isSignOffPolicySelectOpen: false});
        
        try {
            const res = await fpRest.download(`reportsTest?name=policieSingleSignOffExceptions&id=${id}`, `Sign Off Exceptions - ${policy}.pdf`);  
            this.setState({showLoader: false, isLoadingReport: false, isSignOffPolicySelectOpen: true});   
            if (res.message == 'No data found') toast.success('There are no sign off exceptions for that policy');   
        }
        catch (err) {
            console.log(err);
        }
    };

    downloadAllSignOffs = async () => {
        this.setState({showLoader: true, isLoadingReport: true});
        
        try {
            const res = await fpRest.download(`reportsTest?name=policiesAllSignOffs`, `All Policy Sign Offs.pdf`);  
            this.setState({showLoader: false, isLoadingReport: false});    
            if (res.message == 'No data found') toast.success('There are no sign offs');   
        }
        catch (err) {
            console.log(err);
        }
    };
    
    downloadSignOffsByUser = async (id, userName) => {
        this.setState({showLoader: true, isLoadingReport: true, isSignOffUserSelectOpen: false});
        
        try {
            const res = await fpRest.download(`reportsTest?name=policiesUserSignOffs&id=${id}`, `Policy Sign Offs - ${userName}.pdf`);  
            this.setState({showLoader: false, isLoadingReport: false, isSignOffUserSelectOpen: true});    
            if (res.message == 'No data found') toast.success('There are no sign offs for that user');   
        }
        catch (err) {
            console.log(err);
        }
    };     

    printSelected = () => {
        console.log('Print Selected');
    };

    componentDidMount() {
        this.getData();      
    };

    render() {
        const {history} = this.props;
        const {showLoader, isLoadingReport, initSortOrder, policiesList, stageOptions, users, departments, isImporterOpen, isImportDropdownOpen, isDownloadDropdownOpen, isSignOffUserSelectOpen, isSignOffDepartmentSelectOpen, isSignOffPolicySelectOpen, manageMode, authorization, isGettingExs} = this.state;
        const {toggleManageMode, uploadPolicies, closeImporter, toggleImportDropdown, startImport, downloadAllPolicies, downloadMyPolicies, downloadAllSignOffExceptions, downloadSignOffExceptionsByUser, downloadSignOffExceptionsByDepartment, downloadSignOffExceptionsByPolicy, downloadAllSignOffs, toggleDownloadDropdown, downloadSignOffsByUser, toggleSignOffUserSelect, toggleSignOffDepartmentSelect, toggleSignOffPolicySelect} = this;
        const currentUserPolicies = policiesList.filter(policy => policy.signOffReq);

        return (
            <Loader show={showLoader} message={<div><div>{isLoadingReport ? 'Generating Document' : ''}</div><Svg use="loader"/></div>} disableDefaultStyles={true}>
                <PageHeader title="Policies & Procedures" actionItems={[
                    {label: <Svg use="add" />, tip: 'New Policy', link: '/policies/new', show: authorization.post},
                    {label: <Svg use="upload" />, tip: 'Upload Policies', options: [{label: 'Select CSV File', action: startImport}, {label: 'Download Import Template', link: 'https://s3.amazonaws.com/flightplan-public/import-templates/policy-impot-template.csv', target: "_self"}], isOpen: isImportDropdownOpen, action: toggleImportDropdown, show: authorization.post},
                    {label: <Svg use='download'/>, tip:'Download',
                    options: [
                        {label: 'Download All Policies', action: downloadAllPolicies},
                        {label: 'Download My Policies', action: downloadMyPolicies},
                        {label: 'Download All Sign Off Exceptions', action: downloadAllSignOffExceptions},
                        {label: 'Download Sign Off Exceptions For User', action: () => toggleSignOffUserSelect(true)},
                        {label: 'Download Sign Off Exceptions For Department', action: () => toggleSignOffDepartmentSelect(true)},
                        {label: 'Download Sign Off Exceptions For Policy', action: () => toggleSignOffPolicySelect(true)},
                        {label: 'Download All Sign Offs', action: downloadAllSignOffs},
                        {label: 'Download Sign Offs For User', action: () => toggleSignOffUserSelect(false)}
                    ],
                    isOpen: isDownloadDropdownOpen, action: toggleDownloadDropdown, show: authorization.post},
                ]} />
                {authorization.get && 
                    <section>
                        <List
                            className="meta"
                            inlineLabels={true}
                            items ={[
                                {label: 'Sign Off Mode (My Policies)', rightLabel: 'Manage Mode (All Policies)', value: manageMode, editing: true, inputType: 'toggle', onChange: toggleManageMode},
                                {label: 'Number of Policies', value: manageMode ? policiesList.length : currentUserPolicies.length}
                            ]}
                        />
                    </section>
                }                
                <section>
                    <div className="card">                      
                        <ReactTable
                            data={manageMode ? policiesList : currentUserPolicies}
                            defaultSorted={[initSortOrder]}
                            columns={manageMode ?
                                [
                                    {Header: 'id', accessor: 'id', show: false},
                                    {
                                        Header: 'Date Created',
                                        accessor: 'dateCreated',
                                        width: 240,
                                    },
                                    {Header: 'Name', accessor: 'title'},
                                    {Header: 'Version', accessor: 'versionNum', width: 200},
                                    {
                                        Header: 'Stage',
                                        accessor: 'stageName',
                                        width: 240,                             
                                        Filter: ({onChange}) => <DorpDownFilter options={stageOptions.map(option => ({value: option.title, label: option.title}))} action={onChange.bind(this)}/>                                          
                                    }
                                ]
                            :
                                [
                                    {Header: 'id', accessor: 'id', show: false},
                                    {
                                        Header: 'Sign Off Date',
                                        accessor: 'signOffDate',
                                        width: 240,
                                        Cell: row => <span>{row.value ? row.value : <span>Sign Off Required</span>}</span>                                        
                                    },
                                    {Header: 'Name', accessor: 'title'},
                                    {Header: 'Version', accessor: 'versionNum', width: 200}
                                ]
                            }
                            noDataText={manageMode ? 'No Policies Found' : 'There are no policies assigned to your job role'}
                            onSortedChange={sort => sessionStorage.setItem(`policies:sortOrder`, JSON.stringify(sort[0]))}
                            filterable={true}
                            defaultFilterMethod={defaultTableFilter}
                            getTdProps={(state, rowInfo, column, instance) => ({
                                onClick: e => rowInfo && history.push(`/policies/${rowInfo.row.id}`),
                                style: { cursor: 'pointer' }
                            })}
                        />
                    </div>
                    
                </section>
                
                <CsvImporter
                    open={isImporterOpen}
                    onRequestClose={closeImporter}
                    onRequestUpload={res => uploadPolicies(res)}
                    title="Map Policy Fields"
                    mappingOptions={[
                        {value: 'title', label: 'Policy Title', required: true},
                        {value: 'text', label: 'Policy Text', required: true},
                        {
                            value: 'stageId',
                            label: 'Policy Stage',
                            required: true,
                            allowedCellValues: stageOptions.map(option => 
                                ({value: option.id, label: option.title})
                            )
                        },
                    ]}
                />
                <Modal
                    isOpen={isSignOffUserSelectOpen}
                    className="modal-content"
                    overlayClassName="modal-"
                    closeTimeoutMS={300}
                    bodyOpenClassName="modal-active"
                    ariaHideApp={false}
                    contentLabel="Modal"
                >
                    <button className="button close" onClick={toggleSignOffUserSelect}>
                        <Svg use="close"/>
                    </button>
                    <h3>Select a User to Download Data For</h3>
                    
                    <div>
                        <ReactTable
                            data={users}
                            defaultSorted={[{
                                id: 'firstName',
                                desc: true
                            }]}
                            columns= {[
                                {Header: 'id', accessor: 'id', show: false},
                                {Header: 'First Name', accessor: 'firstName'},
                                {Header: 'Last Name', accessor: 'lastName'},
                            ]}
                            noDataText={'Nothing Found'}
                            filterable={true}
                            defaultFilterMethod={defaultTableFilter}
                            defaultPageSize={10}
                            getTdProps={(state, rowInfo, column, instance) => ({
                                onClick: e => {
                                    if(rowInfo) {
                                        isGettingExs ? downloadSignOffExceptionsByUser(rowInfo.row.id, `${rowInfo.row.firstName} ${rowInfo.row.lastName}`) : downloadSignOffsByUser(rowInfo.row.id, `${rowInfo.row.firstName} ${rowInfo.row.lastName}`);
                                    }
                                },
                                style: { cursor: 'pointer' }
                            })}                        
                        />
                    </div>
                </Modal>
                <Modal
                    isOpen={isSignOffDepartmentSelectOpen}
                    className="modal-content"
                    overlayClassName="modal-"
                    closeTimeoutMS={300}
                    bodyOpenClassName="modal-active"
                    ariaHideApp={false}
                    contentLabel="Modal"
                >
                    <button className="button close" onClick={toggleSignOffDepartmentSelect}>
                        <Svg use="close"/>
                    </button>
                    <h3>Select a Department to Download Data For</h3>
                    
                    <div>
                        <ReactTable
                            data={departments}
                            defaultSorted={[{
                                id: 'name',
                                desc: true
                            }]}
                            columns= {[
                                {Header: 'id', accessor: 'id', show: false},
                                {Header: 'Department', accessor: 'name'},
                            ]}
                            noDataText={'Nothing Found'}
                            filterable={true}
                            defaultFilterMethod={defaultTableFilter}
                            defaultPageSize={10}
                            getTdProps={(state, rowInfo, column, instance) => ({
                                onClick: e => {
                                    if(rowInfo) {
                                        downloadSignOffExceptionsByDepartment(rowInfo.row.id, rowInfo.row.name);
                                    }
                                },
                                style: { cursor: 'pointer' }
                            })}                        
                        />
                    </div>
                </Modal>
                <Modal
                    isOpen={isSignOffPolicySelectOpen}
                    className="modal-content"
                    overlayClassName="modal-"
                    closeTimeoutMS={300}
                    bodyOpenClassName="modal-active"
                    ariaHideApp={false}
                    contentLabel="Modal"
                >
                    <button className="button close" onClick={toggleSignOffPolicySelect}>
                        <Svg use="close"/>
                    </button>
                    <h3>Select a Policy to Download Data For</h3>
                    
                    <div>
                        <ReactTable
                            data={policiesList}
                            defaultSorted={[{
                                id: 'title',
                                desc: true
                            }]}
                            columns= {[
                                {Header: 'id', accessor: 'id', show: false},
                                {Header: 'Policy', accessor: 'title'},
                            ]}
                            noDataText={'Nothing Found'}
                            filterable={true}
                            defaultFilterMethod={defaultTableFilter}
                            defaultPageSize={10}
                            getTdProps={(state, rowInfo, column, instance) => ({
                                onClick: e => {
                                    if(rowInfo) {
                                        downloadSignOffExceptionsByPolicy(rowInfo.row.id, rowInfo.row.title);
                                    }
                                },
                                style: { cursor: 'pointer' }
                            })}                        
                        />
                    </div>
                </Modal>                                 
           </Loader>
        );
    };
}

export default withCurrentUser(Policies);
import React, {Component} from 'react';
import {withRouter, Prompt} from 'react-router-dom';
import Svg from '../helpers/svg';
import List from '../helpers/list';
import PageHeader from '../helpers/pageHeader';
import fpRest from '../helpers/fpRest';
import withCurrentUser from '../helpers/withCurrentUser';
import {truncate} from '../helpers/utils';
import LinkedDataTable from '../helpers/linkedDataTable';
import EvidenceTable from '../evidence/evidenceTable'
import 'react-table/react-table.css';
import Loader from 'react-loader-advanced';
 import { toast } from 'react-toastify';
import {handleGetError, handlePostPatchError} from '../helpers/errors';


class SecurityAwarenessEntry extends Component {
    constructor(props) {
        super(props); 

        this.state = {
            showLoader: true,
            isSaved: true,
            users: [],
            policies: [],
            linkedPolicies: [],
            linkedEvidence: [],
            attendees: [],
            name: '',
            enteredDate: '',
            eventDate: '',
            topic: '',
            presentedBy: '',
            description: ''
        };            
    };

    getData = async () => {
        const {id, editing, _new} = this.props;

        try {
            if (!_new) {
                let [securityAwarenessEntry, users, policies] = await Promise.all([fpRest.get(`security-awareness/${id}`), fpRest.get(`users?activeOnly=false`), fpRest.get(`policies`)]);

                securityAwarenessEntry = securityAwarenessEntry.data;
                users = users.data.map(({id, firstName, lastName}) => ({value: id, label: `${firstName} ${lastName}`}));
                policies = policies.data.map(({id, title}) => ({value: id, label: title}));

                this.setState({...securityAwarenessEntry, users, policies, showLoader: false});
            }
            else {
                this.setState({showLoader: false});
            }
        }
        catch (err) {
            handleGetError(err, this.props);
        }
    };
    
    downloadEvidence = async id => {
        this.setState({showLoader: true});

        try {
            const {data} = await fpRest.get(`evidence/${id}`);

            let element = document.createElement('a');

            element.setAttribute('href', data.tempDownloadUrl);
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
            this.setState({showLoader: false});          
        }
        catch (err) {
            console.log(err);
        } 
    };
    
    onEvidenceUploadComplete = async (newEvidence) => {
        this.setState({linkedEvidence: [...this.state.linkedEvidence, newEvidence]});
    }; 
    
    deleteEvidence = async id => {
        try {
            await fpRest.delete(`evidence/${id}`);

            const linkedEvidence = this.state.linkedEvidence.filter(evidence => evidence.id === id ? false : true);

            this.setState({linkedEvidence});
            toast.success('Evidence Deleted');
        }
        catch(err) {
            console.log(err);
        }
    };    

    onFieldChange = ({accessor, value}) => {
        this.setState({
            [accessor]: value,
            isSaved: false
        });
    };

    onAttendeeChange = attendees => {
        this.setState({attendees, isSaved: false});

        setTimeout(() =>{
            if (!this.state.isSaved) this.save(null, false);
        }, 1000);           
    };

    onLinkedPoliciesChange = linkedPolicies => {
        this.setState({linkedPolicies, isSaved: false});
        console.log(linkedPolicies);

        setTimeout(() =>{
            if (!this.state.isSaved) this.save(null, false);
        }, 1000);        
    };

    edit = () => {
        this.props.history.push(`/security-awareness/${this.props.id}/edit`)
    };    
    
    validate = ({
        name,
    }) => {
        let invalidFields = [];
    
        if (!name) invalidFields.push('"Name" is required');
    
        return invalidFields;
    };

    save = async secondAction => {
        const {id, history} = this.props;
        const {
            linkedPolicies,
            attendees,
            name,
            eventDate,
            topic,
            presentedBy,
            description
        } = this.state;
        const persistedState = {
            linkedPolicies,
            attendees,
            name,
            eventDate,
            topic,
            presentedBy,
            description
        };
        const invalidFields = this.validate(persistedState);
        console.log(persistedState);
        this.setState({isSaved: true});

        if (!invalidFields.length) {
            try {
                if (id) {
                    await fpRest.patch(`security-awareness/${id}`, persistedState);
                    if (secondAction === 'close') {
                         history.push(`/security-awareness/${id}`);
                    }
                    
                    toast.success('Security Awareness Saved');
                }
                else {
                    const {data} = await fpRest.post('security-awareness', persistedState);
                    if (secondAction === 'close') {
                        history.push(`/security-awareness/`);
                    }
                    else {
                        history.push(`/security-awareness/${data.id}`);
                    }
                }
            }
            catch (err) {
                this.setState({isSaved: false});
                handlePostPatchError(err);
            }
        }
        else {
            this.setState({isSaved: false});

            invalidFields.forEach(message => {
                toast.error(message);
            });
        } 
    };

    close = () => {
        if (this.props.id) {
            this.props.history.push(`/security-awareness/${this.props.id}`);
        }
        else {
            this.props.history.push(`/security-awareness/`);
        }
    };

    componentDidMount() {
        if (this.props._new) {
            this.setState({showLoader: false});
        }
        else {
            this.getData();
        }
    };

    render() {
        const {id, editing, _new, history} = this.props;
        const {
            showLoader,
            isSaved,
            users,
            policies,
            linkedPolicies,
            linkedEvidence,
            attendees,
            name,
            enteredDate,
            eventDate,
            topic,
            presentedBy,
            description
        } = this.state;
        const {onEvidenceUploadComplete, downloadEvidence, deleteEvidence, onFieldChange, onAttendeeChange, onLinkedPoliciesChange, edit, save, close} = this;

        let saveButtonClass = '';

        if (!isSaved) saveButtonClass = 'warning';
        
        return (
            <Loader show={showLoader} message={<Svg use="loader"/>} disableDefaultStyles={true}>
                {editing ?
                    <PageHeader title={
                            <span>
                                <span className="brand-red">{_new && <span>New </span>}Security Awareness</span>
                                {name && <span>: {truncate(name, 15)}</span>}
                            </span>
                        }
                        actionItems={[
                            //{label: <Svg use='briefcase'/>, tip: 'All Security Awarenesss Entries', link: '/security-awareness/'},
                            {label: <Svg use='close'/>, tip: 'Close', action: close},
                            {label: <Svg use='save'/>, tip:'Save', action: save, className: saveButtonClass},
                            {label: <Svg use='save-close'/>, tip: 'Save & Close', action: () => save('close')}
                        ]}
                    />
                    :
                    <PageHeader title={<span><span className="brand-red">Security Awareness:</span> {truncate(name, 15)}</span>}
                        actionItems={[
                            {label: <Svg use='arrow-left'/>, tip: 'All Security Awarenesss Entries', link: '/security-awareness/'},
                            {label: <Svg use='edit'/>,  tip: 'Edit Security Awarenesss Entries', action: edit},
                            {label: <Svg use="add" />, tip: 'New Security Awarenesss Entry', link: '/security-awareness/new'}
                        ]}
                    />
                }
                {!_new &&
                    <section>
                        <List
                            className="meta"
                            inlineLabels={true}
                            items ={[
                                {label: 'Entered Date', value: enteredDate, inputType: 'date'}
                            ]}
                        />
                    </section>
                }           
                <section>
                    <List
                        className=" card grid-3"
                        editing={editing}
                        onChange={onFieldChange}
                        items={[
                            {label: 'Name', value: name, accessor:'name', required: true},                             
                            {label: 'Event Date', value: eventDate, accessor:'eventDate', inputType: 'date'}, 
                            {label: 'Topic', value: topic, accessor:'topic'},
                            {label: 'Presented By', value: presentedBy, accessor: 'presentedBy'},   
                            {label: 'Description', value: description, accessor: 'description', inputType: 'textarea'}                   
                        ]}
                    />                                              
                </section>
                {_new ?
                    <section><p className="center">Evidence &amp; other linked data can be added after saving.</p></section>
                :
                    <section className="cards grid-2">
                        <LinkedDataTable
                            title="Attendees"
                            dataSet={users}
                            linkedDataIds={attendees}
                            onChange={onAttendeeChange}
                        />
                        <LinkedDataTable
                            title="Linked Policies"
                            dataSet={policies}
                            linkedDataIds={linkedPolicies}
                            onChange={onLinkedPoliciesChange}
                        />                            
                        <EvidenceTable
                            data={linkedEvidence}
                            onUploadComplete={onEvidenceUploadComplete}
                            onRequestDownload={downloadEvidence}
                            onRequestDelete={deleteEvidence}
                            isEditing={true}
                            parentId={id}
                            typeId={8}
                        />                 
                    </section>
                }                                
                <Prompt when={editing && !isSaved} message={location => `You have changes that arn't saved. Click "Okay" to discard these changes, or "Cancel" to stay on this page`}/>                               
                
            </Loader>
        );
    }
}

export default withRouter(withCurrentUser(SecurityAwarenessEntry));
import React from 'react';
import Modal from 'react-modal';
import Svg from './svg';


const SignedInUserMenu = ({user, isOpen, onRequestClose, onRequestSignOut}) => {
    const {firstName, lastName, email, tenantName} = user;
    const signout = () => {
        onRequestSignOut();
        onRequestClose();
    };

    return (
        <Modal
            isOpen={isOpen}
            className="modal-content"
            overlayClassName="modal-"
            closeTimeoutMS={300}
            bodyOpenClassName="modal-active"
            ariaHideApp={false}
            contentLabel="Modal"
        >
            <button className="button close" onClick={onRequestClose}>
                <Svg use="close"/>
            </button>
            <h3>
                {firstName} {lastName}
                <div className="reset-text">
                Email: {email}
                </div>
                <div className="reset-text">
                Company: {tenantName}
                </div>
            </h3>
            <div>
                <button className="button" onClick={signout}>Sign Out</button>
                {/*
                <button className="button">Change Password</button>
                */}
            </div>
        </Modal>        
    );
}

export default SignedInUserMenu;
import React, {Component} from 'react';
import {withRouter, Prompt} from 'react-router-dom';
import withCurrentUser from '../helpers/withCurrentUser';
import EvidenceTable from '../evidence/evidenceTable'
import Modal from 'react-modal';
import Svg from '../helpers/svg';
import PageHeader from '../helpers/pageHeader';
import List from '../helpers/list';
import fpRest from '../helpers/fpRest';
import {defaultTableFilter, selectColtSort} from '../helpers/tableHelpers';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import moment from 'moment';
import Loader from 'react-loader-advanced';
import {toast} from 'react-toastify';
import CKEditor from 'ckeditor4-react';
import sanitizeHtml from 'sanitize-html';
import {handleGetError, handlePostPatchError} from '../helpers/errors';
import {truncate} from '../helpers/utils';


CKEditor.editorUrl = `${process.env.PUBLIC_URL}/_static/js/ckeditor/ckeditor.js`;

const findSignoffs = (policy, currentUser) => {
    const data = policy;

    data.signOffReq = data.linkedJobDescriptionIds.reduce((acc, jobDescId, i) => {
        return currentUser.jobRoleIds.indexOf(jobDescId) > -1 || acc === true ? true : false
    }, false);                 
    data.userSignOffs.forEach(signOff => {
        if (!data.signOffDate) {
            data.signOffDate = signOff.userId === currentUser.id ? signOff.dateSignoff : '';
        }
    });
    
    return data;
};

const mapUsersToSignOffs = (userSignOffs, users) => {
    console.log(userSignOffs, users);
    users = users.reduce((acc, user) => {
        acc[user.id] = user;

        return acc;
    }, {});

    return userSignOffs.map(signOff => {
        const {userId} = signOff;

        signOff.userName = `${users[userId].firstName} ${users[userId].lastName}`

        return signOff;
    });
};

class Policy extends Component {
    constructor(props) {
        super(props) 

        this.state = {
            showLoader: true,
            isLoadingReport: false,
            isSaved: true,
            isDownloadDropdownOpen: false,
            stateDelay: null,
            editor: null,
            id: '',
            title: '',
            text: '',
            versionNum: '',
            dateCreated: '',
            dateRevised: '',
            stageName: '',
            stageId: '',            
            linkedCitationIds: [],
            citations: [],
            mappedCitations: [],
            modalIsOpen: false,
            modalContent: {},
            linkedJobDescriptionIds: [],
            mappedJobDescriptions: [],
            linkedEvidence: [],
            revisions: [],
            jobDescriptions: [],
            stageOptions: [],
            userSignOffs: [],
            authorization: [],
            users: []
        };            
    };

    getData = async () => {
        const {id, revId, newRev, _new, currentUser} = this.props;
        const authorizedEndpoints = currentUser.authorizedEndpoints;
        const isAdmin = currentUser.isAdmin;
        const authorization = {
            currentUserGet: authorizedEndpoints.filter(({func, queryParameters}) => func === 'getPolicy' && queryParameters && queryParameters[0].parameter === 'assignedToCurrentUser').length > 0 ? true : false,
            get: (authorizedEndpoints.filter(({func}) => func === 'getPolicy').length > 0 && this.currentUserGet === false) || isAdmin ? true : false,
            patch: (authorizedEndpoints.filter(({func}) => func === 'patchPolicy').length > 0) || isAdmin ? true : false,
            post: (authorizedEndpoints.filter(({func}) => func === 'postPolicy').length > 0) || isAdmin ? true : false
        };

        let policy = {
            text: '',
            versionNum: 1,
            dateCreated: moment.utc().format(),
            linkedCitationIds: [],
            linkedJobDescriptionIds: []
        };

        try {
            if (authorization.currentUserGet) {
                const {data} = await fpRest.get(`policies/${id}?assignedToCurrentUser=true`);
                const policy = findSignoffs(data, currentUser);
                this.setState({...policy, authorization, showLoader: false});
            }
            else {
                if (!_new) {              
                    const {data} = revId ? await fpRest.get(`policies/${id}/${revId}`) : await fpRest.get(`policies/${id}`);
                    policy = newRev ? {...data, versionNum: data.versionNum + 1, dateRevised: moment.utc().format()} : findSignoffs(data, currentUser);
                    console.log(data);
                }
                const editingData = await Promise.all([fpRest.get(`citations`), fpRest.get(`job-descriptions`), fpRest.get(`policies/stages`)]);
                const stageOptions = editingData[2].data;
                const mappedCitations = this.mapLinkedData(policy.linkedCitationIds, editingData[0].data).sort(selectColtSort);
                const citations = mappedCitations.map(citation => {
                    const {   
                        id,
                        name,
                        text,
                        desc,
                        spec,
                        reqAdd,
                        sectionId,
                        sectionName,
                        ruleName,
                    } = citation;
                    
                    return {
                        id,
                        name,
                        text,
                        desc,
                        spec,
                        reqAdd,
                        sectionId,
                        sectionName,
                        ruleName,                        
                    };
                });
                const mappedJobDescriptions = this.mapLinkedData(policy.linkedJobDescriptionIds, editingData[1].data).sort(selectColtSort);
                const jobDescriptions = mappedJobDescriptions.map(dscriptions => {
                    const { 
                        id,  
                        departmentId,
                        description,
                        name,
                    } = dscriptions;
                    
                    return {
                        id,  
                        departmentId,
                        description,
                        name,                       
                    };
                });

                this.setState({...policy, mappedCitations, citations, mappedJobDescriptions, jobDescriptions, stageOptions, authorization, showLoader: false});
                
                //Breaking calls apart to speed up load time. Needs more work.
                const usersRes = await fpRest.get(`users?activeOnly=false`);
                const userSignOffs = mapUsersToSignOffs(policy.userSignOffs, usersRes.data);
                this.setState({userSignOffs, users: usersRes.data}, () => console.log('Signoffs', this.state.userSignOffs));
            }
        }
        catch (err) {
            handleGetError(err, this.props);
        }
    };

    downloadPolicy = async () => {
        this.setState({showLoader: true, isLoadingReport: true});
        
        try {
            await fpRest.download(`reportsTest?name=policiesSingle&id=${this.props.id}&verisionNum=${this.state.versionNum}`, `Policy - ${this.state.title}.pdf`);  
            this.setState({showLoader: false, isLoadingReport: false});       
        }
        catch (err) {
            console.log(err);
        }
    };

    downloadSignOffExceptions = async () => {
        this.setState({showLoader: true, isLoadingReport: true});
        
        try {
            const res = await fpRest.download(`reportsTest?name=policieSingleSignOffExceptions&id=${this.props.id}&verisionNum=${this.state.versionNum}`, `Policy Sign Off Exceptions - ${this.state.title}.pdf`);  
            this.setState({showLoader: false, isLoadingReport: false});    
            if (res.message == 'No data found') toast.success('There are no sign off exceptions for this policy');   
        }
        catch (err) {
            console.log(err);
        }
    };

    downloadSignOffs = async () => {
        this.setState({showLoader: true, isLoadingReport: true});
        
        try {
            const res = await fpRest.download(`reportsTest?name=policiesSingleSignOffs&id=${this.props.id}&verisionNum=${this.state.versionNum}`, `Policy Sign Offs - ${this.state.title}.pdf`);  
            this.setState({showLoader: false, isLoadingReport: false});    
            if (res.message == 'No data found') toast.success('There are no sign offs for this policy');   
        }
        catch (err) {
            console.log(err);
        }
    };    

    downloadEvidence = async id => {
        this.setState({showLoader: true});

        try {
            const {data} = await fpRest.get(`evidence/${id}`);

            let element = document.createElement('a');

            element.setAttribute('href', data.tempDownloadUrl);
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
            this.setState({showLoader: false});          
        }
        catch (err) {
            console.log(err);
        } 
    };

    toggleDownloadDropdown = () => {
        this.setState({isDownloadDropdownOpen:!this.state.isDownloadDropdownOpen});
    };
    
    onEvidenceUploadComplete = async (newEvidence) => {
        this.setState({linkedEvidence: [...this.state.linkedEvidence, newEvidence]});
    }; 
    
    deleteEvidence = async id => {
        try {
            await fpRest.delete(`evidence/${id}`);

            const linkedEvidence = this.state.linkedEvidence.filter(evidence => evidence.id === id ? false : true);

            this.setState({linkedEvidence});
            toast.success('Evidence Deleted');
        }
        catch(err) {
            console.log(err);
        }
    };  
    
    onEditorChange = editor => {
        if (editor.focusManager.hasFocus) {
            const stateDelay = setTimeout(() => this.setState({stateDelay: null}), 500);

            if (this.state.stateDelay) clearTimeout(this.state.stateDelay);
            this.setState({
                isSaved: false,
                editor,
                stateDelay                
            });
        }
    };

    onFieldChange = e => {
        if (e) {
            if (e.target) {
                const {name, value} = e.target;
                this.setState({
                    [name]: value,
                    isSaved: false
                });
            }
            else {
                const {value} = e;
                this.setState({
                    stageId: value,
                    isSaved: false
                });
            }
        }
    };
    
    onEditorPaste = ({editor, data}) => {
        console.log(data);
        if (data.type === 'html') {
            data.dataValue = sanitizeHtml(data.dataValue, {
                allowedTags: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'blockquote', 'p', 'a', 'ul', 'ol',
                'nl', 'li', 'b', 'i', 'strong', 'em', 'strike', 'code', 'hr', 'br', 'span', 'div',
                'table', 'thead', 'caption', 'tbody', 'tr', 'th', 'td', 'pre', 'sub', 'sup', 'del', 'ins', 'section', 'u', 'address'],                
                allowedAttributes: {
                    '*': ['style'],
                    a: ['href', 'name', 'target'],
                    img: ['src']
                },
                allowedStyles: {
                    '*': {
                        'background': [/^[\s\S]+$/],
                        'background-color': [/^[\s\S]+$/],
                        'font-size': [/^[\s\S]+$/],
                        'color': [/^[\s\S]+$/],
                        'text-align': [/^left$/, /^right$/, /^center$/, /^justify$/],
                    }
                },                         
                transformTags: {
                    'b': 'strong',
                    'blockquote': 'section',
                    'i': 'em',
                    'span': (tagName, attribs, text) => {
                        console.log(attribs);
                        if (attribs.style) {
                            return {
                                tagName: 'span',
                                attribs
                            };
                        }
                        else {
                            return '';
                        }
                    }
                }                                            
            });
        }
    };

    updatelinkedJobDescriptionIds = jobDescriptionsId => {
        const {linkedJobDescriptionIds, jobDescriptions} = this.state;
        const {id} = this.props;
        const newlinkedJobDescriptionIds = this.updateLinkedData(linkedJobDescriptionIds, jobDescriptions, jobDescriptionsId);
        const mappedJobDescriptions = this.mapLinkedData(newlinkedJobDescriptionIds, jobDescriptions);
        const isSaved = false;
        
        this.setState({mappedJobDescriptions, linkedJobDescriptionIds: newlinkedJobDescriptionIds, isSaved});
        
        setTimeout(() =>{
            if (!this.state.isSaved && id) this.save(null, false);
        }, 1000);        
    }    

    updatelinkedCitationIds = citationId => {
        const {citations, linkedCitationIds} = this.state;
        const {id} = this.props;
        const newlinkedCitationIds = this.updateLinkedData(linkedCitationIds, citations, citationId); 
        const mappedCitations = this.mapLinkedData(newlinkedCitationIds, citations);
        const isSaved = false;

        this.setState({mappedCitations, linkedCitationIds: newlinkedCitationIds, isSaved});

        setTimeout(() =>{
            if (!this.state.isSaved && id) this.save(null, false);
        }, 3000);        
    };    

    mapLinkedData = (linkedData, data) => {
        return data.map(dataItem => {    
            const linkedDataArray = linkedData.filter(id => id === dataItem.id);

            if (linkedDataArray.length) {
                return {
                    ...dataItem,
                    isLinked: true,
                    linkedIcon: <Svg use="toggle-on" />
                };
            }

            return {
                ...dataItem,
                linkedIcon: <Svg use="toggle-off" />
            };
        });        
    };

    updateLinkedData = (linkedData, data, id) => {
        if (linkedData.filter(linkedDataItemId => linkedDataItemId === id).length) {
            return linkedData.filter(linkedDataItemId => linkedDataItemId !== id);
        }
        else {
            const newDataItem = data.filter(dataItem => dataItem.id === id)[0];
            return [...linkedData, newDataItem.id]; 
        }         
    };
    
    validate = ({title, stageId, text}) => {
        let invalidFields = [];

        if (title === '') invalidFields.push('"Policy Name" is required');
        if (stageId === '') invalidFields.push('"Policy Stage" is required');
        if (text.length < 10) invalidFields.push('"Police Body" must be longer');

        return invalidFields;
    };

    edit = () => {
        this.setState({
            modalIsOpen: true,
            modalContent: {
                header: '',
                body: <div><button onClick={() => this.props.history.push(`/policies/${this.props.id}/edit`)} className="button">Edit Current Revision</button><button onClick={() => this.props.history.push(`/policies/${this.props.id}/new`)} className="button">Create New Revision</button></div>
            }
        }); 
    };

    save = async (secondAction, showMessage) => {
        const {id, newRev, history} = this.props;
        const {title, stageId, editor,linkedCitationIds, linkedJobDescriptionIds, linkedEvidence, text} = this.state;
        const persistedState = {
            title,
            stageId,
            text: editor ? editor.getData() : text,
            linkedCitationIds,
            linkedJobDescriptionIds,
            linkedEvidence: linkedEvidence.map(item => item.id)
        };
        const invalidFields = this.validate(persistedState);
        this.setState({isSaved: true});

        if (!invalidFields.length) {
            try {
                if (id) {
                    if (newRev) await fpRest.post(`policies/${id}`);
                    await fpRest.patch(`policies/${id}`, persistedState);
                    if (secondAction === 'close') {
                         history.push(`/policies/${id}`);
                    }
                    else if (newRev) {
                        history.push(`/policies/${id}/edit`);
                    }
                    
                    if (showMessage !== false) toast.success('Policy Saved');
                }
                else {
                    const {data} = await fpRest.post('policies', persistedState);
                    if (secondAction === 'close') {
                        history.push(`/policies/`);
                    }
                    else {
                        history.push(`/policies/${data.id}`);
                    }
                } 
            }
            catch (err) {
                this.setState({isSaved: false});
                handlePostPatchError(err);
            }
        }
        else {
            invalidFields.forEach(message => {
                toast.error(message);
            });
        }           
    };

    close = () => {
        if (this.props.id) {
            this.props.history.push(`/policies/${this.props.id}`);
        }
        else {
            this.props.history.push(`/policies/`);
        }
    };

    signOff = async () => {
        const {currentUser} = this.props;
        const {id, versionNum} = this.state;

        this.setState({showLoader: true});

        try {
            await fpRest.post(`policies/signoffs`, {userId: currentUser.id, policyId: id, policyVersion: versionNum, signOffDate: moment().format()});
            this.setState({
                showLoader: false, signOffDate: moment().format(),
                userSignOffs: [...this.state.userSignOffs, {userId: currentUser.id, userName: currentUser.firstName + ' ' + currentUser.lastName, signOffDate: moment().format()}]
            });  
        }
        catch (err) {
            this.setState({showLoader: false});
            toast.error('Signoff Was Unsuccessful');
            console.log(err);
        }
    };

    openModal = (type, id) => {
        let modalContent = null;

        if (type === 'citation') {
            modalContent = this.state.mappedCitations.filter(citation => citation.id === id)
            .map(citation => {
                return {
                    html: true,
                    closeButton: true,
                    header: citation.name,
                    body: citation.text
                }            
            })[0];
        }       

        this.setState({modalIsOpen: true, modalContent});        
    };

    closeModal = () => {
        this.setState({modalIsOpen: false});
    };

    componentDidMount = () => {
        this.getData();
    };
    
    shouldComponentUpdate = (nextProps, nextState) => {
        return nextState.stateDelay ? false : true;
    }
    
    render() {
        const {id, _new, newRev, revId, editing, history} = this.props;
        const {showLoader, isLoadingReport, modalIsOpen, modalContent, stageOptions, isSaved, title, text, versionNum, dateCreated, dateRevised, stageName, stageId, mappedCitations, mappedJobDescriptions, linkedEvidence, revisions, signOffReq, signOffDate, userSignOffs, authorization, isDownloadDropdownOpen} = this.state;
        const {signOff, onFieldChange, updatelinkedCitationIds, updatelinkedJobDescriptionIds, edit, save, closeModal, openModal, close, downloadPolicy, downloadSignOffs, downloadSignOffExceptions, onEvidenceUploadComplete, downloadEvidence, deleteEvidence, onEditorChange, toggleDownloadDropdown, onEditorPaste} = this;

        let saveButtonClass = '';
        let tag = null;

        if (!isSaved) saveButtonClass = 'warning';
        if (_new) tag = 'Creating New Policy'
        if (revId) tag = `Viewing Revision ${revId}`
        if (newRev) tag = 'Creating New Revision' 
       
        return (
            <Loader show={showLoader} message={<div><div>{isLoadingReport ? 'Generating Document' : ''}</div><Svg use="loader"/></div>} disableDefaultStyles={true}>
                {editing ?
                    <PageHeader tag={tag} title={
                        <span>
                            <span className="brand-red">{_new || newRev ? <span>New </span> : ''}Policy{newRev && <span> Rev</span>}</span>
                            {title && <span>: {truncate(title, 256)}</span>}
                        </span>
                    }
                        actionItems={[
                            {label: <Svg use='download'/>, tip:'Download', options: [{label: 'Download Policy', action: downloadPolicy}, {label: 'Download Sign Offs', action: downloadSignOffs}, {label: 'Download Sign Off Exceptions', action: downloadSignOffExceptions}], isOpen: isDownloadDropdownOpen, action: toggleDownloadDropdown, show: authorization.post},
                            {label: <Svg use='save'/>, tip:'Save', action: save, className: saveButtonClass},
                            {label: <Svg use='save-close'/>, tip: 'Save & Close', action: () => save('close')},
                            {label: <Svg use='close'/>, tip: 'Close', action: () => close()}
                        ]}
                    />
                    :
                    <PageHeader tag={tag} title={<span><span className="brand-red">Policy{revId && <span> Rev</span>}:</span> {truncate(title, 256)}</span>}
                        actionItems={[
                            {label: <Svg use='arrow-left'/>, tip: 'All Policies', link: revId ? `/policies/${id}`  : '/policies'},
                            {label: <Svg use='download'/>, tip:'Download', options: [{label: 'Download Policy', action: downloadPolicy}, {label: 'Download Sign Offs', action: downloadSignOffs, show: authorization.post}, {label: 'Download Sign Off Exceptions', action: downloadSignOffExceptions, show: !revId && authorization.post}], isOpen: isDownloadDropdownOpen, action: toggleDownloadDropdown},
                            {label: <Svg use='edit'/>,  tip: 'Edit', action: edit, show: authorization.patch},
                            {label: <Svg use="add" />, tip: 'New Policy', link: '/policies/new', show: authorization.post}
                        ]}
                    />
                }                   
                <section>
                    <List
                        className="meta"
                        editing={editing}
                        onChange={onFieldChange}
                        items={[
                            {inputType: 'custom', label: 'Revision', value: versionNum},
                            {inputType: 'custom', label: 'Date Created', value: moment(dateCreated).utcOffset('-05:00').format('MM/DD/Y')},
                            {inputType: 'custom', label: 'Date Revised', value: dateRevised ? moment(dateRevised).utcOffset('-05:00').format('MM/DD/Y') : 'No Revisions'},
                            {
                                inputType: 'select',
                                label: 'Stage',
                                value: editing ? stageId : stageName,
                                options: stageOptions.map((option) => ({value: option.id, label: option.title}))
                            }
                        ]}
                    />                        
                </section>
                <section>
                    <div className="card">
                        <div className="grid-1">
                            {editing &&
                                <div>           
                                    <input className="center" placeholder="Policy Name" name="title" type="text" value={title} onChange={onFieldChange}/>
                                </div>
                            }
                            {(text || _new) && 
                                <CKEditor
                                    data={text}
                                    readOnly={!editing}
                                    onChange={({editor}) => onEditorChange(editor)}
                                    onBeforeLoad={CKEDITOR => {
                                        CKEDITOR.disableAutoInline = true;

                                        return CKEDITOR
                                    }}
                                    onPaste={onEditorPaste}
                                    config={{
                                        width: '100%',
                                        autoGrow_minHeight: 320,
                                        autoGrow_maxHeight: window.innerHeight < 700 ? 400 : window.innerHeight - 500,
                                        toolbarCanCollapse: true,
                                        toolbarStartupExpanded: editing,
                                        pasteFromWordPromptCleanup: false,
                                        linkShowAdvancedTab: false,
                                        //allowedContent: '*{*}; span',
                                        allowedContent: true,
                                        extraPlugins: 'specialchar,openlink',
                                        openlink_enableReadOnly: 'true',
                                        toolbarGroups: [
                                            { name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
                                            { name: 'forms', groups: [ 'forms' ] },
                                            { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
                                            { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
                                            { name: 'links', groups: [ 'links' ] },
                                            { name: 'insert', groups: [ 'insert' ] },
                                            { name: 'styles', groups: [ 'styles' ] },
                                            { name: 'colors', groups: [ 'colors' ] },
                                            { name: 'tools', groups: [ 'tools' ] },
                                            { name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
                                            { name: 'others', groups: [ 'others' ] },
                                            { name: 'about', groups: [ 'about' ] },
                                            { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
                                        ],
                                        removeButtons: 'Source,Save,NewPage,Preview,Print,Templates,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,CreateDiv,Anchor,Image,Flash,Smiley,PageBreak,Iframe,Font,Maximize,ShowBlocks,About,Styles,Blockquote,Find,Replace,CopyFormatting,BidiLtr,BidiRtl,Language'
                                    }}
                                />
                            }
                            {signOffReq && !editing && !revId &&
                                <div>
                                    {signOffDate ?
                                        <button className="button in-active" style={{width: '100%'}}><Svg use="check-mark"/> Signed off On {moment(signOffDate).utcOffset('-05:00').format('MM/DD/Y')}</button>
                                    :
                                        <button className="button" style={{width: '100%'}} onClick={signOff}>I Have Read &amp; Understand This Policy (Sign Off)</button>
                                    }
                                </div>
                            }
                        </div>                  
                    </div>
                </section>
                {!newRev && authorization.patch &&
                    <section className="grid-2 cards">
                        {!revId && id && !newRev &&
                            <EvidenceTable
                                data={linkedEvidence}
                                onUploadComplete={onEvidenceUploadComplete}
                                onRequestDownload={downloadEvidence}
                                onRequestDelete={deleteEvidence}
                                isEditing={true}
                                parentId={id}
                                typeId={1}
                            />
                        }
                        {!revId && id && !newRev &&
                            <div>
                                <h2 className="header-alt-1 clear-fix"><span style={{float: 'left'}}>Revisions</span><button onClick={() => history.push(`/policies/${this.props.id}/new`)} className="no-style corner"><Svg use="new-doc"/></button></h2>
                                <ReactTable
                                    data={revisions}
                                    columns={[
                                        {Header: 'Version', accessor:'versionNum', width: 64, filterable: true},
                                        {
                                            Header: 'Date Replaced',
                                            accessor:'dateReplaced',
                                            Cell: row => <span>{moment(row.value).utcOffset('-05:00').format('MM/DD/Y')}</span>,
                                            filterable: true
                                        }
                                    ]}
                                    noDataText="No Revisions"
                                    className="small-table"
                                    defaultPageSize={200}
                                    minRows={20}
                                    showPageJump={false}
                                    showPageSizeOptions={false}
                                    showPaginationBottom={false}
                                    defaultFilterMethod={defaultTableFilter}                    
                                    getTdProps={(state, rowInfo, column, instance) => ({
                                        style: {cursor: 'pointer'},
                                        onClick: e => rowInfo && history.push(`/policies/${id}/${rowInfo.row.versionNum}`)

                                    })}                            
                                />
                            </div>    
                        }
                        {!revId &&
                            <div>
                                <h2 className="header-alt-1 clear-fix"><span style={{float: 'left'}}>Linked Job Roles</span><button onClick={() => history.push(`/settings/job-roles`)} className="no-style corner"><Svg use="gear"/></button></h2>
                                <ReactTable
                                    data={mappedJobDescriptions}
                                    columns={[
                                        {Header: 'id', accessor:'id', show: false},
                                        {Header: 'isLinked', accessor:'isLinked', show: false},
                                        {
                                            Header: 'Linked',
                                            accessor:'linkedIcon',
                                            width: 64,
                                            className: 'action',
                                            resizable: false,
                                            sortMethod: selectColtSort
                                        },
                                        {Header: 'Role', accessor:'name', filterable: true},
                                    ]}
                                    className="small-table"
                                    defaultPageSize={200}
                                    minRows={20}
                                    showPageJump={false}
                                    showPageSizeOptions={false}
                                    showPaginationBottom={false}
                                    defaultFilterMethod={defaultTableFilter}                          
                                    getTdProps={(state, rowInfo, column, instance) => ({
                                        style: {cursor: 'pointer'},
                                        className: rowInfo && rowInfo.row.isLinked ? 'active' : 'in-active',
                                        onClick: e => rowInfo && (column.Header === 'Linked' ? updatelinkedJobDescriptionIds(rowInfo.row.id) : console.log(rowInfo.row.id))
                                    })}                            
                                />                         
                            </div>  
                        }
                        {!revId &&
                            <div>
                                <h2 className="header-alt-1 clear-fix"><span style={{float: 'left'}}>Citations Addressed</span></h2>                     
                                <ReactTable
                                    data={mappedCitations}
                                    columns={[
                                        {Header: 'id', accessor:'id', show: false},
                                        {Header: 'isLinked', accessor:'isLinked', show: false},
                                        {
                                            Header: 'Linked',
                                            accessor:'linkedIcon',
                                            width: 64,
                                            className: 'action', 
                                            resizable: false,
                                            sortMethod: selectColtSort
                                        },
                                        {Header: 'Name', accessor:'name', filterable: true},
                                        {Header: 'Description', accessor:'desc', filterable: true}
                                    ]}
                                    className="small-table"
                                    defaultPageSize={200}
                                    minRows={20}
                                    showPageJump={false}
                                    showPageSizeOptions={false}
                                    showPaginationBottom={false}
                                    defaultFilterMethod={defaultTableFilter}                          
                                    getTdProps={(state, rowInfo, column, instance) => ({
                                        style: {cursor: 'pointer'},
                                        className: rowInfo && rowInfo.row.isLinked ? 'active' : 'in-active',
                                        onClick: e => rowInfo && (column.Header === 'Linked' ? updatelinkedCitationIds(rowInfo.row.id) : openModal('citation', rowInfo.row.id))
                                    })}                            
                                />
                            </div>
                        }
                        <div>
                        <h2 className="header-alt-1 clear-fix"><span style={{float: 'left'}}>User Sign Offs</span><button onClick={downloadSignOffs} className="no-style corner"><Svg use="download"/></button></h2>
                            <ReactTable
                                data={userSignOffs}
                                columns={[
                                    {Header: 'userId', accessor:'userId', show: false},
                                    {Header: 'User', accessor:'userName'},
                                ]}
                                noDataText="No Sign Offs"
                                className="small-table"
                                defaultPageSize={200}
                                minRows={20}
                                showPageJump={false}
                                showPageSizeOptions={false}
                                showPaginationBottom={false}
                                defaultFilterMethod={defaultTableFilter}
                                getTdProps={(state, rowInfo, column, instance) => ({
                                    style: {cursor: 'pointer'},
                                    onClick: e => rowInfo && history.push(`/settings/users/${rowInfo.row.userId}`)
                                })}                            
                            />
                        </div>                                                 
                    </section>
                } 
                <Modal
                    isOpen={modalIsOpen}
                    //onAfterOpen={this.afterOpenModal}
                    onRequestClose={closeModal}
                    //style={}
                    className="modal-content"
                    closeTimeoutMS={300}
                    overlayClassName="modal-"
                    bodyOpenClassName="modal-active"
                    ariaHideApp={false}
                    contentLabel="Modal"
                >
                    {modalContent.closeButton &&
                        <button className="button close" onClick={closeModal}>
                            <Svg use="close"/>
                        </button>
                    }
                    {modalContent.header &&                    
                        <h3>{modalContent.header}</h3>
                    }
                    {modalContent.html ? <span dangerouslySetInnerHTML={{__html: modalContent.body}}></span> : <span>{modalContent.body}</span>}
                </Modal>
                <Prompt when={editing && !isSaved} message={location => `You have changes that arn't saved. Click "Okay" to discard these changes, or "Cancel" to stay on this page`}/>                               
                
            </Loader>
        );
    }
}

export default withCurrentUser(withRouter(Policy));
import React, {Fragment} from "react";
import {ForgotPassword} from "aws-amplify-react";
import queryString from 'query-string';
import Svg from '../helpers/svg';
import KassoufLogo from '../kassouf-logo.png';

class FpForgotPassword extends ForgotPassword {
  constructor(props) {
    super(props);
    this._validAuthStates = ["forgotPassword"];
  }

  showComponent(theme) {
    const query = queryString.parse(window.location.search);
    const isCodeView = () => {      
        return query.enterResetCode == "true" && query.username ? true : false;
    };
    const submit = (event) => {
        const newurl = window.location.protocol + "//" + window.location.host;
        console.log(this.state.delivery);
        event.preventDefault();
        this.state.delivery || isCodeView() ? super.submit() : super.send();
        if (isCodeView()) window.history.pushState({path:newurl}, '', newurl);
    };

    console.log(isCodeView(), this.props, this.state);
    return (
      <div style={{maxWidth: 640, margin: '10% auto 0 auto'}}>
        {this.state.delivery || isCodeView() ?
            <Fragment>      
                <div className="card">
                    <img  className="auth-logo" src={KassoufLogo} alt="Complince Compliance Plus"/>
                    {/*<Svg style={{display: 'block', width: '80%', margin: '0 auto 12px auto'}} use="logo"/>*/}
                    <form onSubmit={submit}>
                        <div className="grid-1">
                            <div>
                                <input
                                    id="code"
                                    key="code"
                                    name="code"
                                    onChange={this.handleInputChange}
                                    type="text"
                                    placeholder="Code"
                                />
                            </div>
                            <div>
                                <input
                                    id="password"
                                    key="password"
                                    name="password"
                                    onChange={this.handleInputChange}
                                    type="password"
                                    placeholder="Password"
                                />
                            </div>                            
                        </div>  
                        <p style={{marginTop: 12}} className="center">
                        <a
                            onClick={() => super.send()}
                        > Resend Code
                        </a>
                        </p>
                        <input className="hide" type="submit"/>
                    </form>
                </div>
                <div className="grid-1">
                    <button
                        className="button"
                        type="button"
                        onClick={submit}
                    >
                        Set Password
                    </button>          
                </div>
            </Fragment>
        :    
            <Fragment>      
                <div className="card">
                    <Svg style={{display: 'block', width: '80%', margin: '0 auto 12px auto'}} use="logo"/>
                    <form onSubmit={submit}>
                        <div className="grid-1">
                            <div>
                                <input
                                id="username"
                                key="username"
                                name="username"
                                onChange={(e) => {
                                    e.target.value = e.target.value.toLowerCase();
                                    this.handleInputChange(e);
                                }}
                                type="text"
                                placeholder="Email"
                                />
                            </div>
                        </div>  
                        <p style={{marginTop: 12}} className="center">
                        <a
                            onClick={() => super.changeState("signIn")}
                        > Back to Sign In
                        </a>
                        </p>
                        <input className="hide" type="submit"/>
                    </form>
                </div>
                <div className="grid-1">
                    <button
                        className="button"
                        type="button"
                        onClick={() => super.send()}
                    >
                        Send Reset Code
                    </button>          
                </div>
            </Fragment>
        }        
      </div>
    );
  }
};

export default FpForgotPassword;
import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import withCurrentUser from '../helpers/withCurrentUser';
import Svg from '../helpers/svg';
import PageHeader from '../helpers/pageHeader';
import fpRest from '../helpers/fpRest';
import List from '../helpers/list';
import {defaultTableFilter, DorpDownFilter} from '../helpers/tableHelpers';
import ReactTable from 'react-table';
import Loader from 'react-loader-advanced';
 import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';


import 'react-table/react-table.css';

class Citations extends Component {
    constructor(props) {
        super(props)
        const initSortOrder = sessionStorage.getItem(`citations:sortOrder`);

        this.state = {
            showLoader: true,
            initSortOrder: initSortOrder ? JSON.parse(initSortOrder) : {id: 'linkedPolicies', desc: true},
            citations: [],
            rulesOptions: [],
            sectionOptions: [],
            numOfCitationsAddressed: 0 
        };
    };

    getData = () => {
        const apiCalls = [fpRest.get('citations'), fpRest.get(`policies`)];

        if (this.state.showLoader === false) this.setState({showLoader: true});

        Promise.all(apiCalls)
        .then(res => {
            console.log(res);
            const policies = res[1].data;
            const citations = res[0].data.map(citation => {
                citation.linkedPolicies = policies.filter(policy => policy.linkedCitationIds.indexOf(citation.id) > -1);
                citation.linkedPolicies = citation.linkedPolicies.length ? citation.linkedPolicies : null;
                return citation;
            });
            const numOfCitationsAddressed = citations.filter(({linkedPolicies}) => linkedPolicies).length;
            const rulesOptions = res[0].data.map(({ruleName}) => ruleName).filter((option, index, self) => self.indexOf(option) === index).map(option => ({label: option, value: option}));
            const sectionOptions = res[0].data.map(({sectionName}) => sectionName).filter((option, index, self) => self.indexOf(option) === index).map(option => ({label: option, value: option}));

            this.setState({citations, rulesOptions, sectionOptions, numOfCitationsAddressed, showLoader: false});
        })
        .catch(err => console.log(err));           
    };

    componentDidMount() {
        this.getData();      
    };

    render() {
        const {history} = this.props;
        const {showLoader, initSortOrder, citations, rulesOptions, sectionOptions, numOfCitationsAddressed} = this.state;

        return (
            <Loader show={showLoader} message={<Svg use="loader"/>} disableDefaultStyles={true}>
                <PageHeader title="Rulesets & Policy Gaps" />
                <section>
                    <List
                        className="meta"
                        inlineLabels={true}
                        items ={[
                            {label: <span><span className={numOfCitationsAddressed < citations.length ? 'brand-red' : ''}>{numOfCitationsAddressed}</span> Of {citations.length} Citations Are Addressed by Policies</span>},
                            //{label: 'Citations Not Addressed by Policies', value: ''}
                        ]}
                    />
                </section>               
                <section>
                    <div className="card">
                        <ReactTable
                            data={citations}
                            defaultSorted={[initSortOrder]}
                            columns={
                                [
                                    {Header: 'id', accessor: 'id', show: false},
                                    {
                                        Header: 'Policies',
                                        accessor: 'linkedPolicies',
                                        Cell: ({value}) => <div>{value && value.length ? <span><Svg use="check-mark" style={{fill: 'green'}}/> - {value.length}</span> : <span><Svg use="close"/> - 0</span>}</div>,
                                        width: 120,
                                        Filter: ({onChange}) => <DorpDownFilter options={[{label: 'Assigned', value:'['}, {label: 'Unassigned', value:'null'}]} action={onChange.bind(this)}/> 
                                    },                                    
                                    {
                                        Header: 'Rule',
                                        accessor: 'ruleName',
                                        Filter: ({onChange}) => <DorpDownFilter options={rulesOptions} action={onChange.bind(this)}/> 
                                    },
                                    {
                                        Header: 'Section',
                                        accessor: 'sectionName',
                                        Filter: ({onChange}) => <DorpDownFilter options={sectionOptions} action={onChange.bind(this)}/>
                                    },
                                    {
                                        Header: 'Citation',
                                        accessor: 'name',
                                        //width: 240
                                    },
                                    {
                                        Header: 'Citation Description',
                                        accessor: 'desc',
                                    }
                                ]
                            }
                            onSortedChange={sort => sessionStorage.setItem(`citations:sortOrder`, JSON.stringify(sort[0]))}
                            filterable={true}
                            defaultFilterMethod={defaultTableFilter}
                            defaultPageSize={200}
                            getTdProps={(state, rowInfo, column, instance) => ({
                                onClick: e => rowInfo && history.push(`/citations/${rowInfo.row.id}`),
                                //onMouseOver: e => console.log('foo'),
                                //'data-tip': 'foobar',
                                style: {cursor: 'pointer'}
                            })}                         
                        />
                    </div>
                </section>
                <ReactTooltip/>
                
           </Loader>
        );
    };
}

export default withCurrentUser(withRouter(Citations));
import React, {Component} from 'react';
import Modal from 'react-modal';
import packageJson from '../../package.json';
 
class BustCache extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            isOpen: packageJson.version !== this.props.appVersionOnServer ? true : false
        };
    };

    close = () => {
        this.setState({isOpen: false});
    };

    clearCache = () => {

    };

    render() {
        const {isOpen} = this.props;
        const {close, clearCache} = this;

        return (
            <Modal
                isOpen={isOpen}
                className="modal-content"
                closeTimeoutMS={300}
                overlayClassName="modal-"
                bodyOpenClassName="modal-active"
                ariaHideApp={false}
                contentLabel="Modal"
            >
                <h3>There is a newer versin of this app. Would you like to use it? (Recommended)</h3>
                <div>
                    <button className="button" onClick={clearCache}>Yes</button>
                    <button className="button" onClick={close}>No</button>
                </div>
            </Modal>
        );
    };
};

const bustCache  = appVersionOnServer => {
    const clearCache = () => {
        console.log('Clearing cache and hard reloading...', caches)
        if (caches) {
            // Service worker cache should be cleared with caches.delete()
            caches.keys().then(function(names) {
                for (let name of names) caches.delete(name);
            });
        }

        // delete browser cache and hard reload
        window.location.reload(true);
        console.log('Cache Cleared');
    };

    if (packageJson.version !== appVersionOnServer) {
        //clearCache();
        console.log(`Versions don't match`);
    };
};
 
export default bustCache;
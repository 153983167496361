import React, {Component} from 'react';
import ReactTable from 'react-table';
import {defaultTableFilter, selectColtSort} from '../helpers/tableHelpers';
import Svg from '../helpers/svg';

const mapLinkedData = (linkedData, data) => {
    return data.map(dataItem => {    
        const linkedDataArray = linkedData.filter(id => id === dataItem.value);

        if (linkedDataArray.length) {
            return {
                ...dataItem,
                isLinked: true,
                linkedIcon: <Svg use="toggle-on" />
            };
        }

        return {
            ...dataItem,
            linkedIcon: <Svg use="toggle-off" />
        };
    });        
};

const updateLinkedData = (linkedData, data, id) => {
    if (linkedData.filter(linkedDataItemId => linkedDataItemId === id).length) {
        return linkedData.filter(linkedDataItemId => linkedDataItemId !== id);
    }
    else {
        const newDataItem = data.filter(dataItem => dataItem.value === id)[0];
        return [...linkedData, newDataItem.value]; 
    }         
};
class LinkedDataTable extends Component { 
    constructor (props) {
        //props = onChange, dataSet, linkedDataIds, title
        super(props);

        this.state = {
            firstMapping: true,
            mappedData: []
        }
    };

    onChange = id => {
        const {onChange, linkedDataIds} = this.props;
        const dataSet = this.state.mappedData.map(({label, value}) => ({label, value}));
        const newDataIds = updateLinkedData(linkedDataIds, dataSet, id);
        const mappedData = mapLinkedData(newDataIds, dataSet);
        
        onChange(newDataIds);
        this.setState({mappedData});
    };

    componentDidUpdate() {
        const {dataSet, linkedDataIds} = this.props;

        if (dataSet.length && this.state.firstMapping) {
            const mappedData = mapLinkedData(linkedDataIds, dataSet).sort(selectColtSort); 

            this.setState({mappedData, firstMapping: false});
        }
    };

    render() {  
        const {mappedData} = this.state;
        const {title} = this.props;
        const {onChange} = this;
        
        return (
            <div>
                <h2 className="header-alt-1 clear-fix"><span style={{float: 'left'}}>{title}</span></h2>                     
                <ReactTable
                    data={mappedData}
                    columns={[
                        {Header: 'value', accessor:'value', show: false},
                        {Header: 'isLinked', accessor:'isLinked', show: false},
                        {
                            Header: 'Linked',
                            accessor:'linkedIcon',
                            width: 64,
                            className: 'action', 
                            resizable: false,
                            sortMethod: selectColtSort
                        },
                        {Header: 'Name', accessor:'label', filterable: true},
                    ]}
                    className="small-table"
                    defaultPageSize={200}
                    minRows={20}
                    showPageJump={false}
                    showPageSizeOptions={false}
                    showPaginationBottom={false}
                    defaultFilterMethod={defaultTableFilter}                          
                    getTdProps={(state, rowInfo, column, instance) => ({
                        style: {cursor: 'pointer'},
                        className: rowInfo && rowInfo.row.isLinked ? 'active' : 'in-active',
                        onClick: e => rowInfo && onChange(rowInfo.row.value)
                    })}                            
                />
            </div>        
        );
    };
};

export default LinkedDataTable;
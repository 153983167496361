import React from 'react';
import {CurrentUserContext} from '../index';

export default function withCurrentUser(Component) {

    return function UserComponent(props) {
        return (
            <CurrentUserContext.Consumer>
                {currentUser => <Component {...props} currentUser={currentUser}/>}
            </CurrentUserContext.Consumer>
        );
    };
}
import React from "react";
import { SignIn } from "aws-amplify-react";
import queryString from 'query-string';
import Svg from '../helpers/svg';
import Loader from 'react-loader-advanced';
import KassoufLogo from '../kassouf-logo.png';

class FpSignIn extends SignIn {
  constructor(props) {
    super(props);
    this._validAuthStates = ["signIn", "signedOut", "signedUp"];
  }  

  checkQueryString(changeState) {
    const parsed = queryString.parse(window.location.search);
    if (parsed.enterResetCode == "true") {
      changeState("forgotPassword");
    }
  };

  showComponent() {
    const submit = (event) => {
      event.preventDefault();
      super.signIn();
    };
    const loading = this.state.loading ? true : false;

    this.checkQueryString(super.changeState.bind(this));
    
    return (
      <div className="auth">
        <div className="card">
          <Loader show={loading} message={<Svg use="loader"/>} disableDefaultStyles={true}>
            <img  className="auth-logo" src={KassoufLogo} alt="Complince Compliance Plus"/>
            {/*<Svg style={{display: 'block', width: '80%', margin: '0 auto 12px auto'}} use="logo"/>*/}
            <form onSubmit={submit}>
              <div className="grid-1">
                <div>
                  <input
                    id="username"
                    key="username"
                    name="username"
                    onChange={(e) => {
                      e.target.value = e.target.value.toLowerCase();
                      this.handleInputChange(e);
                    }}
                    type="text"
                    placeholder="Email"
                  />
                </div>
                <div>
                  <input
                    id="password"
                    key="password"
                    name="password"
                    onChange={this.handleInputChange}
                    type="password"
                    placeholder="Password"
                  />
                </div>
              </div>  
              <p style={{marginTop: 12}} className="center">
                Forgot your password?
                <a
                  onClick={() => super.changeState("forgotPassword")}
                > Reset Password
                </a>
              </p>
              <input className="hide" type="submit"/>
            </form>
          </Loader>
        </div>
        <div className="grid-1">
          <button
            className="button"
            type="button"
            onClick={() => super.signIn()}
          >
            {loading ? 'Logging in...' : 'Login'}
          </button>         
        </div>
      </div>
    );
  }
};

export default FpSignIn;
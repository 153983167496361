export const truncate = (string, num) => {
    return string.length > num ? string.slice(0, num).trim() + '...' : string;
};

export const timezones = [
    {value: 1, label: 'Anchorage (Alaska)'},
    {value: 2, label: 'Chicago (Central)'},
    {value: 3, label: 'Denver (Mountain)'},
    {value: 4, label: 'Honolulu (Hawaii)'},
    {value: 5, label: 'Los Angeles (Pacific)'},
    {value: 6, label: 'New York (Eastern)'},
    {value: 7, label: 'Phoenix (MST)'},
    {value: 8, label: 'Puerto Rico (Atlantic)'}
];
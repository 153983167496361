import React from 'react';
import {NavLink} from 'react-router-dom'
import Svg from './svg';

const MainNavButton = ({action, link, label, icon, mainActiveItem}) => {
    let buttonClass = '';

    if (mainActiveItem === label.replace(/[^A-Z0-9]+/ig, "_").toLowerCase()) buttonClass = 'active';

    return (
        <React.Fragment>
            {link ?
                <NavLink to={link} onClick={action} activeClassName="active">
                    {icon &&
                        <Svg use={icon}/>
                    }    
                    <span className="label">{label}</span>                        
                </NavLink>   
            :            
                <button onClick={action} className={buttonClass}>
                    {icon &&
                        <Svg use={icon}/>
                    }
                    <span className="label">{label}</span>
                </button>  
            }
        </React.Fragment>
    );
}

export default MainNavButton;
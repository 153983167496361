import React, { Component } from 'react';
import Select from 'react-select';

export class DorpDownFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: ''     
        }
    };

    onChange = value => {
        const action = this.props.action;

        this.setState({selected: value}, ()=> {
            action(value);
        });
    };

    componentDidMount() {
        const {defaultValue} = this.props;

        if (defaultValue) {
            this.setState({selected: defaultValue});
            this.onChange(defaultValue);
        }
    };

    render() {
        const {selected} = this.state;
        const options = [{value: '', label: 'All'}, ...this.props.options];
        const onChange = this.onChange.bind(this);

        return (
            <Select
                clearable={false}
                classNamePrefix="select"                                        
                value={options.filter(option => selected === option.value)[0]}
                onChange={({value}) => onChange(value)}
                options={options}
            />
        );
    };      
};

export const defaultTableFilter = (filter, row, column) => {
    const id = filter.pivotId || filter.id;

    if (typeof filter.value === 'boolean'){
        return row[id] !== undefined ? row[id] === filter.value ? true : false : true;
    }
    else {
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
    }
};

export const selectColtSort = (a, b, desc) => {
    if (a.props) {
        a = a.props.use === 'toggle-on' ? 1 : 2;
        b = b.props.use === 'toggle-on' ? 1 : 2;
    }
    else {
        a = a.linkedIcon.props.use === 'toggle-on' ? 1 : 2;
        b = b.linkedIcon.props.use === 'toggle-on' ? 1 : 2;
    }
    // force null and undefined to the bottom
    a = (a === null || a === undefined) ? -Infinity : a
    b = (b === null || b === undefined) ? -Infinity : b
    // force any string values to lowercase
    a = typeof a === 'string' ? a.toLowerCase() : a
    b = typeof b === 'string' ? b.toLowerCase() : b
    // Return either 1 or -1 to indicate a sort priority
    if (a > b) {
      return 1
    }
    if (a < b) {
      return -1
    }
    // returning 0 or undefined will use any subsequent column sorting methods or the row index as a tiebreaker
    return 0
};

/*
const ClearStyle = ({editorState, onChange}) => {
    const clear = () => {
        console.log(editorState.getCurrentInlineStyle());
        const styles = [
            'BOLD',
            'ITALIC',
            'UNDERLINE',
            'STRIKETHROUGH',
            'CODE',
        ];

        const contentState = styles.reduce((newContentState, style) => (
            Modifier.removeInlineStyle(
              newContentState,
              editorState.getSelection(),
              style
            )
          ), editorState.getCurrentContent());
          onChange(EditorState.push(editorState, contentState, 'change-inline-style'))        
    }
    
    return (
        <div onClick={clear}>Clear Style</div>
    )
}
*/
import React from 'react';
import Svg from '../helpers/svg';

const Toggle = ({on, noOff, onClick, label}) => {
    return (
        <React.Fragment>
            {label &&
                <span style={{marginRight: '8px'}}>{label}</span>
            }
            <button className="no-style" onClick={onClick}>
                {on ?
                    <Svg use="toggle-on" style={{fill: 'green'}} />
                    :
                    <Svg use="toggle-off" style={{fill: noOff ? 'green' : '#b83c34'}} />
                }    
            </button>
        </React.Fragment>
    );
}

export default Toggle;
import React, { Component } from 'react';
import fpRest from '../helpers/fpRest';
import mime from 'mime-types';
import axios from 'axios';
import Modal from 'react-modal';
import Svg from '../helpers/svg';
import moment from 'moment';
 import { toast } from 'react-toastify';

export default class FileUploader extends Component {
    constructor(props) {
        super(props);

        this.state = this.defaultState;
    };

    getFileAsBase64 = file =>  new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = event => resolve(event.target.result.split(',')[1]);
        reader.onerror = event => reject("File could not be read! Code " + event.target.error.code);
        reader.readAsDataURL(file);
    });

    getFileExt = fileName => fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2);
    
    removeFileExt = fileName => fileName.slice(0, fileName.length - this.getFileExt(fileName).length - 1);

    validateFile = file => {
        const maxFileSize = 150;
        //const excludedTypes = ['application']; 
        const {size} = file;
        if (size <= maxFileSize * 1000000 /*&& !excludedTypes.includes(type.split("/")[0])*/) {
            return true;
        }
        else {
            return false;
        }
    }

    defaultState = {
        isModalOpen: false,
        file: null,
        fileName: '',
        fileExt: '',
        fileNameIsValid: null,
        fileDesc: '',
        fileDescIsValid: null,
        fileIsValid: true,
        progress: 0,
        isOpen: false,
        isUploading: false     
    };

    addBodyEvent = () => {
        document.body.onfocus = () => {
            this.close();
            document.body.onfocus = null;
        };
    };    

    isFormValid = () => {
        const {fileNameIsValid, fileDescIsValid} = this.state;
        const values = [fileNameIsValid, fileDescIsValid];

        return values.includes(false) ? false : true;
    }    

    open = async () => {
        const file = this.fileButtonRef.files[0];
        const fileName = this.removeFileExt(file.name);
        const fileExt = this.getFileExt(file.name);

        if (file && this.validateFile(file)) {
            //const fileAsBase64 = await this.getFileAsBase64(file);
            this.setState({isModalOpen: true, file, fileName, fileExt, fileNameIsValid: true, fileIsValid: true});       
        }
        else {
            this.setState({isModalOpen: true, fileIsValid: false, isOpen: true});
        } 
    };

    close = () => {
        this.props.onRequestClose();
        this.fileButtonRef.value = '';
        this.setState({...this.defaultState});        
    };
    
    upload = async e => {
        const {file, fileName, fileExt, fileDesc} = this.state;
        const {parentId, typeId, onUploadComplete} = this.props;
        const info = {name: fileName + '.' + fileExt, description: fileDesc, parentId: parentId ? parentId : null, typeId, dateUploaded: moment().format()};
        const formData = new FormData();

        let isUploading = true;

        e.preventDefault();
        try {
            if (this.isFormValid()) {
                this.setState({isUploading});
                const onUploadProgress = event => {
                    const {loaded, total} = event;
                    const progress = Math.round((loaded / total) * 100)

                    if (progress === 100) {
                        setTimeout(() => {
                            isUploading = false;
                            this.close();
                        }, 1000);
                    }

                    this.setState({progress, isUploading});
                };
                const {data} = await fpRest.post('evidence', info);
                const axiosOptions = {
                    method: 'put',
                    url: data.tempUploadUrl,
                    'Content-Type': mime.lookup(fileExt),
                    onUploadProgress,
                    data: file
                };
                const s3Upload = await axios(axiosOptions);
                console.log(s3Upload);

                onUploadComplete({...info, id: data.id});
                toast.success('Uploaded Successful');
            }
        }
        catch(err) {
            toast.error('Oh no! It looks like the uploaded failed');
        };
    };

    updateField = e => {
        const name = e.target.name;
        const value = e.target.value;
        const isValid = value !== '' ? true : false;

        this.setState({[name]: value, [name + 'IsValid']: isValid});
    };

    triggerfileButton = () => {
        this.fileButtonRef.click();
    };    

    componentDidUpdate() {
        const {open} = this.props;
        const {isModalOpen} = this.state;
        const triggerfileButton = this.triggerfileButton;
        const addBodyEvent = this.addBodyEvent;

        if (open && !isModalOpen) {
            triggerfileButton();
            addBodyEvent();
        }
    };

    render() {
        const {isModalOpen, fileName, fileNameIsValid, fileDesc, fileDescIsValid, progress, isUploading, fileIsValid} = this.state;
        const {title} = this.props;
        const isFormValid = this.isFormValid([fileNameIsValid, fileDescIsValid]);
        const upload = this.upload.bind(this);
        const open = this.open.bind(this);
        const close = this.close.bind(this);
        const updateField = this.updateField.bind(this);

        let modalClasses = '';
        let uploadButtonValue= 'Upload';
        let formClasses = '';

        if (isUploading) {
            modalClasses = 'uploading';
            uploadButtonValue = `${progress.toString()}%`;
        }

        if (!isFormValid) {
            formClasses = 'invalid'
        }

        return (
            <React.Fragment>
                <input className="hide" name="file" type="file" ref={ref => this.fileButtonRef = ref} onChange={open} />
                {open &&
                    <Modal
                        isOpen={isModalOpen}
                        onRequestClose={close}
                        className={`modal-content upload-modal ${modalClasses}`}
                        closeTimeoutMS={300}
                        overlayClassName="modal-"
                        bodyOpenClassName="modal-active"
                        ariaHideApp={false}
                        contentLabel="Modal"
                    >
                        {fileIsValid ?
                          <React.Fragment>
                                <button className="button close" onClick={close}>
                                    <Svg use="close"/>
                                </button>
                                <h3>{title}</h3>
                                <div>
                                    <form onSubmit={upload} className={formClasses}>
                                        <div className="file-info">
                                            <input className="file-name" type="text" name="fileName" placeholder="File Name" required aria-required="true" value={fileName} onChange={updateField}/>
                                            <input className="file-desc" type="text" name="fileDesc" placeholder="File Description" required aria-required="true" value={fileDesc} onChange={updateField}/>
                                            <div className="progress-bar" style={{width: `${progress}%`}}></div>
                                        </div>
                                        <input type="submit" className="button" value={uploadButtonValue} />
                                    </form>
                                </div>
                            </React.Fragment> 
                        :
                            <React.Fragment>
                                <h3>Sorry, but that's an unsupported file type, and/or the file exceeds 150MB.</h3>
                                <button className="button" onClick={close}>
                                        Okay
                                </button>
                            </React.Fragment>
                        }
                    </Modal>
                }           
            </React.Fragment>                 
        );
    };
};
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import Svg from '../helpers/svg';
import PageHeader from '../helpers/pageHeader';
import fpRest from '../helpers/fpRest';
import CsvImporter from '../helpers/csvImporter';
import settingsHeaderNav from '../helpers/settingsHeaderNav';
import Cards from '../helpers/cards';
import Loader from 'react-loader-advanced';
import {toast} from 'react-toastify';
import 'react-table/react-table.css';

class PolicyStages extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showLoader: true,
            policyStages: [],
            isImporterOpen: false,
            hideInactive: true,
            isImportDropdownOpen: false,
            shouldCreatePolicyStage: false      
        };
    };

    getData = async () => {
        if (this.state.showLoader === false) this.setState({showLoader: true});
        try {
            const res = await fpRest.get('policies/stages');
            const policyStages = res.data;
            console.log(policyStages);
            this.setState({policyStages, showLoader: false});
        }
        catch (err) {
            console.log(err);
        };          
    };

    startImport = () => {
        this.setState({isImporterOpen: true});
    };

    closeImporter = () => {
        this.setState({isImporterOpen: false});
    };

    toggleImportDropdown = () => {
        this.setState({isImportDropdownOpen:!this.state.isImportDropdownOpen});
    };    

    toggleInactive = () => {
        this.setState({hideInactive: !this.state.hideInactive});
    };

    createPolicyStage = () => {
        this.setState({shouldCreatePolicyStage: true}, () => {
            this.setState({shouldCreatePolicyStage: false});
        });
    }

    saveCard = (all, item, stage, dest, action) => {
        this.setState({policyStages: all[0].policyStages, showLoader: true});
        console.log(stage, action);
        if (action === 'create') {
            fpRest.post(`policies/stages`, stage)
            .then(res => {
                this.getData();
            })
            .catch(err => {
                console.log(err);
                this.getData();
            });  
        }
        else if (action === 'update') {
            fpRest.patch(`policies/stages/${stage.id}`, stage)
            .then(res => {
                this.getData();
            })
            .catch(err => {
                console.log(err);
                this.getData();
            });  
        }
    };

    componentDidMount() {
        this.getData();        
    }

    render() {
        const {toggleImportDropdown, startImport, closeImporter, toggleInactive, saveCard, createPolicyStage} = this;
        const {showLoader, policyStages, hideInactive, isImporterOpen, isImportDropdownOpen, shouldCreatePolicyStage} = this.state;

        return (
            <Loader show={showLoader} message={<Svg use="loader"/>} disableDefaultStyles={true}>
                <PageHeader
                    navItems={settingsHeaderNav('policyStages')}
                    actionItems={[
                        //{label: <Svg use="add" />, tip: 'New Policy Stage', action: createPolicyStage},
                    ]} 
                />
                <Cards
                    items={[{name: 'Policy Stages', policyStages}]}
                    mappings={{
                        header: {
                            accessor: 'name',
                            tag:'h2'
                        },
                        subItems: {
                            accessor: 'policyStages',
                            newItemText: 'New Policy Stage',
                            header: {
                                accessor: 'title',
                                tag: 'h3'
                            }
                        }
                    }}
                    areItemsEditable = {false}
                    createNewItem = {shouldCreatePolicyStage}
                    onSave={saveCard}
                />
                
            </Loader>
        );
    };
}

export default withRouter(PolicyStages);
import 'core-js';
import registerServiceWorker from './registerServiceWorker';
import fpRest from './helpers/fpRest';
import ReactDOM from 'react-dom';
import React, {useState, useEffect, Component, Fragment } from 'react';
import {ToastContainer, toast} from 'react-toastify';
import {BrowserRouter as Router} from 'react-router-dom';
import {Auth} from 'aws-amplify';
import withAuth from './auth/withAuth';
import IdleTimer from 'react-idle-timer'
import SecondarySideBarNav from './helpers/secondarySideBarNav';
import MainNavButton from './helpers/mainNavButton';
import Svg from './helpers/svg';
import CurrentUserMenu from './helpers/currentUserMenu';
import './index.css';
import 'react-toastify/dist/ReactToastify.min.css';
import KassoufLogo from './kassouf-logo.png';
import KassoufLogoMark from './kassouf-logo-mark.png';
import Routes from './helpers/routes';
import bustCache from './helpers/bustCache';

export const CurrentUserContext = React.createContext({});
export const UiContext = React.createContext({});

toast.configure();

const scrollMainNavUp = () => {
	const nav = document.getElementById('main-nav');
	const sideBar = document.getElementById('main-sidebar');

	nav.scrollTop = nav.scrollTop - (sideBar.offsetHeight + 300);
};

const scrollMainNavDown = () => {
	const nav = document.getElementById('main-nav');
	const sideBar = document.getElementById('main-sidebar');

	nav.scrollTop = nav.scrollTop + (sideBar.offsetHeight - 300);
};

function Flightplan (props) {
	const [idle, setIdle] = useState(false);
	const [appVersionOnServer, setAppVersionOnServer] = useState(null);
	const [mainBarSize, setMainBarSize] = useState('wide');
	const [pageLoaded, setPageLoaded] = useState(true);
	const [currentUserMenuIsOpen, setCurrentUserMenuIsOpen] = useState(false);
	const [showNavScrollUp, setShowNavScrollUp] = useState(false);
	const [showNavScrollDown, setShowNavScrollDown] = useState(false);
	const [currentUser, setCurrentUserState] = useState(null);

	const setAppInfo = async () => {
		const {data} = await fpRest.get('app-info');
		bustCache(data.webAppVersion);
		//this.setState({appVersionOnServer: data.webAppVersion});
	};

	const setCurrentUser = async (payload, shouldRefresh) => {
		if (payload) {
			payload.jobRoleIds = JSON.parse(payload.jobRoleIds);
			payload.id = parseInt(payload.id, 10);
			payload.roleId = parseInt(payload.roleId, 10);
			payload.authorizedEndpoints = JSON.parse(payload.authorizedEndpoints);
			payload.isAdmin = payload.authorizedEndpoints.filter(({ func }) => func === '*').length > 0 ? true : false;
			setCurrentUserState(payload);
		}

		if (shouldRefresh !== false) {
			setTimeout(async () => {
				try {
					const cognitoUser = await Auth.currentAuthenticatedUser();
					const currentSession = await Auth.currentSession();
					cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
						if (session) {
							setCurrentUser(session.idToken.payload, idle ? false: true);
						}
						else {
							signOut();	
						}
					});
				}
				catch (err) {
					console.log('Unable to refresh Token', err);
				}
			}, 1000 * 60 * 5);
		}
	};

	const signOut = async () => {
		try {
			await Auth.signOut();
			localStorage.clear();
			window.location.assign(window.location.origin);
		}
		catch (err) {
			console.log(err);
		}
	};

	const toggleViewSize = () => {
		if (mainBarSize === `slim`) {
			setMainBarSize(`wide`);
		}
		else {
			setMainBarSize(`slim`);
		}
	};

	const onActive = () => {
		setIdle(false);
		setCurrentUser();
	};

	const onIdle = () => {
		setIdle(true);
	};
	
	const onMainNavScroll = e => {
		const nav = e.target;

		if (nav.scrollTop === 0) {
			setShowNavScrollUp(false);
		}
		else {
			setShowNavScrollUp(true);	
		}		

		if (nav.scrollHeight - nav.scrollTop === nav.clientHeight) {
			setShowNavScrollDown(false);
		}
		else {
			setShowNavScrollDown(true);
		}
	};

	const toggleUserMenu = () => {
		setCurrentUserMenuIsOpen(!currentUserMenuIsOpen);
	};

	const handleMenuState = () => {
		if (window.innerWidth <= 1200) {
			setMainBarSize(`slim`);
		}
	};

	const handleMainNavScrollState = () => {
		setShowNavScrollDown(window.innerHeight > 940 ? false : true);
	};

	const onWindowResize = () => {
		handleMenuState();
		handleMainNavScrollState();
	};

	const pageSize = mainBarSize === `slim` ? 'wide' : '';
	const pageActive = pageLoaded ? 'active' : '';	

	useEffect(function persistForm() {
		Auth.currentSession()
		.then(session => {
			console.log(session);
			setCurrentUser(session.idToken.payload);
		});

		onWindowResize();
		window.addEventListener('resize', onWindowResize);
		setAppInfo();		
	}, []);
	
	return (
		<div>	
			{currentUser &&
				<UiContext.Provider value={{ pageSize }}>
					<CurrentUserContext.Provider value={currentUser}>
						<Router>
							<Fragment>
								<IdleTimer
									onActive={onActive}
									onIdle={onIdle}
									debounce={250}
									timeout={1000 * 60 * 10}
								/>

								{currentUser.isAdmin ?
									<div>
										<div id="main-sidebar" className={`main-sidebar ${mainBarSize} ${pageActive}`}>
											<section className="logo-wrap">
												<img className="logo" src={KassoufLogo} alt="Logo" />
												<img className="logo-mark" src={KassoufLogoMark} alt="Logo Mark" />
											</section>
											<section className="current-user">
												<button className="no-style" onClick={toggleUserMenu}><Svg use="user" /><span className="user-name">{currentUser && currentUser.firstName}</span></button>
											</section>
											{showNavScrollUp &&
												<div className="scroll-controls up" onClick={scrollMainNavUp}>
													<button className="no-style">
														<Svg use="arrow-up"/>
													</button>
												</div>				
											}								
											<nav id="main-nav" onScroll={onMainNavScroll}>
												<ul>
						{/*
						<li>
						<MainNavButton action={secondarySideBarHome} label={'Home'} icon={'home'} /> 
						</li>
						*/}
													<li>
														<MainNavButton link="/policies" action={handleMenuState} label='Policies &amp; Procedures' icon='assignment' />
													</li>
													<li>
														<MainNavButton link="/risk-assessments" action={handleMenuState} label='Risk Assessments' icon='assessment-warn' />
													</li>														
													<li>
														<MainNavButton link="/business-associates" action={handleMenuState} label='Business Associates' icon='briefcase' />
														{/*<MainNavButton action={secondarySideBarSafeguards} label={'Administrative Safeguards'} icon={'shield'} />*/}
													</li>
													<li>
														<MainNavButton link="/citations" action={handleMenuState} label='Rulesets & Gaps' icon='explore' />
													</li>														
													<li>
														<MainNavButton link="/sanctions" action={handleMenuState} icon='gavel' label='Sanctions' />
													</li>
													<li>
														<MainNavButton link="/assets" action={handleMenuState} icon='devices' label='Assets' />
													</li>
													<li>
														<MainNavButton link="/maintenance-logs" action={handleMenuState} icon='check-board' label='Maintenance Logs' />
													</li>
													<li>
														<MainNavButton link="/security-incidents" action={handleMenuState} icon='warning' label='Security Incidents' />
													</li>
													<li>
														<MainNavButton link="/information-system-activity-reviews" action={handleMenuState} icon='assessment1' label='Information System Activity Reviews' />
													</li>
													<li>
														<MainNavButton link="/activities" action={handleMenuState} icon='check-mark' label='Activities' />
													</li>
													<li>
														<MainNavButton link="/security-awareness" action={handleMenuState} icon='grad-cap' label='Security Awareness' />
													</li>																																																																															
													<li>
														<MainNavButton link="/settings/company" action={handleMenuState} icon='gear' label='Settings' />
													</li>	
													{/*
						<li>
						<MainNavButton action={secondarySideBarConfiguration} label={'Configuration'} icon={'gear'} />
						</li>
						*/}
												</ul>
											</nav>
											{showNavScrollDown &&
												<div className="scroll-controls">
													<button className="no-style" onClick={scrollMainNavDown}>
														<Svg use="arrow-down"/>
													</button>
												</div>
											}
										</div>
										<button onClick={toggleViewSize} className={`main-sidebar-controls ${mainBarSize} ${pageActive}`}>
											<Svg use="backburger" />
										</button>
										<div onClick={toggleViewSize} className={`sidebar-shade ${mainBarSize}`}></div>
										<main className={`view ${pageSize}`}>
				<Routes user={currentUser} />
										</main>
									</div>
									:
									<div>
										<div id="main-sidebar" className={`main-sidebar ${mainBarSize} ${pageActive}`}>
											<section className="logo-wrap">
												<img className="logo" src={KassoufLogo} alt="Logo" />
												<img className="logo-mark" src={KassoufLogoMark} alt="Logo Mark" />
											</section>
											<section className="current-user">
												<button className="no-style" onClick={toggleUserMenu}><Svg use="user" /><span className="user-name">{currentUser && currentUser.firstName}</span></button>
											</section>
											<nav>
												<ul>
													<li>
														<MainNavButton link="/policies" label='My Policies' icon='assignment' />
													</li>
												</ul>
											</nav>
										</div>
										<button onClick={toggleViewSize} className={`main-sidebar-controls ${mainBarSize} ${pageActive}`}>
											<Svg use="backburger" />
										</button>
										<main className={`view ${pageSize}`}>
					<Routes user={currentUser} />
				</main>
									</div>
								}
								<footer className="main-footer">
									<span>FlightPlan v2.0 - &copy; 20202<a target="_blank" rel="noopener noreferrer" href="http://www.skysailsoft.com">SkySail Software</a></span>
								</footer>
								<CurrentUserMenu user={currentUser} isOpen={currentUserMenuIsOpen} onRequestClose={toggleUserMenu} onRequestSignOut={signOut} />
							</Fragment>
						</Router>
					</CurrentUserContext.Provider>
				</UiContext.Provider>
			}
		</div>	
	);
};

const App = withAuth(Flightplan);

ReactDOM.render(<App />, document.getElementById('root'));
registerServiceWorker();
import React, { Component } from 'react';
import Select from 'react-select';
import Modal from 'react-modal';
import ReactTable from 'react-table';
import csvtojson from 'csvtojson';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import Svg from '../helpers/svg';
import Toggle from '../helpers/toggle';

const CellError = ({rowValue}) =>
    <span className="error" data-tip="This value is not allowed. Please see the import template.">
        <span className="error-box"></span>
        <span className="error-text">{rowValue}</span>
        <ReactTooltip/>
    </span>

const checkAllowedCellValues = (rows, mappingOptions) => rows.reduce((acc, row, i) => {
    mappingOptions.forEach(option => {
        if ((row[option.value] && option.allowedCellValues === 'date' && moment(row[option.value]).isValid() === false) || (row[option.value] && option.allowedCellValues && Array.isArray(option.allowedCellValues) && option.allowedCellValues.filter(({label}) => row[option.value] && row[option.value].toLowerCase() === label.toLowerCase()).length === 0) || (option.required && !row[option.value])) {
            console.log(i, option.value);
            acc.push({lineIndex: i, column: option.value});
        }
    });
    return acc;
}, []);

const mapAllowedCellValuesForUpload = (rows, mappingOptions) => rows.map((row, i) => {
    mappingOptions.forEach(option => {
        if (row[option.value] && Array.isArray(option.allowedCellValues) && option.allowedCellValues) {
            const allowedValue = option.allowedCellValues.filter(({label}) => row[option.value].toLowerCase() === label.toLowerCase())[0].value;

            row[option.value] = allowedValue; 
        }
    });
    return row;
}, []);

const mergeHeaders = (imported, importHasHeaders, headers) => {
    headers = headers.map(({value}) => value);

    const merged = importHasHeaders ? imported.map((row, i) => i === 0 ? headers : row) : [headers, ...imported];
    const cleaned = merged.map(row => row.filter((field, i) => headers[i] === '~skip~' ? false : true));

    return cleaned;
};
 
const getRemainingRquiredHeaders = (headers, mappingOptions) => mappingOptions.filter(({value, required}, i) => {        
    if (headers.map(({value}) => value).indexOf(value) > -1 || !required) {
        return false;
    }
    else {
        return true;
    }
});

const getUnsetHeaderIndexes = headers => headers.map((header, i) => ({...header, index: i})).filter(({value}) => value === '').map(({index}) => index);

const rowsToObject = rows => {
    const keys = rows.shift();
    
    return rows.map(row => {
        return keys.reduce((a, c, i) => {
            a[c] = row[i];
            return a;
        }, {});
    });
}

const autoMapHeaders = (imported, mappingOptions) => {
    const headers = imported[0].map(field => {
        let match = {label: '', value: ''};
        field = field.toLowerCase();

        for (let option of mappingOptions) {
            const {label, value} = option;

            if (field.indexOf(value) > -1 || field.indexOf(label.toLowerCase()) > -1 || label.toLowerCase().indexOf(field) > -1) {
                match = option;
                break;
            }
        }

        return match;
    });

    return headers;
}

export default class CsvImporter extends Component {
    constructor(props) {
        super(props);

        this.state = this.defaultState;
    };

    defaultState = {
        wasFileButtonTriggered: false,
        isModalOpen: false,
        imported: [],
        importedMapped: [],
        mappedHeaders: [],
        importHasHeaders: null,
        mergeArray: [],
        cellErrors: [],
        remainingRquiredheaders: [],
        unsetHeaderIndexes: [],
        importSteps: ['current-step', 'next-step']   
    };    

    addBodyEvent = () => {
        document.body.onfocus = () => {
            setTimeout(() => {
                if (!this.state.isModalOpen) this.close();
            }, 1000);
            document.body.onfocus = null;
        };
    };   

    startMapingStep = () => {
        const file = this.fileButtonRef.files[0];
        const reader = new FileReader();
        const openModal = this.openModal;
        const {mappingOptions} = this.props;

        reader.onload = event => {
            const contents = event.target.result;
            
            csvtojson({
                noheader: true,
                output: 'csv'
            })
            .fromString(contents)              
            .then(rows => { 
                const imported = rows;
                const mappedHeaders = autoMapHeaders(imported, mappingOptions);
                const importHasHeaders = mappedHeaders.filter(({value}) => value.length ? true : false).length;
                const remainingRquiredheaders = getRemainingRquiredHeaders(mappedHeaders, mappingOptions);

                this.setState({imported, mappedHeaders, importHasHeaders, remainingRquiredheaders});
                openModal();
            })
        };

        reader.onerror = event => {
            console.error("File could not be read! Code " + event.target.error.code);
        };

        reader.readAsText(file);
    };

    changeMapping = option => {
        const {mappedHeaders} = this.state;
        const headers = mappedHeaders.map((field, i) => i === option.index ? option : field);
        const remainingRquiredheaders = getRemainingRquiredHeaders(headers, this.props.mappingOptions);

        this.setState({mappedHeaders: headers, remainingRquiredheaders});
    };

    startConfirmStep = e => {
        e.preventDefault();

        const {imported, importHasHeaders, mappedHeaders} = this.state;
        const unsetHeaderIndexes = getUnsetHeaderIndexes(mappedHeaders);

        if (unsetHeaderIndexes.length) {
            this.setState({unsetHeaderIndexes});
        }
        else {
            const withHeaders = mergeHeaders(imported, importHasHeaders, mappedHeaders);
            const mergeArray = rowsToObject(withHeaders);
            const cellErrors = checkAllowedCellValues(mergeArray, this.props.mappingOptions);

            this.nextStep(0);
            this.setState({mergeArray, cellErrors});
        }
    }

    openModal = () => {
        this.setState({isModalOpen: true});        
    };

    close = () => {
        this.props.onRequestClose();
        this.fileButtonRef.value = '';
        this.setState({...this.defaultState});        
    };
    
    upload = e => {
        e.preventDefault();

        const res = mapAllowedCellValuesForUpload(this.state.mergeArray, this.props.mappingOptions);

        this.props.onRequestUpload(res);
        setTimeout(() => {
            this.close();
        }, 1000);
    };

    triggerfileButton = () => {
        this.fileButtonRef.click();
        this.setState({wasFileButtonTriggered: true});
    };
   
    toggleHeaderRow = () => {
        const {importHasHeaders} = this.state;

        this.setState({importHasHeaders: !importHasHeaders});
    };

    prevStep = (currentStep, e) => {
        if (e) e.preventDefault();

        const importSteps = this.state.importSteps.map((step, i) => {
            if (i === currentStep) {
                return 'next-step';
            }
            else if (i === currentStep - 1) {
                return 'current-step';
            }
            else {
                return step;
            }
        });

        this.setState({importSteps});
    };

    nextStep = (currentStep, e) => {
        if (e) e.preventDefault();

        const importSteps = this.state.importSteps.map((step, i) => {
            if (i === currentStep) {
                return 'prev-step';
            }
            else if (i === currentStep + 1) {
                return 'current-step';
            }
            else {
                return step;
            }
        });

        this.setState({importSteps});
    };

    componentDidUpdate() {
        const {open} = this.props;
        const {wasFileButtonTriggered} = this.state;
        const triggerfileButton = this.triggerfileButton;
        const addBodyEvent = this.addBodyEvent;

        if (open && !wasFileButtonTriggered) {
            triggerfileButton();
            addBodyEvent();
        }
    };   

    render() {
        const {isModalOpen, imported, mappedHeaders, importHasHeaders, importSteps, mergeArray, cellErrors, remainingRquiredheaders, unsetHeaderIndexes} = this.state;
        const {open, mappingOptions, title} = this.props;
        const {upload, startMapingStep, startConfirmStep, changeMapping, toggleHeaderRow, close, prevStep} = this;

        return (
            <React.Fragment>
                <input className="hide" type="file" accept=".csv" ref={ref => this.fileButtonRef = ref} onChange={startMapingStep} />
                {open &&
                    <Modal
                        isOpen={isModalOpen}
                        onRequestClose={close}
                        className="modal-content header-footer steps"
                        closeTimeoutMS={300}
                        overlayClassName="modal-"
                        bodyOpenClassName="modal-active"
                        ariaHideApp={false}
                        contentLabel="Modal"
                    >
                        <ol>
                            <li className={importSteps[0]}>
                                <header>
                                    <button className="button close" onClick={close}>
                                        <Svg use="close"/>
                                    </button>                            
                                    <h3>{title}</h3>
                                    <Toggle label="Does the CSV have a header row?" on={importHasHeaders} onClick={toggleHeaderRow}/>
                                </header>
                                <form onSubmit={(e)=> {startConfirmStep(e)}} className="inner">
                                    {remainingRquiredheaders.length > 0 &&
                                        <div style={{marginTop: '16px'}} className="brand-red">
                                            Rquired  remaining columns: "{remainingRquiredheaders.map(({label}, i) => i === 0 ? label : `, ${label}`)}"
                                        </div>
                                    }
                                    {unsetHeaderIndexes.length > 0 &&
                                        <div style={{marginTop: '16px'}} className="brand-red">
                                            All columns must be assigned. You can choose to "Skip" unwanted columns.
                                        </div>
                                    }                                    
                                    <ul style={{marginTop: '16px'}} className="cards grid-3">
                                            {imported.length && imported[0].map((field, i) => 
                                                <li key={i} className={unsetHeaderIndexes.indexOf(i) > -1 ? 'table-error' : ''}>
                                                    <Select
                                                        style={{marginBottom: '16px'}}
                                                        clearable={false}
                                                        classNamePrefix="select"                                       
                                                        value={mappedHeaders[i]}
                                                        onChange={changeMapping}
                                                        options={[
                                                            {value: '~skip~', label: 'Skip Column', index: i},
                                                            ...mappingOptions.map(option => ({value: option.value, label: option.label, isDisabled: mappedHeaders.filter(({value}) => option.value === value).length, index: i})),
                                                        ]}
                                                    />
                                                    <ReactTable
                                                        data={
                                                            imported
                                                            .map(row => ({field: row[i]}))
                                                            .filter((fieldObj, i) => importHasHeaders && i === 0  ? false : true)
                                                        }
                                                        columns={[
                                                            {Header:'Data Preview', accessor: 'field'}
                                                        ]}
                                                        noDataText=""
                                                        className="small-table"
                                                        defaultPageSize={20}
                                                        minRows={15}
                                                        showPageJump={false}
                                                        showPageSizeOptions={false}
                                                        showPaginationBottom={false}                                                
                                                    />                                            
                                                </li>
                                            )}
                                    </ul>
                                    <footer>
                                        {remainingRquiredheaders.length ?
                                            <button className="button in-active">Next</button>
                                        :
                                            <input type="submit" value="Next" className="button"/>
                                        }
                                    </footer>                                 
                                </form> 
                            </li>
                            <li className={importSteps[1]}>
                                <header>
                                    <button className="button close" onClick={close}>
                                        <Svg use="close"/>
                                    </button>                            
                                    <h3>Review &amp; Confirm Import</h3>
                                    {cellErrors.length > 0 &&
                                        <p className="brand-red">Errors Found</p>
                                    }
                                </header>
                                <div className="inner">
                                    <ReactTable
                                        data={cellErrors.length ? mergeArray.map((row, i) => {
                                            const errorFilter = cellErrors.filter(error => i === error.lineIndex);
                                            //Loop the filter to allow for more that one error per line
                                            if (errorFilter.length) {
                                                row.errors = errorFilter.map(({column}) => column);
                                            }
                                            return row;
                                        }) : mergeArray}
                                        columns={[...mappedHeaders, {label: 'Errors', value: 'errors', show: false}].filter(({value}) => value !== '~skip~').map(({label, value, show}) => 
                                            ({
                                                Header: label,
                                                accessor: value,
                                                show: show === false ? show : true,
                                                Cell: (rowData) => rowData.row.errors && rowData.row.errors.indexOf(rowData.column.id) > -1 ? <CellError rowValue={rowData.value}/> : rowData.value
                                            })
                                        )}
                                        noDataText=""
                                        defaultPageSize={3000}
                                        minRows={15}
                                        showPageJump={false}
                                        showPageSizeOptions={false}
                                        showPaginationBottom={false}                                                
                                    />                                          
                                </div> 
                                <footer>
                                    <button onClick={() => prevStep(1)} className="button">Back</button>
                                    {cellErrors.length ?
                                        <button className="button in-active">Upload</button>
                                    :
                                        <button className="button" onClick={upload}>Upload</button>
                                    }
                                </footer>                                 
                            </li>               
                        </ol>
                    </Modal>
                }         
            </React.Fragment>                 
        );
    };
}
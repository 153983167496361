import React, {Component} from 'react';
import {withRouter, Prompt} from 'react-router-dom';
import EvidenceTable from '../evidence/evidenceTable'
import Modal from 'react-modal';
import Svg from '../helpers/svg';
import List from '../helpers/list';
import PageHeader from '../helpers/pageHeader';
import fpRest from '../helpers/fpRest';
import {defaultTableFilter, DorpDownFilter} from '../helpers/tableHelpers';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import moment from 'moment';
import Loader from 'react-loader-advanced';
import {toast} from 'react-toastify';
import usStatesObj from 'datasets-us-states-abbr-names';
import {handleGetError, handlePostPatchError} from '../helpers/errors';

const usStatesOptions = Object.keys(usStatesObj).map(abbr => ({label: usStatesObj[abbr], value: abbr}));

/*
const short = (string, num) => {
    return string.length > num ? string.slice(0, num).trim() + '...' : string;
};
*/

class BusinessAssociate extends Component {
    constructor(props) {
        super(props) 

        this.state = {
            showLoader: true,
            isLoadingReport: false,
            isDownloadDropdownOpen: false,
            isSaved: true,
            modalContent: {},
            name: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            zip: '',
            phone1: '',
            phone2: '',
            fax1: '',
            fax2: '',
            website: '',
            type: '',
            typeId: '',
            primaryContactName: '',
            primaryContactId: null,
            linkedServices: [],
            linkedServiceIds: [],
            active: null,
            linkedEvidence: [],
            agreements: [],
            contacts: [],
            associateTypes: [
                {id: 1, title: 'Covered Entity'},
                {id: 2, title: 'Business Associate'}
            ],
            hideInactive: false,
            services: []
        };            
    };

    getData = async () => {
        const {id, _new} = this.props;

        let associate = {};
        let services = [];
        
        try {
            if (!_new) {
                associate = await fpRest.get(`business-associates/${id}`);
                associate = associate.data;
                associate.linkedServiceIds = associate.linkedServices.map(({id}) => id)
                sessionStorage.setItem(`associate-${id}`, `{"name": "${associate.name}"}`);
            }
            else {
                services = services.data;
                associate.active = true;
            }

            services = await fpRest.get(`business-associates/services`);
            services = services.data.filter(service => service.active).map(({id, name}) => ({label: name, value: id}));
            this.setState({...associate, services, showLoader: false});
        }
        catch (err) {
            handleGetError(err, this.props);
        }
    };

    toggleDownloadDropdown = () => {
        this.setState({isDownloadDropdownOpen:!this.state.isDownloadDropdownOpen});
    };

    downloadBusinessAssociate = async () => {
        this.setState({showLoader: true, isLoadingReport: true});
        
        try {
            await fpRest.download(`reportsTest?name=businessAssociateSingle&id=${this.props.id}`, `Business Associate - ${this.state.name}.pdf`);  
            this.setState({showLoader: false, isLoadingReport: false});       
        }
        catch (err) {
            console.log(err);
        }
    };    

    downloadEvidence = async id => {
        this.setState({showLoader: true});

        try {
            const {data} = await fpRest.get(`evidence/${id}`);

            let element = document.createElement('a');

            element.setAttribute('href', data.tempDownloadUrl);
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
            this.setState({showLoader: false});          
        }
        catch (err) {
            console.log(err);
        } 
    };
    
    onEvidenceUploadComplete = async (newEvidence) => {
        this.setState({linkedEvidence: [...this.state.linkedEvidence, newEvidence]});
    }; 
    
    deleteEvidence = async id => {
        try {
            await fpRest.delete(`evidence/${id}`);
            
            const linkedEvidence = this.state.linkedEvidence.filter(linkedEvidence => linkedEvidence.id === id ? false : true);

            this.setState({linkedEvidence});
            toast.success('Deleted');
        }
        catch(err) {
            console.log(err);
        }
    };

    onFieldChange = ({accessor, value}) => {
        this.setState({
            [accessor]: value,
            isSaved: false
        });
    };

    edit = () => {
        this.props.history.push(`/business-associates/${this.props.id}/edit`)
    };    
    
    validate = ({name, active}) => {
        let invalidFields = [];

        if (name === '') invalidFields.push('"Company Name" is required');
        if (active === '' || active === null) invalidFields.push('"Active" is required');

        return invalidFields;
    };

    save = async secondAction => {
        const {id, history} = this.props;
        const {name, address1, address2, city, state, zip, phone1, phone2, fax1, fax2, website, typeId, primaryContactId, linkedServiceIds, active} = this.state;
        const persistedState = {name, address1, address2, city, state, zip, phone1, phone2, fax1, fax2, website, typeId, primaryContactId, linkedServiceIds, active};
        const invalidFields = this.validate(persistedState);

        this.setState({isSaved: true});

        if (!invalidFields.length) {
            try {
                if (id) {
                    await fpRest.patch(`business-associates/${id}`, persistedState);
                    if (secondAction === 'close') {
                         history.push(`/business-associates/${id}`);
                    }
                    
                    toast.success('Business Associates Saved');
                }
                else {
                    const {data} = await fpRest.post('business-associates', persistedState);
                    if (secondAction === 'close') {
                        history.push(`/business-associates/`);
                    }
                    else {
                        history.push(`/business-associates/${data.id}`);
                    }
                }
                
                sessionStorage.setItem(`associate-${id}`, `{"name": "${persistedState.name}"}`);
            }
            catch (err) {
                this.setState({isSaved: false});
                handlePostPatchError(err);
            }
        }
        else {
            invalidFields.forEach(message => {
                toast.error(message);
            });
        } 
    };

    close = () => {
        if (this.props.id) {
            this.props.history.push(`/business-associates/${this.props.id}`);
        }
        else {
            this.props.history.push(`/business-associates/`);
        }
    };

    openModal = (type, id) => {
        let modalContent = null;

        if (type === 'citation') {
            modalContent = this.state.mappedCitations.filter(citation => citation.id === id)
            .map(citation => {
                return {
                    closeButton: true,
                    header: citation.title,
                    body: citation.text
                }            
            })[0];
        }       

        this.setState({modalIsOpen: true, modalContent});        
    };

    closeModal = () => {
        this.setState({modalIsOpen: false});
    };

    componentDidMount() {
        this.getData();
    };

    render() {
        const {id, editing, _new, history} = this.props;
        const {showLoader, isLoadingReport, isDownloadDropdownOpen, modalIsOpen, modalContent, isSaved, name, address1, address2, city, state, zip, phone1, phone2, fax1, fax2, website, type, typeId, primaryContactName, primaryContactId, active, linkedServices, linkedServiceIds, linkedEvidence, agreements, contacts, associateTypes, hideInactive, services} = this.state;
        const onFieldChange = this.onFieldChange.bind(this);
        const edit = this.edit.bind(this);
        const save = this.save.bind(this);
        const closeModal = this.closeModal.bind(this);
        const close = this.close.bind(this);
        const onEvidenceUploadComplete = this.onEvidenceUploadComplete.bind(this);
        const downloadEvidence = this.downloadEvidence.bind(this);
        const deleteEvidence = this.deleteEvidence.bind(this);
        const downloadBusinessAssociate = this.downloadBusinessAssociate.bind(this);
        const toggleDownloadDropdown = this.toggleDownloadDropdown.bind(this);

        let saveButtonClass = '';
        let tag = null;

        if (!isSaved) saveButtonClass = 'warning';

        //if (_new) tag = 'Creating New Associate'
        return (
            <Loader show={showLoader} message={<div><div>{isLoadingReport ? 'Generating Document' : ''}</div><Svg use="loader"/></div>} disableDefaultStyles={true}>
                {editing ?
                    <PageHeader tag={tag} title={
                            <span>
                                <span className="brand-red">{_new && <span>New </span>}Associate</span>
                                {name && <span>: {name}</span>}
                            </span>
                        }
                        /*
                        navItems={!_new ? [
                            {label: short(name ? name : 'New', 10), link: '#', active: true},
                            {label: 'Contacts', link: `/business-associates/${id}/contacts`}
                        ] : []}
                        */
                        actionItems={[
                            {label: <Svg use='close'/>, tip: 'Close', action: close},
                            {label: <Svg use='save'/>, tip:'Save', action: save, className: saveButtonClass},
                            {label: <Svg use='save-close'/>, tip: 'Save & Close', action: () => save('close')}
                        ]}
                    />
                    :
                    <PageHeader tag={tag} title={<span><span className="brand-red">Associate:</span> {name}</span>}
                        /*
                        navItems={[
                            //{label: 'Business Associates', link: '/business-associates/'},
                            {label: short(name, 10), link: '#', active: true},
                            {label: 'Contacts', link: `/business-associates/${id}/contacts`},
                            //{label: 'Agreements', link: `/business-associates/${id}/agreements`}
                        ]}
                        */
                        actionItems={[
                            {label: <Svg use='arrow-left'/>, tip: 'All Associates', link: '/business-associates/'},
                            {label: <Svg use='download'/>, tip: 'Download', options: [{label: 'Download Business Associate', action: downloadBusinessAssociate}], isOpen: isDownloadDropdownOpen, action: toggleDownloadDropdown},
                            {label: <Svg use='edit'/>,  tip: 'Edit Business Associate', action: edit},
                            {label: <Svg use="add" />, tip: 'New BA', link: '/business-associates/new'}
                        ]}
                    />
                }                   
                <section>
                    <div className="grid-3 card">
                        <List
                            className="grid-1"
                            editing={editing}
                            onChange={onFieldChange}
                            items={[
                                {label: 'Company Name', value: name, accessor: 'name', required: true},
                                {
                                    label: 'Type',
                                    value: editing ? typeId : type,
                                    inputType: 'select',
                                    accessor: 'typeId',
                                    options: associateTypes.map(({title, id}) => ({label: title, value: id}))
                                },
                                {
                                    label: 'Service(s) Provided',
                                    value: editing ? linkedServiceIds : linkedServices.map(({name}) => name).toString(),
                                    inputType: 'multiSelect',
                                    accessor: 'linkedServiceIds',
                                    options: services
                                },                                
                                {
                                    label: 'Primary Contact',
                                    accessor: 'primaryContactId',
                                    value: editing ? primaryContactId : primaryContactName,
                                    inputType: 'select',
                                    options: contacts.filter(({active}) => active).map(({id, firstName, lastName}) => ({label: `${firstName} ${lastName}`, value: id}))
                                },                                
                                {label: 'Address 1', value: address1, inputType: 'street-address'},
                            ]}
                        />
                        <List
                            className="grid-1"
                            editing={editing}
                            onChange={onFieldChange}
                            items={[
                                {label: 'Address 2', value: address2, inputType: 'street-address'},
                                {label: 'City', value: city},
                                {
                                    label: 'State',
                                    value: editing ? state : usStatesObj[state],
                                    inputType: 'select',
                                    options: usStatesOptions                                    
                                },
                                {label: 'Zip', value: zip, inputType: 'number'},
                                {label: 'Phone 1', value: phone1, inputType: 'tel-national'},                              
                            ]}                            
                        /> 
                        <List
                            className="grid-1"
                            editing={editing}
                            onChange={onFieldChange}
                            items={[
                                {label: 'Phone 2', value: phone2, inputType: 'tel-national'},                                
                                {label: 'Fax 1', value: fax1, inputType: 'tel-national'},
                                {label: 'Fax 2', value: fax2, inputType: 'tel-national'},
                                {label: 'Website', value: website, inputType: 'url'},
                                {
                                    label: 'Active',
                                    value: active,
                                    inputType: 'toggle',                                 
                                }                                
                            ]}                            
                        />                                                
                    </div>    
                </section>
                {!_new &&
                    <section>
                        <div className="cards grid-2">
                            <div>
                                <h2 className="header-alt-1 clear-fix"><span style={{float: 'left'}}>Contacts</span><button onClick={() => history.push(`/business-associates/${id}/contacts/new`)} className="no-style corner"><Svg use="add"/></button></h2>
                                <ReactTable
                                    data={hideInactive ? contacts.filter(({active}) => active) : contacts}
                                    columns={[
                                        {Header: 'id', accessor:'id', show: false},
                                        {Header: 'First Name', accessor:'firstName', filterable: true},
                                        {Header: 'Last Name', accessor:'lastName', filterable: true},
                                        {Header: 'Email', accessor:'email', filterable: true},   
                                        {
                                            show: hideInactive ? false : true,
                                            Header: 'Active',
                                            accessor: 'active',
                                            Cell: row => row.value ? 'Active' : 'Inactive',
                                            Filter: ({onChange}) => <DorpDownFilter defaultValue={true} options={[{label: 'Active', value: true}, {label: 'Inactive', value: false}]} action={onChange.bind(this)}/>                        
                                        } 
                                    ]}
                                    noDataText="No Contacts Found"
                                    className="small-table"
                                    defaultPageSize={200}
                                    minRows={20}
                                    filterable={true}
                                    showPageJump={false}
                                    showPageSizeOptions={false}
                                    showPaginationBottom={false}                              
                                    defaultFilterMethod={defaultTableFilter}                          
                                    getTdProps={(state, rowInfo, column, instance) => ({
                                        style: {cursor: 'pointer'},
                                        onClick: e => rowInfo && history.push(`/business-associates/contacts/${rowInfo.row.id}`),
                                    })}                            
                                />
                            </div>                        
                            <EvidenceTable
                                data={linkedEvidence}
                                title="Evidence"
                                onUploadComplete={onEvidenceUploadComplete}
                                onRequestDownload={downloadEvidence}
                                onRequestDelete={deleteEvidence}
                                isEditing={true}
                                parentId={id}
                                typeId={3}
                            />
                            <div>
                                <h2 className="header-alt-1 clear-fix"><span style={{float: 'left'}}>Agreements</span><button onClick={() => history.push(`/business-associates/${id}/agreements/new`)} className="no-style corner"><Svg use="add"/></button></h2>
                                <ReactTable
                                    data={agreements}
                                    columns={[
                                        {Header: 'id', accessor:'id', show: false},
                                        {Header: 'Summary', accessor:'summary', filterable: true},
                                        {
                                            Header: 'Date Effective',
                                            accessor:'effectiveDate',
                                            filterable: true,
                                            Cell: row => <span>{row.value ? moment(row.value).utcOffset('-05:00').format('MM/DD/Y') : ''}</span>
                                        }
                                        /*
                                        {
                                            show: hideInactive ? false : true,
                                            Header: 'Active',
                                            accessor: 'active',
                                            Cell: row => row.value ? 'Active' : 'Inactive',
                                            Filter: ({onChange}) => <DorpDownFilter options={[{label: 'Active', value: true}, {label: 'inactive', value: false}]} action={onChange.bind(this)}/>                        
                                        } 
                                        */                                    
                                    ]}
                                    noDataText="No Agreements Found"
                                    className="small-table"
                                    defaultPageSize={200}
                                    minRows={20}
                                    showPageJump={false}
                                    showPageSizeOptions={false}
                                    showPaginationBottom={false}                              
                                    defaultFilterMethod={defaultTableFilter}                          
                                    getTdProps={(state, rowInfo, column, instance) => ({
                                        style: {cursor: 'pointer'},
                                        onClick: e => rowInfo && history.push(`/business-associates/agreements/${rowInfo.row.id}`),
                                    })}                            
                                />
                            </div>                       
                        </div>
                    </section>
                }
                <Modal
                    isOpen={modalIsOpen}
                    //onAfterOpen={this.afterOpenModal}
                    onRequestClose={closeModal}
                    className="modal-content"
                    overlayClassName="modal-"
                    bodyOpenClassName="modal-active"
                    closeTimeoutMS={300}
                    ariaHideApp={false}
                    contentLabel="Modal"
                >
                    {modalContent.closeButton &&
                        <button className="button close" onClick={closeModal}>
                            <Svg use="close"/>
                        </button>
                    }
                    {modalContent.header &&                    
                        <h3>{modalContent.header}</h3>
                    }
                    <span>{modalContent.body}</span>
                </Modal>
                <Prompt when={editing && !isSaved} message={location => `You have changes that arn't saved. Click "Okay" to discard these changes, or "Cancel" to stay on this page`}/>                               
            </Loader>
        );
    }
}

export default withRouter(BusinessAssociate);
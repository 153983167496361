import React, {Component} from 'react';
import {withRouter, Prompt} from 'react-router-dom';
import Svg from '../helpers/svg';
import List from '../helpers/list';
import moment from 'moment';
import PageHeader from '../helpers/pageHeader';
import fpRest from '../helpers/fpRest';
import withCurrentUser from '../helpers/withCurrentUser';
import {truncate} from '../helpers/utils';
import 'react-table/react-table.css';
import Loader from 'react-loader-advanced';
 import { toast } from 'react-toastify';
import {handleGetError, handlePostPatchError} from '../helpers/errors';


class Asset extends Component {
    constructor(props) {
        super(props) 

        this.state = {
            hideInactive: false,
            showLoader: true,
            isSaved: true,           
            id: '',
            serialNumber: '',
            tag: '',
            name: '',
            description: '',
            notes: '',
            typeId: null,
            type: '',
            location: '',
            criticality: '',
            active: true,
            activeDate: moment().format(),
            deactivateDate: '',
            destroyedDate: '',
            ephi: null,
            enteredDate: '',
            encrypted: null,
            types: [] 
        };            
    };

    getData = async () => {
        const {id, editing, _new} = this.props;
        let types = [];

        try {
            let asset = {};
            
            if (!_new) {
                asset = await fpRest.get(`assets/${id}`);
                asset = asset.data;
            }

            if (editing) {
                types = await fpRest.get(`assets/types`);
                types = types.data; 
            }
            this.setState({...asset, showLoader: false, types});
        }
        catch (err) {
            handleGetError(err, this.props);
        }
    };

    onFieldChange = ({accessor, value}) => {
        if (accessor === 'active') {
            this.setState({
                //activeDate: value ? moment().format() : null,
                deactivateDate: value ? null : moment().format(),
            });        
        }        
        else if (accessor === 'deactivateDate') {
            this.setState({
                active: value ? false : true,
            });            
        }

        this.setState({
            [accessor]: value,
            isSaved: false
        });
    };

    edit = () => {
        this.props.history.push(`/assets/${this.props.id}/edit`)
    };    
    
    validate = ({
        name,
        tag,
        typeId
    }) => {
        let invalidFields = [];
    
        if (!name) invalidFields.push('"Name" is required');
        if (!tag) invalidFields.push('"Tag" is required');
        if (!typeId) invalidFields.push('"Type" is required');
    
        return invalidFields;
    };

    save = async secondAction => {
        const {id, history} = this.props;
        const {
            serialNumber,
            tag,
            name,
            description,
            notes,
            typeId,
            location,
            criticality,
            active,
            activeDate,
            deactivateDate,
            destroyedDate,
            ephi,
            enteredDate,
            encrypted
        } = this.state;
        const persistedState = {
            serialNumber,
            tag,
            name,
            description,
            notes,
            typeId,
            location,
            criticality,
            active,
            activeDate,
            deactivateDate,
            destroyedDate,
            ephi,
            enteredDate,
            encrypted
        };
        const invalidFields = this.validate(persistedState);

        this.setState({isSaved: true});

        if (!invalidFields.length) {
            try {
                if (id) {
                    await fpRest.patch(`assets/${id}`, persistedState);
                    if (secondAction === 'close') {
                         history.push(`/assets/${id}`);
                    }
                    
                    toast.success('Asset Saved');
                }
                else {
                    const {data} = await fpRest.post('assets', persistedState);
                    if (secondAction === 'close') {
                        history.push(`/assets/`);
                    }
                    else {
                        history.push(`/assets/${data.id}`);
                    }
                }
            }
            catch (err) {
                this.setState({isSaved: false});
                handlePostPatchError(err);
            }
        }
        else {
            this.setState({isSaved: false});

            invalidFields.forEach(message => {
                toast.error(message);
            });
        } 
    };

    close = () => {
        if (this.props.id) {
            this.props.history.push(`/assets/${this.props.id}`);
        }
        else {
            this.props.history.push(`/assets/`);
        }
    };

    componentDidMount() {
        this.getData();
    };

    render() {
        const {id, editing, _new, history} = this.props;
        const {
            showLoader,
            isSaved,
            hideInactive,
            serialNumber,
            tag,
            name,
            description,
            typeId,
            type,
            location,
            criticality,
            active,
            activeDate,
            deactivateDate,
            destroyedDate,
            ephi,
            enteredDate,
            encrypted,
            types
        } = this.state;
        const {onFieldChange, edit, save, close} = this;

        let saveButtonClass = '';

        if (!isSaved) saveButtonClass = 'warning';
        console.log(enteredDate);
        return (
            <Loader show={showLoader} message={<Svg use="loader"/>} disableDefaultStyles={true}>
                {editing ?
                    <PageHeader title={
                            <span>
                                <span className="brand-red">{_new && <span>New </span>}Asset</span>
                                {name && <span>: {truncate(name, 15)}</span>}
                            </span>
                        }
                        actionItems={[
                            //{label: <Svg use='briefcase'/>, tip: 'All assets', link: '/assets/'},
                            {label: <Svg use='close'/>, tip: 'Close', action: close},
                            {label: <Svg use='save'/>, tip:'Save', action: save, className: saveButtonClass},
                            {label: <Svg use='save-close'/>, tip: 'Save & Close', action: () => save('close')}
                        ]}
                    />
                    :
                    <PageHeader title={<span><span className="brand-red">Asset:</span> {truncate(name, 15)}</span>}
                        actionItems={[
                            {label: <Svg use='arrow-left'/>, tip: 'All Assets', link: '/assets/'},
                            {label: <Svg use='edit'/>,  tip: 'Asset', action: edit},
                            {label: <Svg use="add" />, tip: 'New Asset', link: '/assets/new'}
                        ]}
                    />
                }
                {enteredDate &&
                <section>
                    {!_new &&
                        <List
                            className="meta"
                            inlineLabels={true}
                            items ={[
                                {label: 'Entered Date', value: enteredDate, inputType: 'date'}
                            ]}
                        />
                    }
                </section>
                }           
                <section>
                    <div className="grid-3 card">
                        <List
                            className="grid-1"
                            editing={editing}
                            onChange={onFieldChange}
                            items={[
                                {label: 'Name', value: name, accessor:'name', required: true},
                                {label: 'Tag', value: tag, accessor:'tag', required: true},                              
                                {label: 'Serial Number', value: serialNumber, accessor:'serialNumber'},
                                {label: 'Type', value: editing ? typeId : type, accessor:'typeId', inputType: 'select', options: types.map(({id, name}) => ({label: name, value: id})), required: true},
                                {label: 'Description', value: description, inputType: 'textarea'},
                            ]}
                        />
                        <List
                            className="grid-1"
                            editing={editing}
                            onChange={onFieldChange}
                            items={[
                                {label: 'Location', value: location, accessor:'location'},
                                {label: 'Criticality', value: criticality, accessor:'criticality'},
                                {label: 'Destroyed Date', value: destroyedDate, accessor:'destroyedDate', inputType: 'date'},        
                                {label: 'Active Date', value: activeDate, accessor:'activeDate', inputType: 'date'},
                                {label: 'Deactivate Date', value: deactivateDate, accessor:'deactivateDate', inputType: 'date'},                           
                            ]}
                        />
                        <List
                            className="grid-1"
                            editing={editing}
                            onChange={onFieldChange}
                            items={[
                                {label: 'Active', value: editing ? active : active ? 'Yes' : 'No', accessor:'active', inputType: 'toggle'},                            
                                {label: 'Has ePHI', value: editing ? ephi : ephi ? 'Yes' : 'No', accessor:'ephi', inputType: 'toggle'},
                                {label: 'Encrypted', value: editing ? encrypted : encrypted ? 'Yes' : 'No', accessor:'encrypted', inputType: 'toggle'},
                            ]}
                        />
                    </div>                                                                                           
                </section>
                <Prompt when={editing && !isSaved} message={location => `You have changes that arn't saved. Click "Okay" to discard these changes, or "Cancel" to stay on this page`}/>                               
                
            </Loader>
        );
    }
}

export default withRouter(withCurrentUser(Asset));